import React from 'react'

const SettingHeader = (props) => {

    return (
        <div className='header'>
            <h3>{props.header}</h3>
        </div>
    )
}


export default SettingHeader