import store from '../store'
import {serverRequest} from './actionUtils'
import {getOrderFilter} from './orderActions'
import {getCashRegisterFilter} from './cashRegisterAction'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {AlertType} from "../../data/AlertType";
import {PaymentContext} from "../../data/paymentContext";


export function changePaymentState( data ) {
    return {
        type: Action.Payment.CHANGE_STATE,
        data
    }
}

export function addPaymentTag(tag) {
    return {
        type: Action.Payment.ADD_TAG,
        tag
    }
}

export function deletePaymentTag(idx) {
    return {
        type: Action.Payment.DELETE_TAG,
        idx
    }
}

export function resetPayments() {
    return {
        type: Action.Payment.RESET
    }
}

export function createPayment(context) {

    const state = store.getState()

    const now = Math.round(Date.now() / 1000)

    const desc = `Перемещение денег из кассы "${state.payment.cashbox.title}" в кассу "${state.payment.target_cashbox.title}".`

    let body = {
        cashflow_category_id: state.payment.direction ? state.payment.cashflow_category.id : null,
        description: state.payment.direction ? state.payment.description : desc + state.payment.description,
        deposit: state.payment.cashbox.balance + state.payment.income - state.payment.outcome,
        income: parseFloat(state.payment.income.toString().replace(',', '.')) || 0,
        outcome: -parseFloat(state.payment.outcome.toString().replace(',', '.')) || 0,
        direction: state.payment.direction,
        deleted: false,
        can_print_fiscal: state.payment.can_print_fiscal,
        is_fiscal: state.payment.is_fiscal,
        created_at: now,
        custom_created_at: state.payment.custom_created_at || now,
        tags: state.payment.tags,
        relation_type: state.payment.relation_type,
        cashbox_id: state.payment.cashbox?.id,
        client_id: state.payment.client?.id,
        employee_id: state.payment.employee?.id,
        order_id: state.payment.order_id || null,
        target_cashbox_id: state.payment.direction ? null : state.payment.target_cashbox.id
    }
    if (context.type === PaymentContext.PAYMENT) {
        body.filter_cashboxes = getCashRegisterFilter()
        body.filter_payments = {
            custom_created_at: state.payment.filter_created_at,
            cashbox_id: state.cashbox.current_cashbox.id,
            tags: state.payment.filter_tags.length ? state.payment.filter_tags : null,
            deleted: null
        }
    }
    if (context.type === PaymentContext.ORDER) {
        let r_filter = getOrderFilter()
        r_filter.update_order = state.order.edit
        body.filter_order = r_filter
    }
    if (context.type === PaymentContext.CLOSE_ORDER) {
        let r_filter = getOrderFilter()
        r_filter.update_order = state.order.edit
        body.closed_order = {
            order_id: context.order_id,
            status_id: context.status_id,
            filter: r_filter
        }
    }
    const url = URLS.Payment.POST
    const error_message = 'Запрос на создание платежа не выполнен'

    return async dispatch => {

        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {statusPaymentsEditor: false}
        })

        const data = await serverRequest(body, url, dispatch, error_message)


                if (data.success) {
                    if (context.type === PaymentContext.PAYMENT) {
                        dispatch({
                            type: Action.Payment.CHANGE_STATE,
                            data: {payments: data.payments}
                        })
                        dispatch({
                            type: Action.CashRegister.CHANGE_STATE,
                            data: {cashboxes: data.cashboxes},
                        })
                    }
                    if (context.type === PaymentContext.ORDER) {
                        dispatch({
                            type: Action.Order.EDIT,
                            order: data.order
                        })
                        dispatch({
                            type: Action.Order.CHANGE_STATE,
                            data: {ordersShow: data.orders, events: data.events}
                        })
                    }
                    if (context.type === PaymentContext.CLOSE_ORDER) {
                        if (state.order.edit) {
                            dispatch({
                                type: Action.Order.EDIT,
                                order: data.order
                            })
                            dispatch({
                                type: Action.Order.CHANGE_STATE,
                                data: {events: data.events}
                            })
                        }
                        dispatch({
                            type: Action.Order.CHANGE_STATE,
                            data: {ordersShow: data.orders, count: data.orders_count}
                        })
                        dispatch({
                            type: Action.Filter.CHANGE_STATE,
                            data: {badges: data.badges}
                        })
                    }
                    showAlert(dispatch, AlertType.SUCCESSES, 'Платеж успешно добавлен')
                }
                dispatch({
                    type: Action.Payment.RESET
                })

    }
}

export function addPayments() {

    const state = store.getState()

    const body = {
        custom_created_at: state.payment.filter_created_at,
        cashbox_id: state.cashbox.current_cashbox.id,
        tags: state.payment.filter_tags.length ? state.payment.filter_tags : null
    }
    const url = URLS.Payment.ADD
    const error_message = 'Запрос платежей не выполнен'

    return async dispatch => {

        dispatch({
            type: Action.Payment.CHANGE_STATE,
            data: {showLoader: true}
        })

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Payment.CHANGE_STATE,
                data: {payments: data.data}
            })
        }

        dispatch({
            type: Action.Payment.CHANGE_STATE,
            data: {showLoader: false}
        })
    }
}

export function deletePayment(flag) {

    const state = store.getState()

    let body = {
        id: state.payment.edit,
        relation_type: state.payment.relation_type,
        relation_id: state.payment.relation_id || null,
        order_id: state.payment.order_id || null,
        deleted: flag
    }
    if (state.order.edit) {
        let r_filter = getOrderFilter()
        r_filter.update_order = state.order.edit
        body.filter_order = r_filter
    } else {
        body.filter_cashboxes = {
            deleted: null
        }
        body.filter_payments = {
            custom_created_at: state.payment.filter_created_at,
            cashbox_id: state.cashbox.current_cashbox.id,
            tags: state.payment.filter_tags.length ? state.payment.filter_tags : null,
            deleted: null
        }
    }
    const url = URLS.Payment.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос удаление/восстановления платежа не выполнен'

    return async dispatch => {

        dispatch({
            type: Action.Payment.CHANGE_STATE,
            data: {showLoader: true}
        })

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            if (state.order.edit) {
                dispatch({
                    type: Action.Order.EDIT,
                    order: data.order
                })
                dispatch({
                    type: Action.Order.CHANGE_STATE,
                    data: {ordersShow: data.orders, events: data.events}
                })
                dispatch({
                    type: Action.Payment.RESET
                })
            } else {
                dispatch({
                    type: Action.Payment.CHANGE_STATE,
                    data: {payments: data.payments}
                })
                dispatch({
                    type: Action.Payment.RESET
                })
                dispatch({
                    type: Action.CashRegister.CHANGE_STATE,
                    data: {cashboxes: data.cashboxes}
                })
            }
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
        }
        const text = flag ? 'Платеж успешно удален' : 'Платеж успешно восстановлен'
        showAlert(dispatch, AlertType.SUCCESSES, text)

        dispatch({
            type: Action.Payment.CHANGE_STATE,
            data: {showLoader: false}
        })
    }
}