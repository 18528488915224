import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import {addEmployees, changeEmployeeState} from '../../../../Redux/actions/employeeAction'
import {addRoles, changeRoleState} from "../../../../Redux/actions/roleActions"
import {Permissions} from "../../../../data/permissions"
import {Modal} from "../../../../data/data"
import {CheckboxDate} from "../../../../data/checkboxData"
import {ButtonData} from "../../../../data/ButtonData"

import Button from '../../../general/Button'
import SettingHeader from "../SettingHeader"
import Checkbox from '../../../general/Checkbox'
import TableEmployees from './TableEmployees'
import TableRoles from './Roles/TableRoles'

const SettingEmployees = (props) => {

    useEffect(() => {
        props.addRoles()
    }, [props.role.showDeleted])

    useEffect(() => {
        props.addEmployees()
    }, [props.employee.showDeleted])

    return (
        <div className='setting-content'>
            <SettingHeader header='Сотрудники'/>
            <div className='box'>
                <div className='box__forms'>
                    {props.permission.includes(Permissions.CAN_SEE_ROLE_SETTINGS) ?
                        <div className='box__forms'>
                            <h3>Роли</h3>
                            <p>Роль — это список прав и возможностей сотрудника.</p>
                            <div className='two-buttons'>
                                <Button
                                    size={ButtonData.Size.MEDIUM}
                                    type={ButtonData.Type.CREATE}
                                    title='Роль'
                                    onClick={() => props.changeVisibleState({
                                        isRightModalOpen: true,
                                        modalType: Modal.Type.ROLE
                                    })}
                                />
                                <Checkbox
                                    type={CheckboxDate.Type.SLIDE_THREE}
                                    label='Показать удаленных'
                                    onChange={event => props.changeRoleState({showDeleted: event.target.checked})}
                                    checked={props.role.showDeleted}
                                    invisible={!props.permission.includes(Permissions.CAN_SEE_EMPLOYEE_SETTINGS)}
                                />
                            </div>
                            <TableRoles/>
                        </div>
                        : null
                    }
                    <h3>Сотрудники</h3>
                    <p>Каждый сотрудник имеет свой логин и пароль для входа в OneTwoService</p>

                    <div className='two-buttons'>
                        <Button
                            size={ButtonData.Size.MEDIUM}
                            type={ButtonData.Type.CREATE}
                            title='Сотрудник'
                            onClick={() => props.changeVisibleState({
                                isRightModalOpen: true,
                                modalType: Modal.Type.EMPLOYEE
                            })}
                        />
                        <Checkbox
                            type={CheckboxDate.Type.SLIDE_THREE}
                            label='Показать удаленных'
                            onChange={event => props.changeEmployeeState({showDeleted: event.target.checked})}
                            checked={props.employee.showDeleted}
                            invisible={!props.permission.includes(Permissions.CAN_SEE_EMPLOYEE_SETTINGS)}
                        />
                    </div>
                    <TableEmployees/>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = state => ({
    role: state.role,
    employee: state.employee,
    permission: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    changeEmployeeState,
    changeRoleState,
    addEmployees,
    addRoles
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingEmployees)