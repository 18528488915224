import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {Modal} from "../../data/data";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeBranchState( data ) {
    return {
        type: Action.Branch.CHANGE_STATE,
        data
    }
}

export function editBranch(branch) {
    return {
        type: Action.Branch.EDIT,
        branch
    }
}

export function resetBranch() {
    return {
        type: Action.Branch.RESET
    }
}

function getFilter() {

    const state = store.getState()

    return {
        employee_id: state.data.user.id,
        deleted: state.branch.showDeleted,
        page: 0
    }
}

export function getBranch(branch_id){

    const body = {id: branch_id}
    const url = URLS.Branch.GET
    const error_message = 'Запрос филиала не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Branch.EDIT,
                branch: data.branch,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.BRANCH},
            })
        }

    }
}

export function addBranches() {

    const body = getFilter()
    const url = URLS.Branch.ADD
    const error_message = 'Запрос филиалов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Branch.CHANGE_STATE,
                data: {branches: data.branches},
            })
        }
    }
}

export function createBranch() {

    const state = store.getState()

    const body = {
        name: state.branch.name,
        address: state.branch.address,
        phone: state.branch.phone,
        color: state.branch.color,
        icon: state.branch.icon,
        order_type_id: state.branch.order_type.id,
        orders_type_strategy: state.branch.orders_type_strategy,
        orders_prefix: state.branch.orders_prefix,
        documents_prefix: state.branch.documents_prefix,
        employees: state.branch.employees,
        deleted: state.branch.deleted,
        default_estimated_days: parseInt(state.branch.default_estimated_days),
        schedule: state.branch.schedule,
        employee_permissions: state.branch.employee_permissions,
        filter: getFilter()
    }
    const url = URLS.Branch.POST
    const error_message = 'Запрос на создание филиалов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Branch.CHANGE_STATE,
                data: {branches: data.branches},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.Branch.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Филиал успешно создан')
        }
    }
}

export function saveBranch() {

    const state = store.getState()

    const isCurrentBranchEdited = state.branch.current_branch.id === state.branch.edit

    const body = {
        id: state.branch.edit,
        name: state.branch.name,
        address: state.branch.address,
        phone: state.branch.phone,
        color: state.branch.color,
        icon: state.branch.icon,
        order_type_id: state.branch.order_type.id,
        orders_type_strategy: state.branch.orders_type_strategy,
        orders_prefix: state.branch.orders_prefix,
        documents_prefix: state.branch.documents_prefix,
        employees: state.branch.employees,
        deleted: state.branch.deleted,
        default_estimated_days: parseInt(state.branch.default_estimated_days),
        schedule: state.branch.schedule,
        employee_permissions: state.branch.employee_permissions,
        is_current_branch_edited: isCurrentBranchEdited,
        filter: getFilter()
    }
    const url = URLS.Branch.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение филиала не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Branch.CHANGE_STATE,
                data: {branches: data.branches},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            if(isCurrentBranchEdited){
                dispatch({
                    type: Action.Branch.CHANGE_STATE,
                    data: {current_branch: data.branch},
                })
            }
            dispatch({
                type: Action.Branch.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Филиал успешно изменен')
        }
    }
}

export function deleteBranch(flag) {

    const state = store.getState()

    const body = {
        id: state.branch.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Branch.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление филиала не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Branch.CHANGE_STATE,
                data: {branches: data.branches},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.Branch.RESET
            })
            const text = flag ? 'Филиал успешно удален' : 'Филиал успешно восстановлен'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}