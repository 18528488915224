import {Action} from "../../data/ActionTypes";

export function changeWarehousePermissionState( data ) {
    return {
        type: Action.WarehousePermission.CHANGE_STATE,
        data
    }
}

export function editWarehousePermission(permission) {
    return {
        type: Action.WarehousePermission.EDIT,
        permission
    }
}

export function resetWarehousePermission() {
    return {
        type: Action.WarehousePermission.RESET
    }
}