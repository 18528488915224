import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import {
    changeEmployeeState,
    createEmployee,
    deleteEmployee,
    resetEmployee,
    saveEditEmployee
} from '../../../../Redux/actions/employeeAction'
import {checkObject, hasIDinHierarchy} from "../../../general/utils";
import {EditorID, Modal} from "../../../../data/data";
import {Permissions} from "../../../../data/permissions";

import BottomButtons from '../../../general/BottomButtons'
import Tabs from '../../../general/Tabs'
import EditEmployeeGenerally from './EditEmployeeGenerally'
import EmployeePermissions from "./EmployeePermisstions";


const EmployeeEditor = (props) => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputEmployeeNameChecked: true,
            inputEmployeeEmailChecked: true,
            inputEmployeeLoginChecked: true,
            inputEmployeePasswordChecked: true,
            inputEmployeeRoleChecked: true,
            inputEmployeeDefaultBranch: true
        })
        props.resetEmployee()
    }

    const clickHandel = (event) => {
        const listIds = [
            EditorID.EMPLOYEE,
            EditorID.SALARY_RULE,
            EditorID.CASH_REGISTER_EMPLOYEE,
            EditorID.WAREHOUSE_EMPLOYEE
        ]
        if (!hasIDinHierarchy(event, listIds)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkCreateConditions = () => {
        if (
            props.employee.first_name &&
            props.employee.email &&
            props.employee.login &&
            props.employee.password &&
            checkObject(props.employee.role) &&
            checkObject(props.employee.default_branch)
        ) {
            return true
        } else {
            if (!props.employee.first_name) {
                props.changeVisibleState({inputEmployeeNameChecked: false})
            }
            if (!props.employee.email) {
                props.changeVisibleState({inputEmployeeEmailChecked: false})
            }
            if (!props.employee.login) {
                props.changeVisibleState({inputEmployeeLoginChecked: false})
            }
            if (!props.employee.password) {
                props.changeVisibleState({inputEmployeePasswordChecked: false})
            }
            if (!checkObject(props.employee.role)) {
                props.changeVisibleState({inputEmployeeRoleChecked: false})
            }
            if (!checkObject(props.default_branch)) {
                props.changeVisibleState({inputEmployeeDefaultBranch: false})
            }
            return false
        }
    }

    const checkSaveConditions = () => {
        if (props.employee.first_name && props.employee.email && props.employee.login) {
            return true
        } else {
            if (!props.employee.first_name) {
                props.changeVisibleState({inputEmployeeNameChecked: false})
            }
            if (!props.employee.email) {
                props.changeVisibleState({inputEmployeeEmailChecked: false})
            }
            if (!props.employee.login) {
                props.changeVisibleState({inputEmployeeLoginChecked: false})
            }
            return false
        }
    }

    const handleCreate = () => {
        if (checkCreateConditions()) {
            props.createEmployee()
        }
    }

    const handleSave = () => {
        if (checkSaveConditions()) {
            props.saveEditEmployee()
        }
    }

    const handleRecover = () => {
        if (props.permissions.includes(Permissions.CAN_RECOVER_EMPLOYEES)) {
            props.deleteEmployee(false)
        } else {
            return null
        }
    }

    const tabContents = [
        (<EditEmployeeGenerally/>),
        (<EmployeePermissions/>)
    ]

    return (
        <div
            className="modal__box-right"
            id={EditorID.EMPLOYEE}
        >
            <h4>{props.edit ? props.employee.name : 'Новый сотрудник'}</h4>

            <Tabs
                list={['Общие', 'Доступ']}
                func={idx => props.changeEmployeeState({tabs: idx})}
                tab={props.tabs}
            />

            {tabContents[props.tabs]}

            <BottomButtons
                edit={props.edit}
                create={handleCreate}
                save={handleSave}
                delete={() => props.deleteEmployee(true)}
                recover={handleRecover}
                close={handleClose}
                deleted={props.employee.deleted}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    tabs: state.employee.tabs,
    edit: state.employee.edit,
    employee: state.employee,
    permissions: state.data.user.role.permissions,
})

const mapDispatchToProps = {
    changeEmployeeState,
    changeVisibleState,
    createEmployee,
    saveEditEmployee,
    deleteEmployee,
    resetEmployee
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditor)
