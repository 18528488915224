export const ButtonData = Object.freeze({
    Type: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary',
        SECONDARY_ACTIVE: 'secondary-active',
        DESTRUCTIVE: 'destructive',
        CREATE: 'create'
    },
    Size: {
        SMALL: 'small',
        MEDIUM: 'med',
        LARGE: 'large'
    }
})