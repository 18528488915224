import store from '../store'
import {serverRequest} from './actionUtils'
import {checkObject} from '../../components/general/utils'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";

export function changeRemainState( data ) {
    return {
        type: Action.Remain.CHANGE_STATE,
        data
    }
}

export function addRemain() {

    const state = store.getState()

    const body = {
        deleted: state.remain.showDeleted,
        warehouse_id: checkObject(state.remain.filter_warehouse) ? state.remain.filter_warehouse.id : null,
        warehouse_category_id: checkObject(state.remain.filter_category) ? state.remain.filter_category.id : null,
        filter_type: state.remain.filter_type.id,
        title: state.remain.filter_title,
        page: state.remain.page
    }
    const url = URLS.Remain.GET_REMAIN
    const error_message = 'Запрос остатков на складе не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Remain.CHANGE_STATE,
                data: {warehouse_remains: data.warehouse_remains, remains_count: data.count},
            })
        }
    }
}

export function addBatches() {

    const state = store.getState()

    const body = {
        warehouse_id: checkObject(state.remain.filter_warehouse) ? state.remain.filter_warehouse.id : null,
        registration_id: state.remain.filter_registration_id
    }
    const url = URLS.Remain.GET_BATCHES
    const error_message = 'Запрос остатков на складе не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Back.CHANGE_STATE,
                data: {parts: data.batches}
            })
        }
    }
}