import {Action} from "../../data/ActionTypes"

const initialState = {

    roles: [],

    edit: 0,
    title: '',
    deleted: false,

    earningsVisibility: false,
    leadsVisibility: false,
    ordersVisibility: false,

    permissions: [],
    settableStatuses: [],
    visibleStatuses: [],
    settableDiscountMargin: [],

    showDeleted: false
}

export const roleReducer = (state = initialState, action) => {

    switch (action.type) {

        case Action.Role.CHANGE_STATE: {
            return {...Object.assign(state, action.data)}
        }

        case Action.Role.EDIT: {
            return {
                ...state,
                edit: action.role.id,
                title: action.role.title,
                deleted: action.role.deleted,
                earningsVisibility: action.role.earnings_visibility,
                leadsVisibility: action.role.leads_visibility,
                ordersVisibility: action.role.orders_visibility,
                permissions: action.role.permissions,
                settableStatuses: action.role.visible_statuses,
                visibleStatuses: action.role.settable_statuses,
                settableDiscountMargin: action.role.settable_discount_margin
            }
        }

        case Action.Role.RESET: {
            return {
                ...state,
                edit: 0,
                title: '',
                deleted: false,

                earningsVisibility: false,
                leadsVisibility: false,
                ordersVisibility: false,

                permissions: [],
                settableStatuses: [],
                visibleStatuses: [],
                settableDiscountMargin: [],
            }

        }

        default:
            return state
    }

}
