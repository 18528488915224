import React, {useState} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../Redux/actions'
import {changeFilterState} from '../../Redux/actions/filterAction'
import {changeOrderState} from '../../Redux/actions/orderActions'
import {calculateEstimateTimeForOrder} from "../general/utils";
import {ICON} from '../../data/icons'
import {Permissions} from "../../data/permissions";

import CustomFilter from './CustomFilter'
import SetFilter from './setCustomFilter/SetFilter'
import Button from '../general/Button'
import TableFields from '../general/TableFields'

const CustomPanel = (props) => {

    const [invisible, setInvisible] = useState('custom-filter_invisible')

    const newOrder = () => {

        const defaultEmployeeId = 0

        props.changeOrderState({
            manager: props.user,
            engineer: props.employees.find(employee => employee.id === defaultEmployeeId),
            order_type: props.current_branch.order_type,
            estimated_done_at: calculateEstimateTimeForOrder(props.current_branch)
        })
        props.changeVisibleState({statusOrderEditor: true})
    }

    const handleEditFilter = () => {
        setInvisible(invisible ? '' : 'custom-filter_invisible')
        props.changeFilterState({active_badge: 0,  active_filter: 0})
    }

    return (
        <div>
        <div className='row jc-sb'>
                <div className='custom-filters-container'>
                    <Button
                        id='newOrder'
                        className='fw-bold'
                        size='med'
                        type='create'
                        title='Создать'
                        onClick={newOrder}
                        invisible={!props.permissions.includes(Permissions.CAN_CREATE_ORDERS)}
                    />
                    <Button
                        size='med'
                        type='tertiary'
                        title='Фильтр'
                        onClick={handleEditFilter}
                        icon={ICON.FILTER}
                    />

                    { props.customFilters.map(filter => <CustomFilter key={filter.id} data={filter} />) }

                </div>
                <TableFields
                    id='orders'
                    className='ml10'
                    list={props.order.table_headers}
                    func={table_headers =>  props.changeOrderState({table_headers})}
                />
        </div>
        <SetFilter invisible={invisible}/>
        </div>
)
}

const mapStateToProps = state => ({
    customFilters: state.filter.customFilters,
    statusSetCustomFilter: state.view.statusSetCustomFilter,
    permissions: state.data.user.role.permissions,
    statusOrderEditor: state.view.statusOrderEditor,
    user: state.data.user,
    order: state.order,
    order_types: state.data.order_type,
    employees: state.employee.employees,
    current_branch: state.branch.current_branch
})

const mapDispatchToProps = {
    changeOrderState,
    changeFilterState,
    changeVisibleState,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPanel)