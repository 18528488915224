import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeWarehousePermissionState, resetWarehousePermission} from "../../../../Redux/actions/WarehousePermissionActions"
import {hasIDinHierarchy} from "../../../general/utils"
import {changeVisibleState} from "../../../../Redux/actions"
import {changeWarehouseState} from "../../../../Redux/actions/warehouseAction"
import {changeEmployeeState} from "../../../../Redux/actions/employeeAction"
import {EditWarehousePermission} from "../../../../data/WarehouseEmployeeFor"
import {EditorID, Modal} from "../../../../data/data"
import {CheckboxDate} from "../../../../data/checkboxData"
import {WarehousePermissions} from "../../../../data/warehousePermissions"
import {ButtonData} from "../../../../data/ButtonData"

import ChooseButton from '../../../general/ChooseButton'
import WarningOrange from '../../../general/WarningOrange'
import Button from "../../../general/Button"
import Checkbox from '../../../general/Checkbox'

const WarehouseEmployeeEditor = (props) => {

    const getAllPermissions = () => {
        switch (props.warehousePermission.forEditor) {
            case EditWarehousePermission.FOR_EMPLOYEE:
                return props.employee.warehousePermissions
            case EditWarehousePermission.FOR_WAREHOUSE:
                return props.warehouse.employee_permissions
        }
    }

    const all_permissions = getAllPermissions()

    function handleClose() {
        props.changeVisibleState({isCentralModalOpen: false, modalCentralType: Modal.Type.NONE})
        props.resetWarehousePermission()
    }


    const clickHandel = (event) => {
        const listIDs = [EditorID.WAREHOUSE_EMPLOYEE]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const changePermission = (permissionName) => {

        let permissions = props.warehousePermission.personalPermission

        if (permissions.includes(permissionName)) {
            permissions = permissions.filter(permission => permission !== permissionName)
        } else {
            permissions.push(permissionName)
        }
        props.changeWarehousePermissionState({personalPermission: permissions})
    }

    const handleSave = () => {

        const employeePermission = {
            employee_id: props.warehousePermission.employeeId,
            warehouse_id: props.warehousePermission.warehouseId,
            is_available: props.warehousePermission.isAvailable,
            like_warehouse: props.warehousePermission.likeWarehouse,
            permissions: props.warehousePermission.personalPermission
        }

        const employee_permissions = all_permissions.map(permission => {
            if (
                permission.employee_id === employeePermission.employee_id
                && permission.warehouse_id === employeePermission.warehouse_id
            ) {
                return employeePermission
            } else {
                return permission
            }
        })

        savePermissions(employee_permissions)
        handleClose()
    }

    const savePermissions = (employee_permissions) => {
        switch (props.warehousePermission.forEditor) {
            case EditWarehousePermission.FOR_EMPLOYEE:
                props.changeEmployeeState({warehousePermissions: employee_permissions})
                return
            case EditWarehousePermission.FOR_WAREHOUSE:
                props.changeWarehouseState({employee_permissions: employee_permissions})
                return
        }
    }

    const likeWarehouse = props.warehousePermission.likeWarehouse

    const checkPermission = (permissionName) => {
        return likeWarehouse ?
            props.warehousePermission.warehousePermission.includes(permissionName)
            : props.warehousePermission.personalPermission.includes(permissionName)
    }

    const getTitle = () => {

        switch (props.warehousePermission.forEditor) {
            case EditWarehousePermission.FOR_WAREHOUSE:
                const employee = props.employee.employees.find(employee => employee.id === props.warehousePermission.employeeId)
                return (
                    <>
                        <h4>{employee.name}</h4>
                        <h5>{employee.role.title}</h5>
                    </>
                )
            case EditWarehousePermission.FOR_EMPLOYEE:
                const warehouse = props.warehouse.warehouses.find(warehouse => warehouse.id === props.warehousePermission.warehouseId)
                return (
                    <h4>{warehouse.title}</h4>
                )
        }
    }

    return (
        <div className="modal__box modal__box_editor" id={EditorID.WAREHOUSE_EMPLOYEE}>
            {getTitle()}
            <WarningOrange
                text="Сотрудник сможет выполнять только те действия, которые разрешены в настройках его роли"
                width="500px"
            />
            <div className='modal__block-forms'>
                <ChooseButton
                    title='Выберите права'
                    name={['Доступные для склада', 'Персональные']}
                    func1={() => {props.changeWarehousePermissionState({likeWarehouse: true})}}
                    func2={() => {props.changeWarehousePermissionState({likeWarehouse: false})}}
                    checked={likeWarehouse}
                    disabled={props.warehouse.deleted}
                />
                <Checkbox
                    type={CheckboxDate.Type.SLIDE_THREE}
                    label='Видеть остатки'
                    onChange={() => changePermission(WarehousePermissions.SHOW_WAREHOUSE_REMAINS)}
                    checked={checkPermission(WarehousePermissions.SHOW_WAREHOUSE_REMAINS)}
                    disabled={props.warehouse.deleted || likeWarehouse}
                />
            </div>
            <div className='modal__block-forms_row'>
                <div className='modal__block-forms'>
                    <h5>Входящие операции:</h5>
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Оприходование'
                        onChange={() => changePermission(WarehousePermissions.WAREHOUSE_REGISTRATION)}
                        checked={checkPermission(WarehousePermissions.WAREHOUSE_REGISTRATION)}

                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Перемещение'
                        onChange={() => changePermission(WarehousePermissions.MOVE_IN)}
                        checked={checkPermission(WarehousePermissions.MOVE_IN)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Возврат от клиента'
                        onChange={() => changePermission(WarehousePermissions.RETURN_FROM_CLIENT)}
                        checked={checkPermission(WarehousePermissions.RETURN_FROM_CLIENT)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                </div>
                <div className='modal__block-forms'>
                    <h5>Исходящие операции:</h5>
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Списание'
                        onChange={() => changePermission(WarehousePermissions.WRITE_OFF)}
                        checked={checkPermission(WarehousePermissions.WRITE_OFF)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Перемещение'
                        onChange={() => changePermission(WarehousePermissions.MOVE_OUT)}
                        checked={checkPermission(WarehousePermissions.MOVE_OUT)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Добавление в заказ'
                        onChange={() => changePermission(WarehousePermissions.ADD_TO_ORDER)}
                        checked={checkPermission(WarehousePermissions.ADD_TO_ORDER)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Добавление в продажу'
                        onChange={() => changePermission(WarehousePermissions.ADD_TO_SALE)}
                        checked={checkPermission(WarehousePermissions.ADD_TO_SALE)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Возврат поставщику'
                        onChange={() => changePermission(WarehousePermissions.RETURN_TO_SUPPLIER)}
                        checked={checkPermission(WarehousePermissions.RETURN_TO_SUPPLIER)}
                        disabled={props.warehouse.deleted || likeWarehouse}
                    />
                </div>
            </div>

            <div className="two-buttons">
                <Button
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.PRIMARY}
                    title='Сохранить'
                    onClick={handleSave}
                />
                <Button
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.TERTIARY}
                    title='Закрыть'
                    onClick={handleClose}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    employee: state.employee,
    roles: state.data.roles,
    warehouse: state.warehouse,
    warehousePermission: state.warehousePermission
})

const mapDispatchToProps = {
    changeVisibleState,
    changeWarehouseState,
    changeEmployeeState,
    resetWarehousePermission,
    changeWarehousePermissionState
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseEmployeeEditor)
