import store from '../store'
import {serverRequest} from './actionUtils'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";

export function changeMovementState( data ) {
    return {
        type: Action.WarehouseMovement.CHANGE_STATE,
        data
    }
}

export function resetMovement() {
    return {
        type: Action.WarehouseMovement.RESET
    }
}

function getFilter() {

    const state = store.getState()

    return {
        filter_created_at: state.movement.filter_created_at,
        page: state.movement.page
    }
}

export function getMovement(movement_id) {

    const body = {id: movement_id}
    const url = URLS.WarehouseMovement.GET
    const error_message = 'Запрос Перемещения не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.WarehouseMovement.EDIT,
                movement: data.warehouse_movement,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: true, modalCentralType: Modal.Type.WAREHOUSE_MOVEMENT}
            })
        }

    }
}

export function addMovements() {

    const body = getFilter()
    const url = URLS.WarehouseMovement.ADD
    const error_message = 'Запрос Перемещений не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.WarehouseMovement.CHANGE_STATE,
                data: {warehouse_movements: data.warehouse_movements, count: data.count},
            })
        }
    }
}

export function createMovements() {

    const state = store.getState()

    const body = {
        created_at: parseInt(new Date() / 1000),
        parts: state.movement.parts,
        description: state.movement.description,
        warehouse_id: state.remain.filter_warehouse.id,
        target_warehouse_id: state.movement.target_warehouse.id,
        filter: getFilter()
    }
    const url = URLS.WarehouseMovement.POST
    const error_message = 'Запрос на создание Перемещения не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.WarehouseMovement.CHANGE_STATE,
                data: {warehouse_movements: data.warehouse_movements, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
            dispatch({
                type: Action.WarehouseMovement.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Перемещение успешно создано')
        }
    }
}


export function saveMovements() {

    const state = store.getState()

    const body = {
        id: state.movement.edit,
        description: state.movement.description,
        filter: getFilter()
    }
    const url = URLS.WarehouseMovement.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение Перемещения не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.WarehouseMovement.CHANGE_STATE,
                data: {warehouse_movements: data.warehouse_movements, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
            dispatch({
                type: Action.WarehouseMovement.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Перемещение успешно изменено')
        }
    }
}
