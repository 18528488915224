import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState, addCounters} from '../../../../Redux/actions'
import {addBranches, changeBranchState} from '../../../../Redux/actions/branchAction'
import {ButtonData} from "../../../../data/ButtonData"
import {Modal} from "../../../../data/data"
import {CheckboxDate} from "../../../../data/checkboxData"
import {Permissions} from "../../../../data/permissions"

import SettingHeader from "../SettingHeader"
import Button from "../../../general/Button"
import Checkbox from '../../../general/Checkbox'
import TableBranches from './TableBranches'

const SettingBranches = (props) => {

    useEffect(() => {
        props.addBranches()
    }, [props.showDeleted])


    return (
        <div className='setting-content'>
            <SettingHeader header='Локации'/>
            <div className='box'>
                <div className='box__forms'>
                    <p>Таблица показывает все локации компании. Каждой локации можно присвоить уникальный код и цвет.</p>
                    <div className='two-buttons ai-c'>
                        <Button
                            size={ButtonData.Size.MEDIUM}
                            type={ButtonData.Type.CREATE}
                            title='Локация'
                            onClick={() => props.changeVisibleState({
                                isRightModalOpen: true,
                                modalType: Modal.Type.BRANCH
                            })}
                        />
                        <Checkbox
                            type={CheckboxDate.Type.SLIDE_THREE}
                            label='Показать удаленных'
                            onChange={event => props.changeBranchState({showDeleted: event.target.checked})}
                            checked={props.showDeleted}
                            invisible={!props.permissions.includes(Permissions.CAN_SEE_BRANCHES)}
                        />
                    </div>
                    <TableBranches/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    showDeleted: state.branch.showDeleted,
    permissions: state.data.user.role.permissions,
    employees: state.employee.employees
})

const mapDispatchToProps = {
    changeVisibleState,
    addCounters,
    changeBranchState,
    addBranches
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingBranches)