import React, {useEffect} from 'react'
import {connect} from 'react-redux'


import {changeBranchState} from "../../../../Redux/actions/branchAction"
import {CheckboxDate} from "../../../../data/checkboxData"

import Checkbox from "../../../general/Checkbox"

const BranchPermission = (props) => {

    useEffect(() => {

        const new_permissions = []

        props.employees.map(employee => {

            const permission = props.branch.employee_permissions.find(permission => permission?.employee_id === employee.id)

            if(!permission) {
                new_permissions.push({
                    is_available: true,
                    employee_id: employee.id,
                    branch_id: props.branch.edit
                })
            }
        })

        if(new_permissions){
            props.changeBranchState({employee_permissions: props.branch.employee_permissions.concat(new_permissions)})
        }

    },[])

    const changePermission = (employee_id, value) => {
        const employee_permissions = props.branch.employee_permissions.map(permission => {
            if(permission.employee_id === employee_id){
                permission.is_available = value
            }
            return permission
        })
        props.changeBranchState({employee_permissions})
    }


    return (
        <div className='modal__body-right'>
            <table>
                <thead>
                <tr>
                    <th className='th'>Сотрудник</th>
                </tr>
                </thead>
                <tbody>
                {props.employees.map(employee => {

                    const permission = props.branch.employee_permissions.find(permission => permission.employee_id === employee.id)

                    return (
                        <tr key={employee.id}>
                            <td className='td'>
                                <Checkbox
                                    label={employee.name}
                                    type={CheckboxDate.Type.SLIDE_THREE}
                                    onChange={event => changePermission(employee.id, event.target.checked)}
                                    checked={permission?.is_available}
                                    disabled={props.branch.deleted}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    employees: state.employee.employees.filter(employee => !employee.deleted && employee.id !== 0),
    branch: state.branch
})

const mapDispatchToProps = {
    changeBranchState
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchPermission)