import React from 'react'
import {connect} from 'react-redux'

import {changeBranchState} from '../../../../Redux/actions/branchAction'

import Checkbox from '../../../general/Checkbox'
import {CheckboxDate} from "../../../../data/checkboxData";
import LabelInput from "../../../general/LabelInput";

const Schedule = (props) => {

    const weekdays = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскрсение',
    ]

    const changeSchedule = (idx, fieldName, data) => {
        let schedule = props.branch.schedule
        schedule[idx][fieldName] = data
        props.changeBranchState({schedule})
    }

    const changeHour = (idx, fieldName, hour) => {

        hour.replace(/[^0-9]/g, '')
        hour = parseInt(hour)

        if (!hour || hour < 0) {
            hour = 0
        }
        if (hour > 23) {
            hour = 23
        }
        hour = hour.toString().padStart(2, '0')

        let schedule = props.branch.schedule
        schedule[idx][fieldName] = hour + schedule[idx][fieldName].slice(2, 5)
        console.log('hour:', hour)
        console.log('time:', hour + schedule[idx][fieldName].slice(2, 5))
        props.changeBranchState({schedule})
    }

    const changeMinute = (idx, fieldName, minute) => {

        minute.replace(/[^0-9]/g, '')
        minute = parseInt(minute)

        if (!minute || minute < 0) {
            minute = 0
        }
        if (minute > 59) {
            minute = 59
        }
        minute = minute.toString().padStart(2, '0')

        let schedule = props.branch.schedule
        schedule[idx][fieldName] = schedule[idx][fieldName].slice(0, 3) + minute
        console.log('minute:', minute)
        console.log('time:', schedule[idx][fieldName].slice(0, 3) + minute)
        props.changeBranchState({schedule})
    }

    const sortWeekDays = (a, b) => a.week_day - b.week_day

    return (
        <div className="box__forms">
            <div className='label'>График работы локации</div>
            {props.branch.schedule.sort(sortWeekDays).map((day, idx) => {
                return (
                    <div className="row" key={idx}>
                        <Checkbox
                            className='w150'
                            type={CheckboxDate.Type.SLIDE_THREE}
                            onChange={(event) => changeSchedule(idx, 'work_day', event.target.checked)}
                            checked={day.work_day}
                            label={weekdays[idx]}
                            disabled={props.branch.deleted}
                        />

                        {day.work_day ? (
                            <div className="ml10 row g6 ai-c">
                                <div className='input'>
                                    <input
                                        className="w15"
                                        onChange={event => changeHour(idx, 'start_time', event.target.value)}
                                        value={day.start_time.slice(0, 2)}
                                        disabled={props.branch.deleted}
                                    />
                                    <div className='mb5'>:</div>
                                    <input
                                        className="w15"
                                        onChange={event => changeMinute(idx, 'start_time', event.target.value)}
                                        value={day.start_time.slice(3, 5)}
                                        disabled={props.branch.deleted}
                                    />
                                </div>
                                <div className="w30">&#8212;</div>
                                <div className='input'>
                                    <input
                                        className="w15"
                                        onChange={event => changeHour(idx, 'end_time', event.target.value)}
                                        value={day.end_time.slice(0, 2)}
                                        disabled={props.branch.deleted}
                                    />
                                    <div className='mb5'>:</div>
                                    <input
                                        className="w15"
                                        onChange={event => changeMinute(idx, 'end_time', event.target.value)}
                                        value={day.end_time.slice(3, 5)}
                                        disabled={props.branch.deleted}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                )
            })}
        </div>
    )
}

const mapStateToProps = (state) => ({
    branch: state.branch,
})

const mapDispatchToProps = {
    changeBranchState
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
