import {Action} from "../../data/ActionTypes";

const initialState = {

   edit: 0,          // id при редактировании

   type: {},         // Тип начисления
   orderType: {},    // Тип заказа
   method: 0,        // Начисления (0 - проценты, 1 - руб)
   coefficient: 1,   // Коэффициет при начаслении за работы или запчасти
   count_coeff: [{   // Условия начисления
      cost: 0,
      coef: 0
   }],
   fixSalary: 0,    // Оклад
   deleted: false,   // Удален
   employeeId: 0,   // Сотрудник
   statusGroup: {},  // 4 - по статусе Готов, 6 - по статусу Закрыт

}
 
export const salaryRuleReducer = (state = initialState, action) => {
   switch (action.type){

      case Action.PayRule.CHANGE_STATE: {

         return {...Object.assign(state, action.data)}
      }

      case Action.PayRule.EDIT: {
         return {
            ...state,
            edit: action.salaryRule.id,

            description: action.salaryRule.description,
            type: action.salaryRule.type,
            orderType: action.salaryRule.order_type,
            method: action.salaryRule.method,
            coefficient: action.salaryRule.coefficient,
            count_coeff: action.salaryRule.count_coeff,
            fixSalary: action.salaryRule.fix_salary,
            deleted: action.salaryRule.deleted,
            employeeId: action.salaryRule.employee_id,
            statusGroup: action.salaryRule.status_group
         }
      }

      case Action.PayRule.RESET: {
         return {
            ...state,
            edit: 0,

            type: {},
            method: 0,
            coefficient: 0,
            count_coeff: [{
               cost: 0,
               coef: 0
            }],
            fixSalary: 0,
            deleted: false,
            employeeId: 0,
            statusGroup: {}
         }
      }

      default: return state
   }
    
}
 