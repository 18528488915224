import {includesObject} from '../../components/general/utils'
import {Action} from "../../data/ActionTypes";

const key = 'filter_'

const initialState = {

    clientFilter: {
        page: 0,
        name: '',
        phone: '7'
    },

    search_word: '',


    // ========================
    badges: [],
    customFilters: [],

    title: '',
    general: false,

    sort: JSON.parse(localStorage.getItem(key + 'sort')) || 'desc',
    field_sort:  JSON.parse(localStorage.getItem(key + 'field_sort')) || 'id',
    page: JSON.parse(localStorage.getItem(key + 'page')) || 0,

    engineer_id: JSON.parse(localStorage.getItem(key + 'engineer_id')) || null,
    overdue: JSON.parse(localStorage.getItem(key + 'overdue')) || null,
    status_id: JSON.parse(localStorage.getItem(key + 'status_id')) || null,
    status_overdue: JSON.parse(localStorage.getItem(key + 'status_overdue')) || null,
    urgent: JSON.parse(localStorage.getItem(key + 'urgent')) || null,
    order_type_id: JSON.parse(localStorage.getItem(key + 'order_type_id')) || null,
    manager_id: JSON.parse(localStorage.getItem(key + 'manager_id')) || null,
    created_at: JSON.parse(localStorage.getItem(key + 'created_at')) || null,
    kindof_good: JSON.parse(localStorage.getItem(key + 'kindof_good')) || null,
    brand: JSON.parse(localStorage.getItem(key + 'brand')) || null,
    subtype: JSON.parse(localStorage.getItem(key + 'subtype')) || null,
    client_id: JSON.parse(localStorage.getItem(key + 'client_id')) || null,

    search: '',

    temp_statuses: [],
    temp_order_types: [],
    temp_managers: [],
    temp_engineers: [],
    temp_created_at: [0, 0],
    temp_kindof_good_id: null,
    temp_brand: null,
    temp_subtype: null,
    temp_client: {},

    active_badge: JSON.parse(localStorage.getItem(key + 'active_badge')) || 0,
    active_filter: JSON.parse(localStorage.getItem(key + 'active_filter')) || 0
}

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {


        case Action.Filter.CHANGE_STATE: {
            const local_save = ['sort', 'field_sort', 'page', 'engineer_id', 'overdue', 'status_id', 'status_overdue',
                'urgent', 'order_type_id', 'manager_id', 'created_at', 'kindof_good', 'brand', 'subtype', 'client_id',
                'active_badge', 'active_filter']
            Object.keys(action.data).forEach(field => {
                if (local_save.includes(field)) localStorage.setItem(key + field, JSON.stringify(action.data[field]))
            })
            return {...Object.assign(state, action.data)}
        }

        case Action.Filter.SELECTED: {
                // Объявим переменную для измененных данных
                let new_data
                // Проверим если значения value в списке уже существующих
                if (action.value.every(val => includesObject(val, state[action.field]))) {
                    // Если есть удалим эти значения
                    new_data = state[action.field].filter(val => !includesObject(val, action.value))
                } else {
                    // Если нет добавим эти значения
                    new_data = state[action.field].concat(action.value.filter(val => !includesObject(val, state[action.field])))
                }
                // Если флаг saveToApp установлен сохраним данные на локальном хранилище
                if (action.saveToApp) localStorage.setItem(key + action.field, JSON.stringify(new_data))
                // Вернем измененный стейт
                return {
                    ...state,
                    [action.field]: new_data,
                }
            }

        case Action.Filter.RESET:
            return {
                ...state,
                page: 0,

                engineer_id: null,
                overdue: null,
                status_id: null,
                status_overdue: null,
                urgent: null,

                order_type_id: null,
                manager_id: null,
                created_at: null,
                kindof_good: null,
                brand: null,
                subtype: null,
                client_id: null,

                active_badge: 0,
                active_filter: 0
            }

        case Action.Filter.RESET_TEMP:
            return {
                ...state,
                temp_statuses: [],
                temp_order_types: [],
                temp_managers: [],
                temp_engineers: [],
                temp_created_at: [0, 0],
                temp_kindof_good_id: null,
                temp_brand: null,
                temp_subtype: null,
                temp_client: {},
            }

        case Action.Filter.RESET_DATA:
            return {
                ...state,
                title: '',
                general: false,
            }


        default:
            return state
    }

}
