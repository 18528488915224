import React from 'react'
import {connect} from 'react-redux'

import {changeBranchState} from "../../../../Redux/actions/branchAction"
import {onlyNumbers} from "../../../general/utils"

import LabelInput from "../../../general/LabelInput"
import Schedule from "./Schedule"
import ChooseIcon from "./ChooseIcon"
import SelectFromList from "../../../general/SelectFromList"


const BranchGeneral = (props) => {

    const orderCounter = props.counters.find((count) => count.id === 1)
    const docCounter = props.counters.find((count) => count.id === 2)

    return (
        <div className='modal__body-right'>
            <div className='modal__block-forms_row'>
                <div className='modal__block-forms w250'>
                    <LabelInput
                        title="Название"
                        onChange={event => props.changeBranchState({name: event.target.value})}
                        value={props.branch.name}
                        checkedFlag="inputBranchNameChecked"
                        redStar={true}
                        disabled={props.branch.deleted}
                    />
                    <LabelInput
                        title="Адрес"
                        onChange={event => props.changeBranchState({address: event.target.value})}
                        value={props.branch.address}
                        disabled={props.branch.deleted}
                    />
                    <LabelInput
                        title='Телефон'
                        onChange={value => props.changeBranchState({phone: value})}
                        value={props.branch.phone}
                        disabled={props.branch.deleted}
                        isPhone={true}
                    />
                </div>
                <div className='modal__block-forms w250'>
                    <SelectFromList
                        title='Тип заказа по умолчанию'
                        list={props.order_type}
                        setElement={order_type => props.changeBranchState({order_type})}
                        current_object={props.branch.order_type}
                        disabled={props.branch.deleted}
                    />
                    <LabelInput
                        title="Шаблон номера заказа"
                        onChange={event => props.changeBranchState({orders_prefix: event.target.value})}
                        value={props.branch.orders_prefix}
                        checkedFlag="inputBranchPrefixChecked"
                        redStar={true}
                        disabled={props.branch.deleted}
                    />
                    <p>{`Пример: ${props.branch.orders_prefix}-${orderCounter.count}`}</p>
                    <LabelInput
                        title="Шаблон номера документов"
                        onChange={event => props.changeBranchState({documents_prefix: event.target.value})}
                        value={props.branch.documents_prefix}
                        checkedFlag="inputBranchPrefixDocChecked"
                        redStar={true}
                        disabled={props.branch.deleted}
                    />
                    <p>{`Пример: ${props.branch.documents_prefix}-${docCounter.count}`}</p>
                    <LabelInput
                        title="Добавить к ориентировочной дате готовности заказа"
                        onChange={event => props.changeBranchState({default_estimated_days: onlyNumbers(event.target.value)})}
                        value={props.branch.default_estimated_days}
                        unit='дней'
                        disabled={props.branch.deleted}
                    />
                </div>
            </div>
            <ChooseIcon/>
            <Schedule/>
        </div>
    )
}

const mapStateToProps = state => ({
    branch: state.branch,
    order_type: state.data.order_type,
    counters: state.data.counters,
})

const mapDispatchToProps = {
    changeBranchState,
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchGeneral)