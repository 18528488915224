import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeBookForm(value, field) {
    return {
        type: 'CHANGE_BOOK_FORM',
        field,
        value,
    }
}

export function changeBookState(data) {
    return {
        type: Action.Book.CHANGE_STATE,
        data
    }
}

export function resetBookEquipment() {
    return {
        type: Action.Book.RESET,
    }
}

export function chooseBookSelected(value, field) {
    return {
        type: Action.Book.SELECTED,
        field,
        value,
    }
}

export function createEType() {

    const state = store.getState()

    const body = {
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        branches: state.book.branches,
        deleted: false,
        filter: {
            title: state.book.filter_type,
            page: state.book.page_type - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.TYPE_POST
    const error_message = 'Запрос на создание типа техники не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_types: data.data, equipment_type_count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Тип успешно добавлен')
        }
    }
}

export function createEbrand() {

    const state = store.getState()

    const body = {
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        equipment_type_id: state.book.parent_id,
        branches: state.book.branches,
        deleted: false,
        filter: {
            title: state.book.filter_brand,
            equipment_type_id: state.book.equipment_type.id,
            page: state.book.page_brand - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.BRAND_POST
    const error_message = 'Запрос на создание бренда не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_brands: data.data, equipment_brand_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Бренд успешно добавлен')
        }
    }
}

export function createESubtype() {

    const state = store.getState()

    const body = {
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        equipment_brand_id: state.book.parent_id,
        branches: state.book.branches,
        deleted: false,
        img: state.book.img,
        filter: {
            title: state.book.filter_subtype,
            equipment_brand_id: state.book.equipment_brand.id,
            page: state.book.page_subtype - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.SUBTYPE_POST
    const error_message = 'Запрос на создание модуля/серии не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_subtypes: data.data, equipment_subtype_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Модуль/серия успешно добавлен')
        }
    }
}

export function createEModel() {

    const state = store.getState()

    const body = {
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        equipment_subtype_id: state.book.parent_id,
        branches: state.book.branches,
        deleted: false,
        filter: {
            title: state.book.filter_model,
            equipment_subtype_id: state.book.equipment_subtype.id,
            page: state.book.page_model - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.MODEL_POST
    const error_message = 'Запрос на создание модели не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_models: data.data, equipment_models_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Модель успешно добавлена')
        }
    }
}

export function createEquipmentType(title) {
    
    const state = store.getState()

    const body = {
            title,
            icon: state.book.icon,
            url: state.book.url,
            branches: state.branch.branches.map(branch => branch.id),
            deleted: false,
            filter: {
                title,
                deleted: state.book.showDeleted
            }
        }

    const url = URLS.Book.TYPE_POST
    const error_message = 'Запрос на создание типа техники не выполнен'

    return async (dispatch) => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_types: data.data, equipment_type_count: data.count}
            })
            if (state.view.statusOrderEditor) {
                dispatch({
                    type: Action.Order.CHANGE_STATE,
                    data: {kindof_good: data.equipment_type},
                })
                dispatch({
                type: Action.Book.CHANGE_STATE,
                    data: {filter_type: '', equipment_type: data.equipment_type},
                })
            }
            showAlert(dispatch, AlertType.SUCCESSES, 'Тип успешно добавлен')
        }
    }
}

export function addEquipmentType() {
    
    const state = store.getState()

    const body = {
        title: state.book.filter_type,
        page: state.book.page_type - 1,
        deleted: state.book.showDeleted
    }
    const url = URLS.Book.TYPE_ADD
    const error_message = 'Запрос типов техники не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_types: data.data, equipment_type_count: data.count}
            })
        }
    }
}

export function createEquipmentBrand(title) {

    const state = store.getState()

    const body = {
        title,
        equipment_type_id: state.order.kindof_good.id,
        branches: state.branch.branches.map(branch => branch.id),
        deleted: false,
        filter: {
            title,
            equipment_type_id: state.book.equipment_type.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.BRAND_POST
    const error_message = 'Запрос на создание бренда не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_brands: data.data, equipment_brand_count: data.count}
            })
            if (state.view.statusOrderEditor) {
                dispatch({
                    type: Action.Order.CHANGE_STATE,
                    data: {brand: data.brand},
                })
                dispatch({
                    type: Action.Book.CHANGE_STATE,
                    data: {filter_brand: '', equipment_brand: data.brand},
                })
            }
            showAlert(dispatch, AlertType.SUCCESSES, 'Бренд успешно добавлен')
        }
    }
}

export function addEquipmentBrand() {

    const state = store.getState()

    const body = {
        title: state.book.filter_brand,
        equipment_type_id: state.book.equipment_type.id,
        page: state.book.page_brand - 1,
        deleted: state.book.showDeleted
    }
    const url = URLS.Book.BRAND_ADD
    const error_message = 'Запрос брендов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_brands: data.data, equipment_brand_count: data.count}
            })
        }
    }
}

export function createEquipmentSubtype(title) {

    const state = store.getState()

    const body = {
        title,
        equipment_brand_id: state.order.brand.id,
        branches: state.branch.branches.map(branch => branch.id),
        deleted: false,
        filter: {
            title,
            equipment_brand_id: state.book.equipment_brand.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.SUBTYPE_POST
    const error_message = 'Запрос на создание модуля/серии не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_subtypes: data.data, equipment_subtype_count: data.count}
            })
            if (state.view.statusOrderEditor) {
                dispatch({
                    type: Action.Order.CHANGE_STATE,
                    data: {subtype: data.subtype},
                })
                dispatch({
                    type: Action.Book.CHANGE_STATE,
                    data: {filter_subtype: '', equipment_subtype: data.subtype},
                })
            }
            showAlert(dispatch, AlertType.SUCCESSES, 'Модуль/серия успешно добавлена')
        }
    }
}

export function addEquipmentSubtype() {

    const state = store.getState()

    const body ={
        title: state.book.filter_subtype,
        equipment_brand_id: state.book.equipment_brand.id,
        page: state.book.page_subtype - 1,
        deleted: state.book.showDeleted
    }
    const url = URLS.Book.SUBTYPE_ADD
    const error_message = 'Запрос модулей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_subtypes: data.data, equipment_subtype_count: data.count}
            })
        }
    }
}

export function createEquipmentModel(title) {

    const state = store.getState()

    const body = {
        title,
        equipment_subtype_id: state.order.subtype.id,
        branches: state.branch.branches.map(branch => branch.id),
        deleted: false,
        filter: {
            title,
            equipment_subtype_id: state.book.equipment_subtype.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.MODEL_POST
    const error_message = 'Запрос на создание модели не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_models: data.data, equipment_models_count: data.count}
            })
            if (state.view.statusOrderEditor) {
                dispatch({
                    type: Action.Order.CHANGE_STATE,
                    data: {model: data.model},
                })
                dispatch({
                    type: Action.Book.CHANGE_STATE,
                    data: {filter_model: '', equipment_model: data.model},
                })
            }
            showAlert(dispatch, AlertType.SUCCESSES, 'Модель успешно добавлена')
        }
    }
}

export function addEquipmentModel() {

    const state = store.getState()

    const body = {
        title: state.book.filter_model,
        equipment_subtype_id: state.book.equipment_subtype.id,
        page: state.book.page_model - 1,
        deleted: state.book.showDeleted
    }
    const url = URLS.Book.MODEL_ADD
    const error_message = 'Запрос моделей не выполнен'

    return async (dispatch) => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_models: data.data, equipment_models_count: data.count}
            })
        }
    }
}

export function saveEquipmentType() {

    const state = store.getState()

    const body = {
        id: state.book.edit,
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        branches: state.book.branches,
        list_for_join: state.book.list_for_join.map((equipment) => equipment.id),
        filter: {
            title: state.book.filter_type,
            page: state.book.page_type - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.TYPE_POST
    const error_message = 'Запрос на изменение типа не выполнен'
    const method = RequestMethod.PUT

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_types: data.data, equipment_type_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Тип успешно изменен')
        }
    }
}

export function saveEquipmentBrand() {

    const state = store.getState()

    const body = {
        id: state.book.edit,
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        equipment_type_id: state.book.parent_id,
        branches: state.book.branches,
        list_for_join: state.book.list_for_join.map((equipment) => equipment.id),
        filter: {
            title: state.book.filter_brand,
            equipment_type_id: state.book.equipment_type.id,
            page: state.book.page_brand - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.BRAND_POST
    const error_message = 'Запрос на изменение бренда не выполнен'
    const method = RequestMethod.PUT
    
    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_brands: data.data, equipment_brand_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Бренд успешно изменен')
        }
    }
}

export function saveEquipmentSubtype() {
    
    const state = store.getState()

    const body = {
        id: state.book.edit,
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        img: state.book.img,
        equipment_brand_id: state.book.parent_id,
        branches: state.book.branches,
        list_for_join: state.book.list_for_join.map((equipment) => equipment.id),
        filter: {
            title: state.book.filter_subtype,
            equipment_brand_id: state.book.equipment_brand.id,
            page: state.book.page_subtype - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.SUBTYPE_POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменения модуля/серии не выполнен'
    
    return async (dispatch) => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_subtypes: data.data, equipment_subtype_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Модуль/серия успешно изменена')
        }
    }
}

export function saveEquipmentModel() {

    const state = store.getState()

    const body = {
        id: state.book.edit,
        title: state.book.title,
        icon: state.book.icon,
        url: state.book.url,
        equipment_model_id: state.book.parent_id,
        branches: state.book.branches,
        list_for_join: state.book.list_for_join.map((equipment) => equipment.id),
        filter: {
            title: state.book.filter_model,
            equipment_subtype_id: state.book.equipment_subtype.id,
            page: state.book.page_model - 1,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.MODEL_POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение модели не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_models: data.data, equipment_models_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Модель успешно изменена')
        }
    }
}

export function deleteEquipmentType(flag) {
    
    const state = store.getState()

    const body = {
        id: state.book.edit,
        deleted: flag,
        filter: {
            title: state.book.filter_type,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.TYPE_POST
    const error_message = 'Запрос на удаление/восстановления типов техники не выполнен'
    const method = RequestMethod.PUT
    
    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_types: data.data, equipment_type_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            const text = flag ? 'Тип успешно удален' : 'Тип успешно восстановлен'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}

export function deleteEquipmentBrand(flag) {
    
    const state = store.getState()

    const body = {
        id: state.book.edit,
        deleted: flag,
        filter: {
            title: state.book.filter_brand,
            equipment_type_id: state.book.equipment_type.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.BRAND_POST
    const error_message = 'Запрос на удаление/восстановление бренда не выполнен'
    const method = RequestMethod.PUT

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_brands: data.data, equipment_brand_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            const text = flag ? 'Бренд успешно удален' : 'Бренд успешно восстановлен'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}

export function deleteEquipmentSubtype(flag) {
    
    const state = store.getState()

    const body = {
        id: state.book.edit,
        deleted: flag,
        filter: {
            title: state.book.filter_subtype,
            equipment_brand_id: state.book.equipment_brand.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.SUBTYPE_POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановления модуля/серии не выполнен'

    return async (dispatch) => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_subtypes: data.data, equipment_subtype_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            const text = flag ? 'Модуль/серия успешно удалена' : 'Модуль/серия успешно восстановлена'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}

export function deleteEquipmentModel(flag) {
    
    const state = store.getState()

    const body = {
        id: state.book.edit,
        deleted: flag,
        filter: {
            title: state.book.filter_model,
            equipment_subtype_id: state.book.equipment_subtype.id,
            deleted: state.book.showDeleted
        }
    }
    const url = URLS.Book.MODEL_POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановления модели не выполнен'
    

    return async (dispatch) => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {equipment_models: data.data, equipment_models_count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusEquipmentEditor: false}
            })
            dispatch({
                type: Action.Book.RESET,
            })
            const text = flag ? 'Модель успешно удалена' : 'Модель успешно восстановлена'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}
