import React from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../Redux/actions'
import {changeCashRegisterState, editCashRegister} from '../../../Redux/actions/cashRegisterAction'
import {changePaymentState} from '../../../Redux/actions/paymentAction'
import {ICON} from '../../../data/icons'
import {Modal} from "../../../data/data";
import {CashRegisterPermissions} from "../../../data/cashRegisterPermissions";

import Button from '../../general/Button'
import Icon from '../../general/Icon'


const CashRegister = (props) => {

    const isActive = (id) => {
        return id === props.activ_cashbox_id
    }

    const canEdit = props.user.role.permissions.includes('edit_cash')

    const employeePermission = props.cashbox.employee_permissions.find(permission => permission.employee_id === props.user.id)

    const showIncome = props.user.role.permissions.includes('make_income') &&
        isActive(props.cashbox.id) &&
        (employeePermission?.like_cash_register ?
            props.cashbox.permissions.includes(CashRegisterPermissions.INCOMING) :
            employeePermission?.permissions.includes(CashRegisterPermissions.INCOMING))

    const showOutcome = props.user.role.permissions.includes('make_expenditure') &&
        isActive(props.cashbox.id) &&
        (employeePermission?.like_cash_register ?
            props.cashbox.permissions.includes(CashRegisterPermissions.OUTCOMING) :
            employeePermission?.permissions.includes(CashRegisterPermissions.OUTCOMING))

    const move = props.user.role.permissions.includes('move_money') &&
        isActive(props.cashbox.id) &&
        (employeePermission?.like_cash_register ?
            props.cashbox.permissions.includes(CashRegisterPermissions.OUTCOMING_MOVE) :
            employeePermission?.permissions.includes(CashRegisterPermissions.OUTCOMING_MOVE))

    const showBalance = props.user.role.permissions.includes('see_remains') &&
        (employeePermission?.like_cash_register ?
            props.cashbox.permissions.includes(CashRegisterPermissions.SHOW_CASH_REGISTER_REMAIN) :
            employeePermission?.permissions.includes(CashRegisterPermissions.SHOW_CASH_REGISTER_REMAIN))

    const handleEdit = () => {
        props.editCashbox(props.cashbox)
        props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.CASH_REGISTER})
    }

    const handleIncome = () => {
        props.changePaymentState({
            direction: 2,
            context: {type: 'payment'},
            current_type: props.cashbox.type,
            employee: props.user,
            cashbox: props.cashbox
        })
        props.changeVisibleState({'statusPaymentsEditor': true})
    }

    const handleOutcome = () => {
        props.changePaymentState({
            direction: 1,
            context: {type: 'payment'},
            current_type: props.cashbox.type,
            employee: props.user,
            cashbox: props.cashbox
        })
        props.changeVisibleState({'statusPaymentsEditor': true})
    }

    const handleMove = () => {
        props.changePaymentState({
            direction: 0,
            context: {type: 'payment'},
            current_type: props.cashbox.type,
            employee: props.user,
            cashbox: props.cashbox
        })
        props.changeVisibleState({'statusPaymentsEditor': true})
    }

    return (
        <div
            className={`cashbox ${isActive(props.cashbox.id) ? 'cashbox_active' : ''}`}
            onClick={() => props.changeCashboxState({current_cashbox: props.cashbox})}
        >
            <div className='cashbox__icons'>
                <Icon
                    className='icon'
                    icon={ICON.SPHERE}
                />

                <div
                    onClick={canEdit ? handleEdit : null}
                >
                    <Icon
                        className='icon'
                        icon={ICON.SETTING}
                    />
                </div>
            </div>
            <div className='cashbox__title'>{props.cashbox.title}</div>
            {showBalance ?
                <div className='cashbox__balance'>
                    {`${props.cashbox.balance ? parseFloat(props.cashbox.balance).toFixed(2) : 0} руб.`}
                </div>
                : null}
            <div className='cashbox__buttons'>
                <Button
                    id='PaymentsEditor'
                    size='med'
                    type='create'
                    title='Приход'
                    onClick={handleIncome}
                    disabled={props.cashbox.deleted}
                    invisible={!showIncome}
                />
                <Button
                    id='PaymentsEditor'
                    size='med'
                    type='destructive'
                    title='Расход'
                    onClick={handleOutcome}
                    disabled={props.cashbox.deleted}
                    invisible={!showOutcome}
                />
            </div>
            <Button
                id='PaymentsEditor'
                size='med'
                type='tertiary'
                title='Перемещение'
                onClick={handleMove}
                disabled={props.cashbox.deleted}
                invisible={!move}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.data.user,
    activ_cashbox_id: state.cashbox.current_cashbox.id,
})

const mapDispatchToProps = {
    editCashbox: editCashRegister,
    changeCashboxState: changeCashRegisterState,
    changePaymentState,
    changeVisibleState
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegister)