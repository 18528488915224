import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeCashRegisterPermissionState, resetCashRegisterPermission} from "../../../Redux/actions/cashRegisterPermissionAction";
import {changeVisibleState} from '../../../Redux/actions'
import {hasIDinHierarchy} from "../../general/utils"
import {changeCashRegisterState} from "../../../Redux/actions/cashRegisterAction"
import {changeEmployeeState} from "../../../Redux/actions/employeeAction"
import {CashRegisterPermissions} from "../../../data/cashRegisterPermissions"
import {EditorID, Modal} from "../../../data/data"
import {EditFor} from "../../../data/cashRegisterEmployeeFor"

import ChooseButton from '../../general/ChooseButton'
import WarningOrange from '../../general/WarningOrange'
import Button from '../../general/Button'
import Checkbox from '../../general/Checkbox'
import {CheckboxDate} from "../../../data/checkboxData";
import {ButtonData} from "../../../data/ButtonData";

const CashRegisterEmployeeEditor = (props) => {

    const getAllPermissions = () => {
        switch (props.cashRegisterPermission.forEditor) {
            case EditFor.FOR_EMPLOYEE:
                return props.employee.cashRegisterPermissions
            case EditFor.FOR_CASH_REGISTER:
                return props.cashbox.employee_permissions
        }
    }

    const all_permissions = getAllPermissions()

    function handleClose() {
        props.changeVisibleState({isCentralModalOpen: false, modalCentralType: Modal.Type.NONE})
        props.resetCashRegisterPermission()
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.CASH_REGISTER_EMPLOYEE]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const changePermission = (permissionName) => {
        let permissions = props.cashRegisterPermission.personalPermission
        if(permissions.includes(permissionName)){
            permissions = permissions.filter(permission => permission !== permissionName)
        } else {
            permissions.push(permissionName)
        }
        props.changeCashRegisterPermissionState({personalPermission: permissions})
    }

    const handleSave = () => {

        const employeePermission = {
            employee_id: props.cashRegisterPermission.employeeId,
            cash_register_id: props.cashRegisterPermission.cashRegisterId,
            is_available: props.cashRegisterPermission.isAvailable,
            like_cash_register: props.cashRegisterPermission.likeCashRegister,
            permissions: props.cashRegisterPermission.personalPermission
        }

        const employee_permissions = all_permissions.map(permission => {
            if(
                permission.employee_id === employeePermission.employee_id
                && permission.cash_register_id === employeePermission.cash_register_id
            ){
                return employeePermission
            } else {
                return permission
            }
        })

        savePermissions(employee_permissions)
        handleClose()
    }

    const savePermissions = (employee_permissions) => {
        switch (props.cashRegisterPermission.forEditor) {
            case EditFor.FOR_EMPLOYEE:
                props.changeEmployeeState({cashRegisterPermissions: employee_permissions})
                return
            case EditFor.FOR_CASH_REGISTER:
                props.changeCashRegisterState({employee_permissions: employee_permissions})
                return
        }
    }

    const likeCashRegister = props.cashRegisterPermission.likeCashRegister

    const checkPermission = (permissionName) => {
        return likeCashRegister ?
            props.cashRegisterPermission.cashRegisterPermission.includes(permissionName)
            : props.cashRegisterPermission.personalPermission.includes(permissionName)
    }

    const getTitle = () => {

        switch (props.cashRegisterPermission.forEditor) {
            case EditFor.FOR_EMPLOYEE:
                return (
                    <>
                        <h4>{props.employee.name}</h4>
                        <h5>{props.employee.role.title}</h5>
                    </>
                )
            case EditFor.FOR_CASH_REGISTER:
                return (
                    <h4>{props.cashbox.title}</h4>
                )
        }
    }

    return (
            <div className="modal__box modal__box_editor" id={EditorID.CASH_REGISTER_EMPLOYEE}>
                {getTitle()}
                <WarningOrange
                    text="Сотрудник сможет выполнять только те действия, которые разрешены в настройках его роли"
                    width="500px"
                />
                <div className='modal__block-forms'>
                    <ChooseButton
                        title='Выберите права'
                        name={['Доступные для кассы', 'Персональные']}
                        func1={() => {props.changeCashRegisterPermissionState({likeCashRegister: true})}}
                        func2={() => {props.changeCashRegisterPermissionState({likeCashRegister: false})}}
                        checked={likeCashRegister}
                        disabled={props.cashRegisterPermission.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Видеть остаток денег в кассе'
                        onChange={() => changePermission(CashRegisterPermissions.SHOW_CASH_REGISTER_REMAIN)}
                        checked={checkPermission(CashRegisterPermissions.SHOW_CASH_REGISTER_REMAIN)}
                        disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Видеть денежный поток'
                        onChange={() => changePermission(CashRegisterPermissions.SHOW_CASH_FLOW)}
                        checked={checkPermission(CashRegisterPermissions.SHOW_CASH_FLOW)}
                        disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                    />
                    <div className='modal__block-forms_row'>
                        <div className='modal__block-forms'>
                            <h5>Входящие операции</h5>
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                label='Приход'
                                onChange={() => changePermission(CashRegisterPermissions.INCOMING)}
                                checked={checkPermission(CashRegisterPermissions.INCOMING)}
                                disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                            />
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                label='Перемещение'
                                onChange={() => changePermission(CashRegisterPermissions.INCOMING_MOVE)}
                                checked={checkPermission(CashRegisterPermissions.INCOMING_MOVE)}
                                disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                            />
                        </div>
                        <div className='modal__block-forms'>
                            <h5>Исходящие операции</h5>
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                label='Приход'
                                onChange={() => changePermission(CashRegisterPermissions.OUTCOMING)}
                                checked={checkPermission(CashRegisterPermissions.OUTCOMING)}
                                disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                            />
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                label='Перемещение'
                                onChange={() => changePermission(CashRegisterPermissions.OUTCOMING_MOVE)}
                                checked={checkPermission(CashRegisterPermissions.OUTCOMING_MOVE)}
                                disabled={props.cashRegisterPermission.deleted || likeCashRegister}
                            />
                        </div>
                    </div>
                </div>

                <div className="two-buttons">
                    <Button
                        size={ButtonData.Size.MEDIUM}
                        type={ButtonData.Type.PRIMARY}
                        title='Сохранить'
                        onClick={handleSave}
                    />
                    <Button
                        size={ButtonData.Size.MEDIUM}
                        type={ButtonData.Type.TERTIARY}
                        title='Закрыть'
                        onClick={handleClose}
                    />
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    employee: state.employee,
    cashbox: state.cashbox,
    cashRegisterPermission: state.cashRegisterPermission
})

const mapDispatchToProps = {
    changeVisibleState,
    changeCashRegisterPermissionState,
    resetCashRegisterPermission,
    changeEmployeeState,
    changeCashRegisterState
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterEmployeeEditor)
