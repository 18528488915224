import React from 'react'
import {connect} from 'react-redux'

import {changeBackState, getBack} from '../../../Redux/actions/warehouseBackActions'
import {getRegistration} from "../../../Redux/actions/registrationAction";

import Label from "../../general/cell/Label"
import CreatedAt from "../../general/cell/CreateAt"
import IconTitle from "../../general/cell/IconTitle"
import TableHeader from '../../general/TableHeader'
import Client from "../../general/cell/Client"

const BackTable = (props) => {

    const chooseCell = (header, back) => {

        if (!header.visible) return null

        switch (header.id) {
            case 1:
                return (
                    <Label
                        key={header.id}
                        func={() => handleEdit(back)}
                        label={back.label}
                    />
                )
            case 2:
                return (
                    <CreatedAt
                        key={header.id}
                        creator={back.created_by.name}
                        date={back.created_at}
                    />
                )
            case 3:
                return (
                    <IconTitle
                        key={header.id}
                        title={back.warehouse.title}
                        icon={back.warehouse.branch.icon}
                        color={back.warehouse.branch.color}
                    />
                )
            case 4:
                return (
                    <Client
                        key={header.id}
                        clent={back.client}
                    />
                )
            case 5:
                return (
                    <Label
                        key={header.id}
                        func={() => props.getRegistration(back.registration.id)}
                        label={back.registration.label}
                    />
                )
            default:
                return <td key={header.id}>{back[header.field]}</td>
        }
    }

    const handleEdit = (back) => {
        props.getBack(back.id)
    }

    return (
        <table>
            <thead>
            <tr>
                {props.back.table_headers.map(header => (
                    <TableHeader
                        key={header.id}
                        header={header}
                        changeState={props.changeBackState}
                        headers={props.back.table_headers}
                        // sort_field={props.back.sort_field}
                        // sort={props.back.sort}
                    />
                ))}
            </tr>
            </thead>
            <tbody>
            {props.back.warehouse_backs.map(back => (
                <tr
                    key={back.id}
                    onDoubleClick={() => handleEdit(back)}
                >
                    {props.back.table_headers.map(header => chooseCell(header, back))}
                </tr>
            ))}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    back: state.back,
    view: state.view
})

const mapDispatchToProps = {
    changeBackState,
    getBack,
    getRegistration
}

export default connect(mapStateToProps, mapDispatchToProps)(BackTable)