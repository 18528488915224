import React from 'react'
import {connect} from 'react-redux'

import {getEmployee} from '../../../../Redux/actions/employeeAction'
import {showPhone} from '../../../general/utils'

import Data from "../../../general/cell/Data";

function TableEmployees(props) {
    return (
        <table>
            <thead>
            <tr>
                <th className='th'>Сотрудник</th>
                <th className='th'>Логин</th>
                <th className='th'>email</th>
                <th className='th'>Телефон</th>
                <th className='th'>Роль</th>
            </tr>
            </thead>
            <tbody>
            {props.employees.filter(employee => Boolean(employee.id)).map(employee => {
                return (
                    <tr
                        key={employee.id}
                        className={employee.deleted ? 'tr_deleted' : 'tr'}
                        onDoubleClick={() => {props.getEmployee(employee.id)}}
                    >
                        <Data data={employee.name}/>
                        <Data data={employee.login}/>
                        <Data data={employee.email}/>
                        <Data data={showPhone(employee.phone)}/>
                        <Data data={employee.role.title}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    employees: state.employee.employees
})

const mapDispatchToProps = {
    getEmployee
}

export default connect(mapStateToProps, mapDispatchToProps)(TableEmployees)