import React from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import {editPayRule} from '../../../../Redux/actions/payrulleAction'
import Button from "../../../general/Button";
import {ButtonData} from "../../../../data/ButtonData";
import {EditorID, Modal} from "../../../../data/data";
import Data from "../../../general/cell/Data";

const SalaryRule = (props) => {

    if (!props.employee.edit) return null

    return (
        <div className='box__forms'>
            <h5>Зарплата</h5>
            <Button
                id={EditorID.NEW_SALARY_RULE}
                size={ButtonData.Size.MEDIUM}
                type={ButtonData.Type.CREATE}
                title='Правило'
                onClick={() => props.changeVisibleState({isCentralModalOpen: true, modalCentralType: Modal.Type.SALARY_RULE})}
            />
            <table>
                <thead>
                    <tr>
                        <th className='th'>Правила</th>
                    </tr>
                </thead>
                <tbody>
                {props.employee.payRules.filter(payRule => !payRule.deleted).map(payrule => (
                    <tr
                        key={payrule.id}
                        className={payrule.deleted ? 'tr_deleted' : 'tr'}
                        onDoubleClick={() => props.editPayrule(payrule)}
                    >
                        <Data data={payrule.type.title}/>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    statusSalaryEditor: state.view.statusSalaryEditor,
    employee: state.employee
})

const mapDispatchToProps = {
    changeVisibleState,
    editPayrule: editPayRule
}

export default connect(mapStateToProps, mapDispatchToProps)(SalaryRule)