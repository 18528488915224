import React, {useState} from 'react'
import {connect} from 'react-redux'

import {changeWarehouseState, editWarehouseCategory} from '../../../Redux/actions/warehouseAction'
import {changeVisibleState} from '../../../Redux/actions'
import {changePartState} from '../../../Redux/actions/partAction'
import {ICON} from '../../../data/icons'

import Icon from '../../general/Icon'
import {Permissions} from "../../../data/permissions";
import {Modal} from "../../../data/data";
import {COLORS} from "../../../data/colors";

const CategoryTable = props => {

    const [visibleList, setVisibleList] = useState(false)

    const mainCategory = props.warehouse.warehouse_categories || {id: 1, title: 'Все категории', categories: []}

    const handleChooseMain = (cat) => {
        props.changeWarehouseState({current_category: cat})
        props.changePartState({filter_warehouse_category: cat})
        setVisibleList(!visibleList)
    }

    const handleChoose = (cat) => {
        props.changeWarehouseState({current_category: cat})
        props.changePartState({filter_warehouse_category: cat})
    }

    const handleEdit = (cat) => {
        if (props.permissions.includes(Permissions.CAN_EDIT_WAREHOUSE_CATEGORIES)) {
            props.editWarehouseCategory(cat)
            props.changeWarehouseState({current_parent_category: props.warehouse.current_parent_category})
            props.changeVisibleState({isCentralModalOpen: true, modalCentralType: Modal.Type.WAREHOUSE_CATEGORIES})
        }
    }

    const style = props.warehouse.current_category.id === mainCategory.id ? {backgroundColor: COLORS.NAME.SECOND_BACKGROUND} : null


    return (
        <div className='category'>
            <div className='category__head'>
                <div className='category__th category__th_w100'>Категория</div>
                <div className='category__th category__th_w70'>Кол-во</div>
            </div>

            <div
                className='category__tr'
                style={style}
                onClick={() => handleChooseMain(mainCategory)}
            >
                <div className='category__category-title'>
                    <Icon
                        icon={ICON.DOWN}
                        className={`icon icon_24 ${visibleList ? '' : 'icon_rotate-270'}`}
                    />
                    <div className='nowrap'>{mainCategory.title}</div>
                </div>
                <div className='category__count'>{mainCategory.count}</div>
            </div>
            {visibleList ?
                <div className='category__subcategory'>
                    {mainCategory.categories.map(category => (
                        <WarehouseCategory
                            key={category.id}
                            category={category}
                            choose={cat => handleChoose(cat)}
                            current={props.warehouse.current_category}
                            parent_category={mainCategory}
                            choose_parent_category={cats => props.changeWarehouseState({current_parent_category: cats})}
                            edit={cat => handleEdit(cat)}
                        />
                    ))}
                </div>
                : null
            }

        </div>
    )
}

const WarehouseCategory = props => {

    const [visibleList, setVisibleList] = useState(false)

    const handleChoose = () => {
        props.choose(props.category)
        props.choose_parent_category(props.parent_category)
        setVisibleList(!visibleList)
    }

    const style = props.category.id === props.current.id ? {backgroundColor: COLORS.NAME.SECOND_BACKGROUND} : null
    const className = props.category.deleted ? 'rowDeleted nowrap' : 'nowrap'


    return (
        <>
            <div
                className='category__tr'
                style={style}
                onClick={handleChoose}
                onDoubleClick={() => props.edit(props.category)}
            >
                <div className='category__category-title'>
                    {props.category.categories.length ?
                        <Icon
                            icon={ICON.DOWN}
                            className={`icon icon_24 ${visibleList ? '' : 'icon_rotate-270'}`}
                        />
                        : <div className='ml24'/>
                    }
                    <div className={className}>{props.category.title}</div>
                </div>
                <div className='category__count'>{props.category.count}</div>
            </div>
            {visibleList ?
                <div className='ml10'>
                    {props.category.categories.map(category => (
                        <WarehouseCategory
                            key={category.id}
                            category={category}
                            choose={cat => props.choose(cat)}
                            current={props.current}
                            parent_category={props.category}
                            choose_parent_category={cats => props.choose_parent_category(cats)}
                            edit={cat => props.edit(cat)}
                        />
                    ))}
                </div>
                : null
            }
        </>
    )
}

const mapStateToProps = state => ({
    warehouse: state.warehouse,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeWarehouseState,
    editWarehouseCategory,
    changeVisibleState,
    changePartState
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable)