import React, {useEffect} from 'react'
import {connect} from 'react-redux'


import {changeBackState, createBack, resetBack, saveBack} from '../../../Redux/actions/warehouseBackActions'
import {changeVisibleState} from '../../../Redux/actions'
import {addRemain, changeRemainState} from '../../../Redux/actions/remainAction'
import {checkObject, hasIDinHierarchy} from '../../general/utils'

import BottomButtons from '../../general/BottomButtons'
import SetClient from '../../general/SetClient'
import SelectFromList from '../../general/SelectFromList'
import LabelArea from '../../general/LableArea'
import {EditorID} from "../../../data/data";
import BackPartTable from "./BackPartTable";

const WarehouseBackEditor = (props) => {

    const isEdit = Boolean(props.back.edit)

    useEffect(() => {
        props.addRemain()
    }, [props.remain.filter_warehouse, props.remain.filter_registration_id])

    const handleClose = () => {
        props.resetBack()
        props.changeVisibleState({
            isCentralModalOpen: false,
            modalCentralType: '',
            inputWarehouseBack: true
        })
    }

    const clickHandle = (event) => {

        const listIDs = [EditorID.REGISTRATION, EditorID.BACK, "bottomButtonsExtra"]

        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose();
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandle)
        return () => {
            window.removeEventListener('click', clickHandle)
        }
    })

    const handleSave = () => {
        if (createAndSaveConditions()) {
            props.saveBack()
        } else {
            checkAllConditions();
        }
    }

    const handleCreate = () => {
        if (createAndSaveConditions()) {
            props.createBack()
        } else {
            checkAllConditions();
        }
    }

    const createAndSaveConditions = () => {
        return props.back.parts.length && checkObject(props.remain.filter_warehouse)
    }

    const checkAllConditions = () => {
        if (!checkObject(props.remain.filter_warehouse)) {
            props.changeVisibleState({inputWarehouseBack: false})
        }
    }

    return (
        <div className='modal__box modal__box_editor' id={EditorID.BACK}>
            <h4>{isEdit ? `Возврат ${props.back.label}` : 'Новый возврат'}</h4>

            <div className='modal__body modal__body-editor'>
                <SetClient
                    id='idClientBack'
                    title='Поставщик'
                    setClient={client => console.log(client)}
                    client={props.back.client}
                    disabled={true}
                />
                <div className='row mt15'>
                    <span>Оприходование: </span>
                    <span>{props.back.registration.label}</span>
                </div>
                <SelectFromList
                    title='Вернуть со склада'
                    list={props.warehouses}
                    setElement={warehouse => props.changeRemainState({filter_warehouse: warehouse})}
                    current_object={props.remain.filter_warehouse}
                    checkedFlag='inputWarehouseBack'
                    noChoosed='Выберете склад'
                    disabled={isEdit}
                />
                <BackPartTable/>
                <LabelArea
                    title='Комментарий'
                    onChange={event => props.changeBackState({description: event.target.value})}
                    value={props.back.description}
                />
            </div>

            <BottomButtons
                edit={isEdit}
                create={handleCreate}
                save={handleSave}
                close={handleClose}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    back: state.back,
    remain: state.remain,
    warehouses: state.warehouse.warehouses
})

const mapDispatchToProps = {
    resetBack,
    changeVisibleState,
    createBack,
    changeBackState,
    changeRemainState,
    saveBack,
    addRemain
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseBackEditor)