import React from 'react'
import {connect} from 'react-redux'

import {changeRoleState} from "../../../../../Redux/actions/roleActions";

import ChooseButton from "../../../../general/ChooseButton";


function ChooseButtons(props) {

    const disabled = props.role.deleted

    return (
        <>
            <ChooseButton
                title='Отчет по сотрудникам'
                name={['По всем сотрудникам', 'Только свою ЗП']}
                func1={() => props.changeRoleState({earningsVisibility: true})}
                func2={() => props.changeRoleState({earningsVisibility: false})}
                checked={props.role.earningsVisibility}
                disabled={disabled}
            />
            <ChooseButton
                title='Заказы'
                name={['Все заказы', 'Только свои заказы']}
                func1={() => props.changeRoleState({ordersVisibility: true})}
                func2={() => props.changeRoleState({ordersVisibility: false})}
                checked={props.role.ordersVisibility}
                disabled={disabled}
            />
            <ChooseButton
                title='Обращения'
                name={['Все обращения', 'Только свои обращения']}
                func1={() => props.changeRoleState({leadsVisibility: true})}
                func2={() => props.changeRoleState({leadsVisibility: false})}
                checked={props.role.leadsVisibility}
                disabled={disabled}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role
})

const mapDispatchToProps = {
    changeRoleState
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseButtons)
