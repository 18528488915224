import React from 'react'

import {NavLink} from "react-router-dom";


function SettingGroup({group}) {

    return (
        <div>
            {group.map(row => {
                return (
                    <NavLink
                        key={row.id}
                        className="sidebar__item"
                        activeClassName="sidebar__item_active-settings"
                        to={row.url}
                    >
                        <span className="didebarItemsText">{row.title}</span>
                    </NavLink>
                    )
                }
            )
            }
        </div>
    )}
   
export default SettingGroup