import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../Redux/actions';
import {changeWarehouseState, createWarehouseCategory, resetWarehouse} from '../../../Redux/actions/warehouseAction';
import {saveWarehouseCategory, deleteWarehouseCategory} from '../../../Redux/actions/warehouseAction';
import {hasIDinHierarchy} from "../../general/utils";
import {EditorID, Modal} from "../../../data/data";

import BottomButtons from '../../general/BottomButtons'
import LabelInput from '../../general/LabelInput'
import ChooseCategory from './ChooseCategory';

const WarehouseCategoryEditor = props => {

    const can_delete = props.permissions.includes('delete_warehouse_categories')
    const can_recover = props.permissions.includes('recover_warehouse_categories')

    const isDisabled = props.warehouse.category_deleted
    const isEditing = Boolean(props.warehouse.edit)

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const clickHandel = event => {
        const listIDs = [EditorID.WAREHOUSE_CATEGORIES, EditorID.NEW_CATEGORY]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    const handleClose = () => {
        props.changeVisibleState({
            isCentralModalOpen: false,
            modalCentralType: Modal.Type.NONE,
            inputWCategoryTitleChecked: true
        })
        props.resetWarehouse()
    }

    const checkAllConditions = () => {
        if(props.warehouse.title_category) {
            return true
        } else {
            props.changeVisibleState({inputWCategoryTitleChecked: false})
            return false
        }
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createWarehouseCategory()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveWarehouseCategory()
        }
    }

    return (
            <div className="modal__box modal__box_editor" id={EditorID.WAREHOUSE_CATEGORIES}>
                <h4>Новая категория</h4>

                <div className="modal__body modal__body-editor modal__body-editor_small">
                    <LabelInput
                        title="Название категории"
                        onChange={event => props.changeWarehouseState({title_category: event.target.value})}
                        value={props.warehouse.title_category}
                        checkedFlag="inputWCategoryTitleChecked"
                        redStar={true}
                        disabled={isDisabled}
                    />
                    <ChooseCategory
                        setCategory={category => props.changeWarehouseState({current_parent_category: category})}
                        current_category={props.warehouse.current_parent_category}
                        disabled={isDisabled}
                    />
                </div>

                <BottomButtons
                    edit={isEditing}
                    deleted={props.warehouse.category_deleted}
                    create={handleCreate}
                    save={handleSave}
                    delete={can_delete ? () => props.deleteWarehouseCategory(true) : null}
                    recover={can_recover ? () => props.deleteWarehouseCategory(false) : null}
                    close={handleClose}
                />
            </div>
    )
}

const mapStateToProps = (state) => ({
    warehouse: state.warehouse,
    inputWCategoryTitleChecked: state.view.inputWCategoryTitleChecked,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    createWarehouseCategory,
    resetWarehouse,
    saveWarehouseCategory,
    deleteWarehouseCategory,
    changeWarehouseState
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseCategoryEditor)
