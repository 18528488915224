import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {AlertType} from "../../data/AlertType";

export function changeReqSparePartState( data ) {
    return {
        type: Action.SparePart.CHANGE_STATE,
        data
    }
}

export function resetReqSparePart() {
    return {
        type: Action.SparePart.RESET
    }
}

export function selectedReqSparePart( value, field, saveToApp=true ) {
    return {
        type: Action.SparePart.SELECTED,
        field,
        value,
        saveToApp
    }
}

function getFilter() {
    const state = store.getState()
    return {
        deleted: state.reqsp.show_deleted,
        created_at: state.reqsp.filter_created_at.some(created_at => !!created_at) ? state.reqsp.filter_created_at : null,
        status_id: state.reqsp.filter_status.map(employee => employee.id),
        created_by_id: state.reqsp.filter_created_by.map(employee => employee.id),
        executor_id: state.reqsp.filter_executor.map(employee => employee.id),
        supplier_id: state.reqsp.filter_supplier.id,
        page: state.reqsp.page
    }
}

export function getReqSparePart(request_spare_part_id) {

    const body = {id: request_spare_part_id}
    const url = URLS.SparePart.GET
    const error_message = 'Запрос Запроса запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.SparePart.EDIT,
                reqsp: data.request_spare_part,
            })
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {events: data.events},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.REQUEST_SPARE_PART}
            })
        }
    }
}

export function addReqSparePart() {

    const body = getFilter()
    const url = URLS.SparePart.ADD
    const error_message = 'Запрос Запросов запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {request_spare_parts: data.request_spare_parts, count: data.count},
            })
        }
    }
}

export function createReqSparePart() {

    const state = store.getState()

    const body = {
        created_at: Math.round(new Date() / 1000),
        estimated_come_at: state.reqsp.estimated_come_at,
        amount: state.reqsp.amount,
        cost: state.reqsp.cost,
        delivery_cost: state.reqsp.delivery_cost,
        description: state.reqsp.description,
        part_id: state.reqsp.part.id,
        executor_id: state.reqsp.executor.id,
        client_id: state.reqsp.client.id,
        supplier_id: state.reqsp.supplier.id,
        order_id: state.reqsp.order.id,
        status_id: 35,
        branch_id: state.branch.current_branch.id,
        filter: getFilter()
    }
    const url = URLS.SparePart.POST
    const error_message = 'Запрос на создание Запроса запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {request_spare_parts: data.request_spare_parts, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusReqSparePartEditor: false},
            })
            dispatch({
                type: Action.SparePart.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Запрос успешно создан')
        }
    }
}

export function saveReqSparePart() {

    const state = store.getState()

    const body = {
        id: state.reqsp.edit,
        estimated_come_at: state.reqsp.estimated_come_at,
        amount: state.reqsp.amount,
        cost: state.reqsp.cost,
        delivery_cost: state.reqsp.delivery_cost,
        description: state.reqsp.description,
        part_id: state.reqsp.part.id,
        executor_id: state.reqsp.executor.id,
        client_id: state.reqsp.client.id,
        supplier_id: state.reqsp.supplier.id,
        order_id: state.reqsp.order.id,
        status_id: state.reqsp.status.id,
        branch_id: state.branch.current_branch.id,
        filter: getFilter()
    }
    const url = URLS.SparePart.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение Запроса запчастей не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {request_spare_parts: data.request_spare_parts, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusReqSparePartEditor: false},
            })
            dispatch({
                type: Action.SparePart.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Запрос успешно изменен')
        }

    }
}

export function deleteReqSparePart(flag) {

    const state = store.getState()

    const body = {
        id: state.reqsp.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.SparePart.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление Запроса запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {request_spare_parts: data.request_spare_parts, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusReqSparePartEditor: false},
            })
            dispatch({
                type: Action.SparePart.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Запрос успешно удален/восстановлен')
        }
    }
}

export function addEventComment() {

    const state = store.getState()

    const body = {
        request_spare_part_id: state.reqsp.edit,
        current_status_id: state.reqsp.status.id,
        branch_id: state.branch.current_branch.id,
        comment: state.reqsp.event_comment
    }
    const url = URLS.SparePart.COMMENT
    const error_message = 'Запрос на создание комментария не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {events: data.events || [], event_comment: ''}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Комментарий успешно добавлен')
        }
    }

}

export function changeStatus(status_id, request_spare_part_id) {

    const state = store.getState()

    const body = {
        request_spare_part_id,
        status_id,
        branch_id: state.branch.current_branch.id,
        filter: getFilter()
    }
    const url = URLS.SparePart.CHANGE_STATUS
    const error_message = 'Запрос на изменение статуса не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            if (state.reqsp.edit) {
                dispatch({
                    type: Action.SparePart.CHANGE_STATE,
                    data: {status: data.status, events: data.events}
                })
            }
            dispatch({
                type: Action.SparePart.CHANGE_STATE,
                data: {request_spare_parts: data.request_spare_parts, count: data.count}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Статус успешно изменен')
        }
    }
}