import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'

import {changeRoleState} from "../../../../../Redux/actions/roleActions";
import {selectValues} from "../../../../general/utils";
import {CheckboxDate} from "../../../../../data/checkboxData";

import Checkbox from "../../../../general/Checkbox";

const PermissionsGroup = (props) => {

    const handleSelect = (values) => {
        const permissions = selectValues(values, props.permissions)
        props.changeRoleState({permissions})
    }

    const mainCheckbox = useRef()

    useEffect(() => {
        const values = props.data.permission.filter(per => props.permissions.includes(per.value))
        if (values.length === props.data.permission.length) {
            mainCheckbox.current.indeterminate = false
            mainCheckbox.current.checked = true
        } else if (!values.length) {
            mainCheckbox.current.indeterminate = false
            mainCheckbox.current.checked = false
        } else {
            mainCheckbox.current.indeterminate = true
        }
    }, [props.permissions])

    return (
        <div className="box__forms">
            <Checkbox
                type={CheckboxDate.Type.SQUARED_FIVE}
                label={props.data.description}
                onChange={() => handleSelect(props.data.permission.map(per => per.value))}
                checked={props.data.permission.map(data => data.value).every(permission => props.permissions.includes(permission))}
                ref={mainCheckbox}
                disabled={props.disabled}
            />
            {props.data.permission.map((permission, idx) => (
                <Checkbox
                    key={idx}
                    className='ml15'
                    type={CheckboxDate.Type.SQUARED_FIVE}
                    label={permission.description}
                    onChange={() => handleSelect([permission.value])}
                    checked={props.permissions.includes(permission.value)}
                    disabled={props.disabled}
                />
            ))}
        </div>
    )
}

const mapStateToProps = state => ({
    permissions: state.role.permissions
})

const mapDispatchToProps = {
    changeRoleState
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsGroup)