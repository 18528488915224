import {Table} from '../../data/tableHeaders'
import {Action} from "../../data/ActionTypes";

const initialState = {

    warehouse_remains: [],
    remains_count: 0,

    edit: 0,
    where_to_buy: '',                   // Ссылка на поставщиков
    cell: '',                           // Адрес хранения
    count: 0,                            // Количество
    warranty_period: 0,                 // Гарантийный период
    deleted: false,                     // Удален

    part: {},
    part_id: 0,                         // Деталь
    category_id: 0,                     // Категория
    id_warehouse: {},
    warehouse_id: 0,                     // Склад

    page: 0,
    filter_title: '',
    filter_warehouse: JSON.parse(localStorage.getItem('remain_filter_warehouse')) || {},
    filter_category: JSON.parse(localStorage.getItem('remain_filter_category')) || {},
    filter_type:  JSON.parse(localStorage.getItem('remain_filter_type')) || {id: 0, title: 'Все'},
    filter_registration_id: 0,
    showDeleted: false,

    type_option: [
        {id: 0, title: 'Все'},
        {id: 1, title: 'Ниже минимального остатка'},
        {id: 2, title: 'Только в наличии'}
    ],

    table_headers:  JSON.parse(localStorage.getItem('remain_table_headers')) || Table.Fields.Remain,
    sort_field: 'id',
    sort: 'asc'
}

export const remainReducer = (state = initialState, action) => {
    switch (action.type){

        case Action.Remain.CHANGE_STATE: {
            const local_save = ['filter_warehouse', 'filter_category', 'filter_type', 'table_headers']
            Object.keys(action.data).forEach(field => {
               if (local_save.includes(field)) localStorage.setItem(`remain_${field}`, JSON.stringify(action.data[field]))
            })
            return {...Object.assign(state, action.data)}
        }

        default: return state
    }

}