import React from 'react'
import { connect } from 'react-redux'

import {editWarehouse, getWarehouse} from "../../../../Redux/actions/warehouseAction"

import IconTitle from "../../../general/cell/IconTitle";
import Data from "../../../general/cell/Data";

const TableWarehouse = (props) => {

    const handleEdit = (warehouse_id) => {
        if (props.permissions.includes('setting_edit_warehouse')) {
            props.getWarehouse(warehouse_id)
        }
    }

   return (
      <table>
         <thead>
            <tr>
               <th className='th th_w150'>Название</th>
               <th className='th'>Описание</th>
            </tr>
         </thead>
          <tbody>
          {props.warehouses.map(warehouse => (
              <tr
                  key={warehouse.id}
                  className={warehouse.deleted ? 'tr_deleted' : 'tr'}
                  onDoubleClick={() => handleEdit(warehouse.id)}
              >
                  <IconTitle
                      title={warehouse.title}
                      icon={warehouse.branch.icon}
                      color={warehouse.branch.color}
                  />
                  <Data data={warehouse.description}/>
              </tr>
          ))}
          </tbody>
      </table>
   )
}

const mapStateToProps = state => ({
    warehouses: state.warehouse.warehouses,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    editWarehouse,
    getWarehouse
}
  
 export default connect(mapStateToProps, mapDispatchToProps)(TableWarehouse)