import {ICON} from "../../data/icons"
import {Action} from "../../data/ActionTypes";

const initialState = {
    branches: [],
    current_branch: {},

    tabs: 0,

    edit: 0,
    name: '',
    address: '',
    phone: '',
    color: 'grey',
    icon: ICON.LOCATION,
    order_type: {},
    orders_type_strategy: 'last',
    orders_prefix: 'OTS',
    documents_prefix: 'A',
    employees: [],
    deleted: false,
    default_estimated_days: 4,
    employee_permissions: [],
    schedule: [
        {
            start_time: '09:00',
            end_time: '18:00',
            work_day: true,
            week_day: 1
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: true,
            week_day: 2
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: true,
            week_day: 3
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: true,
            week_day: 4
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: true,
            week_day: 5
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: false,
            week_day: 6
        }, {
            start_time: '09:00',
            end_time: '18:00',
            work_day: false,
            week_day: 7
        },
    ],

    showDeleted: false
}

export const branchReducer = (state = initialState, action) => {
    switch (action.type) {

        case Action.Branch.CHANGE_STATE: {
            // const local_save = []
            // Object.keys(action.data).forEach(field => {
            //    if (local_save.includes(field)) localStorage.setItem(field, JSON.stringify(action.data[field]))
            // })
            return {...Object.assign(state, action.data)}
        }

        case Action.Branch.RESET: {

            return {
                ...state,
                name: '',
                address: '',
                phone: '',
                color: 'grey',
                icon: ICON.LOCATION,
                orders_type: {},
                orders_type_strategy: 'last',
                orders_prefix: 'OTS',
                documents_prefix: 'A',
                employees: [],
                deleted: false,
                employee_permissions: [],
                default_estimated_days: 4,
                schedule: [
                    {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: true,
                        week_day: 1
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: true,
                        week_day: 2
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: true,
                        week_day: 3
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: true,
                        week_day: 4
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: true,
                        week_day: 5
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: false,
                        week_day: 6
                    }, {
                        start_time: '09:00',
                        end_time: '18:00',
                        work_day: false,
                        week_day: 7
                    },
                ],

                edit: 0
            }
        }

        case Action.Branch.EDIT: {

            return {
                ...state,
                name: action.branch.name,
                address: action.branch.address,
                phone: action.branch.phone,
                color: action.branch.color,
                icon: action.branch.icon,
                order_type: action.branch.order_type,
                orders_type_strategy: action.branch.orders_type_strategy,
                orders_prefix: action.branch.orders_prefix,
                documents_prefix: action.branch.documents_prefix,
                employees: action.branch.employees,
                deleted: action.branch.deleted,
                default_estimated_days: action.branch.default_estimated_days,
                schedule: action.branch.schedule,
                employee_permissions: action.branch.employee_permissions,

                edit: action.branch.id
            }
        }

        default:
            return state
    }

}
