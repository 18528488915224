import React, {useState} from 'react'
import { connect } from 'react-redux'

import {changeVisibleState} from "../../../Redux/actions"
import {ButtonData} from "../../../data/ButtonData"


import Button from "../../general/Button"
import LabelInput from "../../general/LabelInput"
import {changeEmployeeState, changePassword} from "../../../Redux/actions/employeeAction";

const UserPassword = (props) => {

    const changePassword = () => {
        if(props.employee.newPassword !== props.employee.repeatPassword){
            props.changeVisibleState({inputNewPassword: false})
        } else {
            props.changePassword()
        }

    }

    return (
        <div className='box ai-c jc-c'>
            <div className='box__forms w250'>
                {!props.view.inputOldPassword ? (<span className="login__error-message">Неправильный пароль</span>) : null}
                <LabelInput
                    title="Старый пароль"
                    password={true}
                    onChange={event => props.changeEmployeeState({oldPassword: event.target.value})}
                    value={props.employee.oldPassword}
                    redStar={true}
                    checkedFlag='inputOldPassword'
                />
                {!props.view.inputNewPassword ? (<span className="login__error-message">Пароли не совпадают</span>) : null}
                <LabelInput
                    title="Новый пароль"
                    password={true}
                    onChange={event => props.changeEmployeeState({newPassword: event.target.value})}
                    value={props.employee.newPassword}
                    redStar={true}
                    checkedFlag='inputNewPassword'
                />
                <LabelInput
                    title="Новый пароль еще раз"
                    password={true}
                    onChange={event => props.changeEmployeeState({repeatPassword: event.target.value})}
                    value={props.employee.repeatPassword}
                    redStar={true}
                    checkedFlag='inputNewPassword'
                />
                <Button
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.PRIMARY}
                    title='Сохранить'
                    onClick={() => changePassword()}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    employee: state.employee,
    view: state.view
})

const mapDispatchToProps = {
    changeVisibleState,
    changeEmployeeState,
    changePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPassword)