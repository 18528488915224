import store from '../store'
import {serverRequest} from './actionUtils'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";


export function changePriceState(data) {
    return {
        type:  Action.Price.CHANGE_STATE,
        data
    }
}

export function editPrice(price) {
    return {
        type: Action.Price.EDIT,
        price
    }
}

export function resetPrice() {
    return {
        type: Action.Price.RESET
    }
}

export function addServicePrices() {

    const body = {}
    const url = URLS.Price.ADD
    const error_message = 'Запрос цен на услуги не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type:  Action.Price.CHANGE_STATE,
                data: {service_prices: data.service_prices}
            })
        }
    }
}

export function createSaveServicePrice(id, cost, discount_margin_id, service_id) {

    const body = {
        id,
        cost,
        discount_margin_id,
        service_id
    }
    const url = URLS.Price.POST
    const error_message = 'Запрос на создание цены услуги не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type:  Action.Price.CHANGE_STATE,
                data: {service_prices: data.service_prices}
            })
        }
    }
}

function getFilter() {
    const state = store.getState()
    return {
        deleted: state.price.showDeleted,
        margin_type: state.price.filter_type,
        page: state.price.page
    }
}

export function addDiscountMargin() {

    const body = {}
    const url = URLS.DiscountMargin.ADD
    const error_message = 'Запрос наценок не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type:  Action.Price.CHANGE_STATE,
                data: {discount_margin: data.discount_margin},
            })
        }
    }
}

export function createDiscountMargin() {

    const state = store.getState()

    const body = {
        title: state.price.title,
        margin: state.price.margin,
        margin_type: state.price.margin_type,
        deleted: state.price.deleted,
        filter: getFilter()
    }
    const url = URLS.DiscountMargin.POST
    const error_message = 'Запрос на создание наценки не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type:  Action.Price.CHANGE_STATE,
                data: {discount_margin: data.discount_margin},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusPriceEditor: false}
            })
            dispatch({
                type: Action.Price.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Наценка успешно создана')
        }
    }
}

export function saveDiscountMargin() {

    const state = store.getState()

    const body = {
        id: state.price.edit,
        title: state.price.title,
        margin: state.price.margin,
        margin_type: state.price.margin_type,
        deleted: state.price.deleted,
        filter: getFilter()
    }
    const url = URLS.DiscountMargin.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение цены не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Price.CHANGE_STATE,
                data: {discount_margin: data.discount_margin},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusPriceEditor: false}
            })
            dispatch({
                type: Action.Price.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Наценка успешно изменена')
        }
    }
}

export function deleteDiscountMargin(flag) {

    const state = store.getState()

    const body = {
        id: state.price.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.DiscountMargin.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление наценки не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type:  Action.Price.CHANGE_STATE,
                data: {discount_margin: data.discount_margin},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusPriceEditor: false}
            })
            dispatch({
                type: Action.Price.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Наценка успешно удалена/восстановлена')
        }
    }
}
