import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {addNotEvent, changeNotEventState} from '../../../../Redux/actions/notEventAction'
import {addNotTemplate} from '../../../../Redux/actions/notTemplateAction'

import Tabs from '../../../general/Tabs'
import SettingHeader from "../SettingHeader"
import OrderNotification from './OrderNotification'
import NotificationTemplate from './NotificationTemplate'

const SettingNotification = props => {

    useEffect(() => {
        props.addNotTemplate()
    }, [props.notTemplate.showDeleted])

    useEffect(() => {
        props.addNotEvent()
    }, [props.notEvent.showDeleted])

    const tabContent = [(<OrderNotification/>), null, null, (<NotificationTemplate/>)]

    return (
        <div className='setting-content'>
            <SettingHeader header='Оповещения'/>
            <div className='box'>

                <Tabs
                    list={['Заказы', 'Обращения', 'Задачи', 'Шаблоны']}
                    tab={props.notEvent.tabs}
                    func={idx => props.changeNotEventState({tabs: idx})}
                />
                {tabContent[props.notEvent.tabs]}

            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    notEvent: state.notEvent,
    notTemplate: state.notTemplate
})

const mapDispatchToProps = {
    changeNotEventState,
    addNotTemplate,
    addNotEvent
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingNotification)