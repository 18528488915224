import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeRoleState, createRole, deleteRole, resetRole, saveRole} from "../../../../../Redux/actions/roleActions";
import {changeVisibleState,} from '../../../../../Redux/actions'
import {hasIDinHierarchy} from "../../../../general/utils";
import {permission} from '../../../../../data/permissions'
import {EditorID, Modal} from "../../../../../data/data";

import BottomButtons from '../../../../general/BottomButtons'
import PermissionsGroup from './PermissionsGroup'
import ChooseAvailableStatuses from './ChooseAvailableStatuses'
import ChooseButton from './ChooseButton'
import SelectAvailablePrices from './SelectAvailablePrices'
import LabelInput from "../../../../general/LabelInput";

const RoleEditor = (props) => {

    const orderStatusGroupIDs = [1, 2, 3, 4, 5, 6, 7]
    const leadStatusGroupIDs = [8, 9, 10, 11, 12]
    const disabled = props.role.deleted

    const handleClose = () => {
        props.resetRole()
        props.changeVisibleState({
            modalType: Modal.Type.NONE,
            isRightModalOpen: false,
            inputRoleTitleChecked: true
        })
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.ROLE]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkAllConditions = () => {
        if (props.role.title) {
            return true
        } else {
            props.changeVisibleState({inputRoleTitleChecked: false})
            return false
        }
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createRole()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveRole()
        }
    }

    return (
        <div
            className="modal__box-right"
            id={EditorID.ROLE}
        >
            <h4>{props.edit ? props.title_create : ' Новая роль'}</h4>

            <div className="modal__body-right">
                <LabelInput
                    title='Название'
                    onChange={event => props.changeRoleState({title: event.target.value})}
                    value={props.role.title}
                    checkedFlag='inputRoleTitleChecked'
                    disabled={disabled}
                    redStar={true}
                />

                {permission.map((permission, idx) => (
                    <PermissionsGroup
                        key={idx}
                        data={permission}
                        disabled={disabled}
                    />
                ))}

                <ChooseButton/>

                <ChooseAvailableStatuses
                    header='Доступные статусы заказов'
                    status={props.status.filter(status => orderStatusGroupIDs.includes(status.group))}
                />

                <ChooseAvailableStatuses
                    header='Доступные статусы обращений'
                    status={props.status.filter(status => leadStatusGroupIDs.includes(status.group))}
                />

                <SelectAvailablePrices/>
            </div>

            <BottomButtons
                edit={props.role.edit}
                deleted={props.role.deleted}
                create={handleCreate}
                save={handleSave}
                delete={() => props.deleteRole(true)}
                recover={() => props.deleteRole(false)}
                close={handleClose}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
    status: state.data.status,
    inputRoleTitleChecked: state.view.inputRoleTitleChecked,
})

const mapDispatchToProps = {
    changeRoleState,
    createRole,
    saveRole,
    deleteRole,
    resetRole,
    changeVisibleState,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleEditor)
