import {permission_cahsbox} from "../../data/permissions";
import {Action} from "../../data/ActionTypes";

const key = 'cashbox_'

const initialState = {

   cashboxes: [],

   tabs: JSON.parse(localStorage.getItem(key + 'tabs')) || 0,
   edit: 0,
   tabs_editor: 0,

   title: '',
   balance: 0,
   type: 0,
   isGlobal: false,
   isVirtual: false,
   deleted: false,
   permissions: permission_cahsbox,
   branch_id: 0,
   employee_permissions: [],

   showDeleted: false,

   employeeId: 0, // id сотрудника, права которого редактируются в данный момент
   cashRegisterId: 0,
   isAvailable: false,
   likeCashRegister: false,
   personalPermission: [],

   is_access_prepared: false,

   current_cashbox: {} // активная касса
}

export const cashboxReducer = (state = initialState, action) => {
   switch (action.type){

      case 'CHANGE_CASH_REGISTER_FORM': {
         return {
            ...state, 
            [action.field]: action.value,
         }
      }

      case Action.CashRegister.CHANGE_STATE: {
         const local_save = ['tabs']
         Object.keys(action.data).forEach(field => {
            if (local_save.includes(field)) localStorage.setItem(key + field, JSON.stringify(action.data[field]))
         })
         return {...Object.assign(state, action.data)}
      }

      case Action.CashRegister.SELECTED: {
         if (action.id.every(id => state[action.field].includes(id))) {
            return {
               ...state, 
               [action.field]: state[action.field].filter(id => !action.id.includes(id)),
            }
         } else {
            return {
               ...state, 
               [action.field]: state[action.field].concat(action.id.filter(id => !state[action.field].includes(id))),
            }
         }
      }
      
      case Action.CashRegister.RESET: {
         return {
            ...state, 
            title: '',
            balance: 0,
            type: 0,
            isGlobal: false,
            isVirtual: false,
            deleted: false,
            permissions: permission_cahsbox,
            branch_id: 0,
            employee_permissions: [], //
            permissions_employee: 0 // id сотрудника, права которого редактируются в данный момент
         }
      }
      
      case Action.CashRegister.EDIT: {
         return {
            ...state, 
            edit: action.cashRegister.id,
            title: action.cashRegister.title,
            balance: action.cashRegister.balance,
            type: action.cashRegister.type,
            isGlobal: action.cashRegister.isGlobal,
            isVirtual: action.cashRegister.isVirtual,
            deleted: action.cashRegister.deleted,
            permissions: action.cashRegister.permissions,
            branch_id: action.cashRegister.branch_id,
            employee_permissions: action.cashRegister.employee_permissions,
            permissions_employee: action.cashRegister.permissions_employee
         }
      }

      default: return state
   }
   
}
