import store from '../store'

import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {Modal} from "../../data/data";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeEmployeeState(data) {
    return {
        type: Action.Employee.CHANGE_STATE,
        data
    }
}

export function editEmployee(employee) {
    return {
        type: Action.Employee.EDIT,
        employee
    }
}

export function resetEmployee() {
    return {
        type: Action.Employee.RESET
    }
}

function getFilter() {
    const state = store.getState()
    return {
        deleted: state.employee.showDeleted
    }
}

export function getEmployee(employee_id){

    const state = store.getState()

    const body = {id: employee_id}
    const url = URLS.Employee.GET
    const error_message = 'Запрос сотрудника не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Employee.EDIT,
                employee: data.employee,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.EMPLOYEE}
            })
        }
    }
}

export function addEmployees() {

    const body = getFilter()
    const url = URLS.Employee.ADD
    const error_message = 'Запрос сотрудников не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {employees: data.employees},
            })
        }
    }
}

export function createEmployee() {

    const state = store.getState()

    const body = {
        first_name: state.employee.first_name,
        last_name: state.employee.last_name,
        email: state.employee.email,
        notes: state.employee.notes,
        phone: state.employee.phone.replace(/[^0-9]/g, ''),
        password: state.employee.password,
        role_id: state.employee.role.id,
        default_branch_id: state.employee.default_branch.id,
        login: state.employee.login,
        inn: state.employee.inn,
        doc_name: state.employee.doc_name,
        avatar: state.employee.avatar,
        img: state.employee.img || null,
        cash_register_permissions: state.employee.cashRegisterPermissions,
        branch_permissions: state.employee.branchPermissions,
        warehouse_permissions: state.employee.warehousePermissions,
        filter: getFilter()
    }
    const url = URLS.Employee.POST
    const error_message = 'Запрос на создание сотрудника не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {employees: data.employees}
            })
            dispatch({
                type: Action.Employee.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.EMPLOYEE}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Сотрудник успешно создан')
        }
    }
}

export function saveEditEmployee() {

    const state = store.getState()

    const body = {
        id: state.employee.edit,
        first_name: state.employee.first_name,
        last_name: state.employee.last_name,
        email: state.employee.email,
        notes: state.employee.notes,
        phone: state.employee.phone.replace(/[^0-9]/g, ''),
        password: state.employee.password,
        role_id: state.employee.role.id,
        default_branch_id: state.employee.default_branch.id,
        login: state.employee.login,
        inn: state.employee.inn,
        doc_name: state.employee.doc_name,
        avatar: state.employee.avatar,
        img: state.employee.img || null,
        cash_register_permissions: state.employee.cashRegisterPermissions,
        branch_permissions: state.employee.branchPermissions,
        warehouse_permissions: state.employee.warehousePermissions,
        filter: getFilter()
    }
    const url = URLS.Employee.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение сотрудника не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {employees: data.employees}
            })
            dispatch({
                type: Action.Employee.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.EMPLOYEE}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Сотрудник успешно изменен')
        }
    }
}

export function deleteEmployee(flag) {

    const state = store.getState()

    const body = {
        id: state.employee.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Employee.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос удаление/восстановление сотрудника не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {employees: data.employees}
            })
            dispatch({
                type: Action.Employee.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.EMPLOYEE}
            })
            const text = flag ? 'Сотрудник успешно удален' : 'Сотрудник успешно восстановлен'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}


export function saveAvatar(data) {

    const state = store.getState()

    const body = {
        employee_id: state.employee.edit,
        left: data.avaPosition[0],
        top: data.avaPosition[1],
        size: data.size,
        img: state.employee.img
    }
    const url = URLS.Employee.CHANGE_AVATAR
    const error_message = 'Запрос на изменение аватара не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Data.CHANGE_STATE,
                data: {user: data.user},
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Аватарка успешно изменена')
        }
    }
}

export function logout() {

    return () => fetch(process.env.REACT_APP_URL_SERVER + URLS.Employee.LOGOUT)
}

export function changePassword() {

    const state = store.getState()

    const body = {
        old_password: state.employee.oldPassword,
        new_password: state.employee.newPassword
    }
    const url = URLS.Employee.CHANGE_PASSWORD
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение пароля не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Data.CHANGE_STATE,
                data: {user: data.user},
            })
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {oldPassword: '', newPassword: '', repeatPassword: ''},
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Пароль успешно изменен')
        } else {
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {inputOldPassword: false}
            })
        }

    }
}