import store from '../store'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {RequestMethod} from "../../data/requestMethod";

export function getRequestConfig(body = {}) {

    const state = store.getState()

    return {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": state.data.csrfToken,
            'Authorization': `Bearer ${state.data.jwt_token}`
        },
        credentials: state.data.credentials,
        body: JSON.stringify(body)
    }
}

export function bad_request(dispatch, error, message = '') {
    console.warn(message)
    console.log(error)
    if (error.code === 422) {
        sessionStorage.clear()
        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {statusRefreshPage: true, statusOrderLoader: false}
        })
        dispatch({
            type: Action.Data.CHANGE_STATE,
            data: {jwt_token: '', login_status: false}
        })
    }
    showAlert(dispatch, 'danger', message)
}

export function badAnswer(dispatch, message, code) {
    console.warn(message)
    if (code === 422) {
        sessionStorage.clear()
        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {statusRefreshPage: true, statusOrderLoader: false}
        })
        dispatch({
            type: Action.Data.CHANGE_STATE,
            data: {jwt_token: '', login_status: false}
        })
    }
    showAlert(dispatch, 'danger', message)
}

export function expired_token(dispatch){
    dispatch({
        type: Action.Data.CHANGE_STATE,
        data: {jwt_token: '', login_status: false}
    })
    showAlert(dispatch, 'danger', 'Время сессии истекло')
}

export async function serverRequest(body, url, dispatch, error_message, method=RequestMethod.POST) {

    const state = store.getState()

    const request_config = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${state.data.jwt_token}`
        },
        credentials: state.data.credentials,
        body: JSON.stringify(body)
    }

    return await fetch(state.data.url_server + url, request_config)
        .then(response => {

            if (response.status === 422){
                dispatch({
                    type: Action.Data.CHANGE_STATE,
                    data: {jwt_token: '', login_status: false}
                })
                showAlert(dispatch, 'danger', 'Время сессии истекло')
            }

            return response.json()
        })
        .then(data => {

            if(!data.success){
                console.warn(data.message)
                showAlert(dispatch, 'danger', error_message)
            }

            return data
        })
            .catch(error => {
                console.warn(error)
                showAlert(dispatch, 'danger', error_message)
                return {success: false}
            })

}