export const CashRegisterPermissions = Object.freeze({
    SHOW_CASH_REGISTER_REMAIN: 'show_cashbox_remains',
    SHOW_CASH_FLOW: 'show_cash_flow',
    INCOMING: 'incoming',
    INCOMING_MOVE: 'incoming_move',
    OUTCOMING: 'outcoming',
    OUTCOMING_MOVE: 'outcoming_move',
})

export const DefaultEmployeeCashRegisterPermission = {
    is_available: true,
    like_cash_register: true,
    permissions:
    [
        CashRegisterPermissions.SHOW_CASH_REGISTER_REMAIN,
        CashRegisterPermissions.SHOW_CASH_FLOW,
        CashRegisterPermissions.INCOMING,
        CashRegisterPermissions.INCOMING_MOVE,
        CashRegisterPermissions.OUTCOMING,
        CashRegisterPermissions.OUTCOMING_MOVE,
    ]
}

