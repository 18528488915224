import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";


export function changeCashRegisterState(data ) {
    return {
        type: Action.CashRegister.CHANGE_STATE,
        data
    }
}

export function chooseCashRegisterSelected(id, field) {
    return {
        type: Action.CashRegister.SELECTED,
        id,
        field
    }
}

export function editCashRegister(cashRegister) {
    return {
        type: Action.CashRegister.EDIT,
        cashRegister: cashRegister
    }
}

export function resetCashRegister(){
    return {
        type: Action.CashRegister.RESET
    }
}

export function getCashRegisterFilter() {

    const state = store.getState()

    return {
        deleted: state.cashbox.showDeleted,
        branch_id: Object.values(state.branch.current_branch).length ? state.branch.current_branch.id : null
    }
}

export function addCashRegisters() {

    const body = getCashRegisterFilter()
    const url = URLS.CashRegister.ADD
    const error_message = 'Запрос касс не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.CashRegister.CHANGE_STATE,
                data: {cashboxes: data.data, current_cashbox: data.data.length ? data.data[0] : {}},
            })
        }

    }
}

export function createCashRegister() {

    const state = store.getState()

    const body = {
        title: state.cashbox.title,
        balance: state.cashbox.balance,
        type: state.cashbox.type,
        isGlobal: state.cashbox.isGlobal,
        isVirtual: state.cashbox.isVirtual,
        deleted: state.cashbox.deleted,
        permissions: state.cashbox.permissions,
        branch_id: state.branch.current_branch.id,
        employee_permissions: state.cashbox.employee_permissions,
        filter: getCashRegisterFilter()
    }
    const url = URLS.CashRegister.POST
    const error_message = 'Запрос на создание кассы не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.CashRegister.CHANGE_STATE,
                data: {cashboxes: data.cashboxes},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.CashRegister.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Касса успешно создана')
        }
    }
}


export function saveEditCashRegister() {

    const state = store.getState()

    const body = {
        id: state.cashbox.edit,
        title: state.cashbox.title,
        balance: state.cashbox.balance,
        type: state.cashbox.type,
        isGlobal: state.cashbox.isGlobal,
        isVirtual: state.cashbox.isVirtual,
        deleted: state.cashbox.deleted,
        permissions: state.cashbox.permissions,
        employees: state.cashbox.employees,
        branch_id: state.branch.current_branch.id,
        employee_permissions: state.cashbox.employee_permissions,
        filter: getCashRegisterFilter()
    }
    const url = URLS.CashRegister.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение кассы не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.CashRegister.CHANGE_STATE,
                data: {cashboxes: data.cashboxes},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.CashRegister.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Касса успешно изменена')
        }
    }
}

export function deleteCashRegister(flag) {

    const state = store.getState()

    const body = {
        id: state.cashbox.edit,
        deleted: flag,
        filter: getCashRegisterFilter()
    }
    const url = URLS.CashRegister.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление кассы не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.CashRegister.CHANGE_STATE,
                data: {cashboxes: data.cashboxes},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.CashRegister.RESET
            })
            const text = flag ? 'Касса успешно удалена' : 'Касса успешно восстановлена'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}