import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'

import {setVisibleFlag} from '../../../../Redux/actions'
import {changeBranchState} from '../../../../Redux/actions/branchAction'

import Icon from '../../../general/Icon'
import {ICON} from '../../../../data/icons'

const ChooseIcon = (props) => {

    const [showList, setShowList] = useState(false)

    const elementRef = useRef()

    const clickHandel = (event) => {
        if (showList && !elementRef.current?.contains(event.target)) {
            setShowList(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const colors = [
        '#cccccc',
        '#d70000',
        '#f6c000',
        '#2ab676',
        '#0099e8',
        '#3d4fb8',
        '#9a33b4',
        '#616161',
        '#7784cd',
        '#f8622b',
        '#e87d6f',
        '#038140',
    ]

    const icons = [
        ICON.LOCATION,
        ICON.MAP_LOCATION,
        ICON.WRENCH,
        ICON.HAMMER,
        ICON.RADIO,
        ICON.GIT,
        ICON.SVG
    ]

    return (
        <div className='p-r'>
            <div className="label">Цвет и иконка локации</div>
            <div
                className='pd5'
                onClick={props.branch.deleted ? null : () => setShowList(true)}
            >
                <Icon
                    className='icon icon_32'
                    icon={props.branch.icon}
                    color={props.branch.color}
                />
            </div>

            {showList ?
                <div
                    ref={elementRef}
                    className='select-icon'
                >
                    {icons.map((icon, idx) => {
                        return (
                            <div className="row" key={idx}>
                                {colors.map((color, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className='pd5'
                                            onClick={() => {
                                                props.changeBranchState({color, icon})
                                                setShowList(false)
                                            }}
                                        >
                                            <Icon
                                                className='icon icon_24'
                                                icon={icon}
                                                color={color}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    branch: state.branch,
    statusChooseIcon: state.view.statusChooseIcon,
})

const mapDispatchToProps = {
    setVisibleFlag,
    changeBranchState
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseIcon)
