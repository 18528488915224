import {Action} from "../../data/ActionTypes";

const initialState = {
    employees: [],

    tabs: 0,
    edit: 0,
    showDeleted: false,

    first_name: '',
    last_name: '',
    name: '',
    email: '',
    notes: '',
    phone: '',
    avatar: '',
    img: '',
    login: '',
    password: '',
    role: {},
    permissions: [],
    inn: '',
    doc_name: '',
    post: '',
    deleted: false,
    default_branch: {},

    oldPassword: '',
    newPassword: '',
    repeatPassword: '',

    payRules: [],

    cashRegisterPermissions: [],
    branchPermissions: [],
    warehousePermissions: [],

    avaStartPosition: [0, 0],
    scale_img: 100
}

export const employeeReducer = (state = initialState, action) => {
    switch (action.type) {

        case Action.Employee.CHANGE_STATE: {
            return {...Object.assign(state, action.data)}
        }

        case Action.Employee.EDIT: {
            return {
                ...state,
                edit: action.employee.id,
                first_name: action.employee.first_name || '',
                last_name: action.employee.last_name || '',
                name: action.employee.name || '',
                email: action.employee.email || '',
                notes: action.employee.notes || '',
                phone: action.employee.phone || '',
                login: action.employee.login || '',
                role: action.employee.role,
                permissions: action.employee.permissions || [],
                inn: action.employee.inn || '',
                doc_name: action.employee.doc_name || '',
                post: action.employee.post || '',
                deleted: action.employee.deleted,
                default_branch: action.employee.default_branch,
                avatar: action.employee.avatar,
                payRules: action.employee.payrules,
                cashRegisterPermissions: action.employee.cash_register_permissions,
                branchPermissions: action.employee.branch_permissions,
                warehousePermissions: action.employee.warehouse_permissions
            }
        }

        case Action.Employee.RESET: {
            return {
                ...state,
                edit: 0,
                first_name: '',
                last_name: '',
                name: '',
                email: '',
                notes: '',
                phone: '',
                login: '',
                password: '',
                role: {},
                permissions: [],
                inn: '',
                doc_name: '',
                post: '',
                deleted: false,
                default_branch: {},
                avatar: '',
                img: '',
                payRules: [],
                cashRegisterPermissions: [],
                branchPermissions: [],
                warehousePermissions: [],
            }
        }


        default:
            return state
    }

}
