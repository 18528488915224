import store from '../store'
import {serverRequest} from './actionUtils'
import {Action} from "../../data/ActionTypes";
import {Modal} from "../../data/data";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";

export function changeSalaryRuleState(data) {
    return {
        type: Action.PayRule.CHANGE_STATE,
        data
    }
}

export function editPayRule(salaryRule) {
    return dispatch => {
        dispatch({
            type: Action.PayRule.EDIT,
            salaryRule
        })
        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {isCentralModalOpen: true, modalCentralType: Modal.Type.SALARY_RULE},
        })
    }
}

export function resetPayRule() {
    return {
        type: Action.PayRule.RESET
    }
}

export function createSalaryRule() {

    const state = store.getState()

    const body = {
        type_id: state.salaryRule.type.id,
        order_type: state.salaryRule.orderType.id,
        method: state.salaryRule.method,
        coefficient: state.salaryRule.coefficient,
        count_coeff: state.salaryRule.count_coeff,
        fix_salary: state.salaryRule.fixSalary,
        deleted: state.salaryRule.deleted,
        employee_id: state.employee.edit,
        status_group_id: state.salaryRule.statusGroup.id
    }
    const url = URLS.PayRule.POST
    const error_message = 'Запрос на создание правила не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Employee.EDIT,
                employee: data.employee
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.PayRule.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Правило успешно создано')
        }
    }
}

export function saveSalaryRule() {

    const state = store.getState()

    const body = {
        id: state.salaryRule.edit,
        type_id: state.salaryRule.type.id,
        order_type: state.salaryRule.orderType.id,
        method: state.salaryRule.method,
        coefficient: state.salaryRule.coefficient,
        count_coeff: state.salaryRule.count_coeff,
        fix_salary: state.salaryRule.fixSalary,
        deleted: state.salaryRule.deleted,
        employee_id: state.employee.edit,
        status_group_id: state.salaryRule.statusGroup.id
    }
    const url = URLS.PayRule.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение правила не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Employee.EDIT,
                employee: data.employee
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.PayRule.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Правило успешно изменено')
        }
    }
}

export function deleteSalaryRule(flag) {

    const state = store.getState()

    const body = {
        id: state.salaryRule.edit,
        deleted: flag
    }
    const url = URLS.PayRule.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление правила не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Employee.EDIT,
                employee: data.employee
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.PayRule.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Правило успешно удалено')
        }
    }
}