import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";

import {changeVisibleState} from '../../../../Redux/actions'
import {changeWarehouseState, resetWarehouse, createWarehouse} from "../../../../Redux/actions/warehouseAction"
import {saveWarehouse, deleteWarehouse} from "../../../../Redux/actions/warehouseAction"
import {hasIDinHierarchy} from "../../../general/utils"
import {permission_warehouse} from "../../../../data/permissions"
import {EditorID, Modal} from "../../../../data/data"

import BottomButtons from '../../../general/BottomButtons'
import Tabs from "../../../general/Tabs"
import WarehouseInfo from "./WarehouseInfo"
import WarehouseAccess from "./WarehouseAccess"


const WarehouseEditor = props => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputWarehouseTitleChecked: true
        })
        props.resetWarehouse()
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.WAREHOUSE, EditorID.WAREHOUSE_EMPLOYEE]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    useEffect(() => {
        if (!props.warehouse.edit) {
            let list_per = {}
            props.employees.forEach(employee => {
                list_per[employee.id] = {}
                list_per[employee.id].available = true
                list_per[employee.id].like_warehouse = true
                list_per[employee.id].permissions = permission_warehouse
            })
            props.changeWarehouseState({employees: list_per})
        }
    }, [])

    const checkAllConditions = () => {
        if(props.warehouse.title){
            return true
        } else {
            if (!props.warehouse.title) {
                props.changeVisibleState({inputWarehouseTitleChecked: false})
            }
            return false
        }
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createWarehouse()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveWarehouse()
        }
    }

    const can_delete = props.permissions.includes('setting_delete_warehouse')
    const can_recover = props.permissions.includes('setting_recover_warehouse')

    const tabContent = [
        (<WarehouseInfo/>),
        (<WarehouseAccess/>)
    ]

    return (
        <div className="modal__box-right" id={EditorID.WAREHOUSE}>
            <h4>{props.warehouse.edit ? props.warehouse.title : 'Новый склад'}</h4>


            <Tabs
                list={['Общие', 'Доступ']}
                func={idx => props.changeWarehouseState({tabs: idx})}
                tab={props.warehouse.tabs}
            />

            {tabContent[props.warehouse.tabs]}

            <BottomButtons
                edit={ props.warehouse.edit }
                deleted={ props.warehouse.deleted }
                create={ handleCreate }
                save={ handleSave }
                delete={ can_delete ? () => props.deleteWarehouse(true) : null }
                recover={ can_recover ? () => props.deleteWarehouse(false) : null }
                close={ handleClose }
            />
        </div>
    )
}

WarehouseEditor.propTypes = {
    tabs: PropTypes.number
}

const mapStateToProps = state => ({
    warehouse: state.warehouse,
    employees: state.employee.employees.filter(employee => !employee.deleted),
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    changeWarehouseState,
    resetWarehouse,
    createWarehouse,
    saveWarehouse,
    deleteWarehouse
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseEditor)
