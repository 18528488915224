import {Action} from "../../data/ActionTypes";

const initialState = {

    forEditor: '',
    employeeId: 0,
    warehouseId: 0,
    isAvailable: true,
    likeWarehouse: true,
    personalPermission: [],
    warehousePermission: []
}

export const warehousePermissionReducer = (state = initialState, action) => {
    switch (action.type){

        case Action.WarehousePermission.CHANGE_STATE: {
            return {...Object.assign(state, action.data)}
        }


        case Action.WarehousePermission.EDIT: {
            return {
                ...state,
                forEditor: action.permission.forEditor,
                employeeId: action.permission.employeeId,
                warehouseId: action.permission.warehouseId,
                isAvailable: action.permission.isAvailable,
                likeWarehouse: action.permission.likeWarehouse,
                personalPermission: action.permission.personalPermission,
                warehousePermission: action.permission.warehousePermission,
            }
        }

        case Action.WarehousePermission.RESET: {
            return {
                ...state,
                forEditor: '',
                employeeId: 0,
                warehouseId: 0,
                isAvailable: true,
                likeWarehouse: true,
                personalPermission: [],
                warehousePermission: []
            }
        }

        default: return state
    }

}