import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeEmployeeState} from "../../../../Redux/actions/employeeAction"
import {changeVisibleState} from "../../../../Redux/actions";
import {Modal} from "../../../../data/data"
import {DefaultWarehousePermission} from "../../../../data/warehousePermissions"
import {EditWarehousePermission} from "../../../../data/WarehouseEmployeeFor"
import {CheckboxDate} from "../../../../data/checkboxData"

import Data from "../../../general/cell/Data"
import Checkbox from "../../../general/Checkbox"
import {changeWarehousePermissionState} from "../../../../Redux/actions/WarehousePermissionActions";

function WarehouseEmployeePermissions(props) {

    useEffect(() => {

        const new_permissions = []

        props.warehouses.map(warehouse => {

            const permission = props.employee.warehousePermissions.find(permission => permission?.warehouse_id === warehouse.id);

            if (!permission) {
                new_permissions.push({
                    ...DefaultWarehousePermission,
                    warehouse_id: warehouse.id,
                    employee_id: props.employee.edit
                })
            }
        })

        if (new_permissions) {
            props.changeEmployeeState({warehousePermissions: props.employee.warehousePermissions.concat(new_permissions)})
        }
    }, [])

    useEffect(() => {
    }, [props.employee.warehousePermissions])

    const handleEdit = (permission, warehouse) => {
        props.changeWarehousePermissionState({
            forEditor: EditWarehousePermission.FOR_EMPLOYEE,
            employeeId: permission.employee_id,
            warehouseId: permission.warehouse_id,
            isAvailable: permission.is_available,
            likeWarehouse: permission.like_warehouse,
            personalPermission: permission.permissions,
            warehousePermission: warehouse.permissions
        })

        props.changeVisibleState({
            modalCentralType: Modal.Type.WAREHOUSE_EMPLOYEE,
            isCentralModalOpen: true
        })
    }

    const handleCheck = (warehouseId, value) => {
        const warehousePermissions = props.employee.warehousePermissions
        warehousePermissions.map(permission => {
            if (permission.warehouse_id === warehouseId) {
                permission.is_available = value
            }
            return permission
        })
        props.changeEmployeeState({warehousePermissions})
    }

    const getPermission = (warehouseId) => {
        const permission = props.employee.warehousePermissions.find(permission => permission?.warehouse_id === warehouseId);
        return permission || DefaultWarehousePermission
    }

    return (
        <table>
            <thead>
            <tr>
                <th className='th th_w60'/>
                <th className='th th_w180'>Склад</th>
                <th className='th th_w180'>Права</th>
            </tr>
            </thead>
            <tbody>
            {props.warehouses.map(warehouse => {

                const permission = getPermission(warehouse.id)
                const permission_type = permission.is_available ?
                    (permission.like_warehouse ? 'Доступные для склада' : 'Персональные')
                    : 'Нет доступа'

                return (
                    <tr
                        key={warehouse.id}
                        className='tr'
                        onDoubleClick={() => handleEdit(permission, warehouse)}
                    >
                        <td className='td'>
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                onChange={event => handleCheck(warehouse.id, event.target.checked)}
                                checked={permission.is_available}
                                disabled={props.employee.deleted}
                            />
                        </td>
                        <Data data={warehouse.title}/>
                        <Data data={permission_type}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    warehouses: state.warehouse.warehouses,
    employee: state.employee
})

const mapDispatchToProps = {
    changeVisibleState,
    changeEmployeeState,
    changeWarehousePermissionState
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseEmployeePermissions)