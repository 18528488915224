import store from '../store'
import {serverRequest} from './actionUtils'

import {Modal} from '../../data/data'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";

export function changePartState( data ) {
    return {
        type: Action.Part.CHANGE_STATE,
        data
    }
}

export function editPart(part) {
    return {
        type: Action.Part.EDIT,
        part
    }
}

export function resetPart() {
    return {
        type: Action.Part.RESET,
    }
}

export function choosePartSelected(value, field, saveToApp=false) {
    return {
        type: Action.Part.SELECT,
        field,
        value,
        saveToApp
    }
}

export function changePartProperty(value, idx, field) {
    return {
        type: Action.Part.CHANGE_PROPERTY,
        value,
        idx,
        field
    }
}

export function addPartProperty() {
    return {
        type: Action.Part.ADD_PROPERTY
    }
}

export function deletePartProperty(idx) {
    return {
        type: Action.Part.DELETE_PROPERTY,
        idx
    }
}

export function resetResidueRule() {
    return {
        type: Action.Residue.RESET,
    }
}

export function editResidueRule(residue_rule) {
    return dispatch => {
        dispatch({
            type: Action.Residue.EDIT,
            residue_rule
        })
        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {isCentralModalOpen: true, modalCentralType: Modal.Type.RESIDUE_RULE}
        })
    }
}

function getFilter() {
    const state = store.getState()
    return {
        title: state.part.filter_name,
        deleted: state.part.showDeleted,
        warehouse_category_id: state.part.filter_warehouse_category.id,
        page: state.part.page
    }
}

export function getPart(part_id) {

    const state = store.getState()
    if (!state.data.user.role.permissions.includes('edit_part')) return {type: 'NOTHING'}

    const body = {id: part_id}
    const url = URLS.Part.GET
    const error_message = 'Запрос товара/запчасти не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Part.EDIT,
                part:  data.part
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.PART}
            })
        }
    }
}

export function addParts() {

    const body = getFilter()
    const url = URLS.Part.ADD
    const error_message = 'Запрос товаров/запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {parts: data.parts, count_parts: data.count}
            })
        }
    }
}

export function createPart() {

    const state = store.getState()

    const body = {
        title: state.part.title,
        description: state.part.description,
        marking: state.part.marking,
        article: state.part.article,
        barcode: state.part.barcode,
        code: state.part.code,
        earnings_percent: state.part.visible_option ? state.part.earnings_percent : 0,
        earnings_sum: state.part.visible_option ? state.part.earnings_sum : 0,
        specifications: state.part.specifications,
        prices: state.part.prices,
        deleted: false,
        warranty_period: state.part.warranty_period,
        warehouse_category_id: state.part.warehouse_category.id,
        img: state.part.img,
        doc: state.part.doc,
        filter: getFilter()
    }
    const url = URLS.Part.POST
    const error_message = 'Запрос на создание товара/запчасти не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {parts: data.parts, count_parts: data.count}
            })
            // Если создаем запчать при оприходовании
            if(state.part.createForRegistration) {
                dispatch({
                    type: Action.Registration.CHANGE_STATE,
                    data: {part: data.new_part, prices: data.new_part.prices}
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {
                        isRightModalOpen: true,
                        modalType: Modal.Type.REGISTRATION,
                        isCentralModalOpen: true,
                        modalCentralType: Modal.Type.REGISTRATION_PART
                    }
                })
            }
            // Если создаем запчасть при запросе запчастей
            if(state.part.createForRequestSparePart) {
                dispatch({
                    type: Action.SparePart.CHANGE_STATE,
                    data: {part: data.new_part}
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {isRightModalOpen: true, modalType: Modal.Type.REQUEST_SPARE_PART}
                })
            }
            dispatch({
                type: Action.Part.RESET
            })
        }
    }
}

export function savePart() {

    const state = store.getState()

    const body = {
        id: state.part.edit,
        title: state.part.title,
        description: state.part.description,
        marking: state.part.marking,
        article: state.part.article,
        barcode: state.part.barcode,
        code: state.part.code,
        earnings_percent: state.part.visible_option ? state.part.earnings_percent : 0,
        earnings_sum: state.part.visible_option ? state.part.earnings_sum : 0,
        specifications: state.part.specifications,
        warranty_period: state.part.warranty_period,
        prices: state.part.prices,
        warehouse_category_id: state.part.warehouse_category.id,
        img: state.part.img,
        doc: state.part.doc,
        filter: getFilter()
    }
    const url = URLS.Part.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение товара/запчасти не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {parts: data.parts, count_parts: data.count}
            })
            dispatch({
                type: Action.Part.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
        }
    }
}

export function deletePart( flag ) {

    const state = store.getState()

    const body = {
        id: state.part.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Part.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление товара не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {parts: data.parts, count_parts: data.count}
            })
            dispatch({
                type: Action.Part.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
        }
    }
}

export function createResidueRule() {

    const state = store.getState()

    const body = {
        part_id: state.part.edit,
        warehouse_id: state.part.warehouse.id,
        min_residue: parseInt(state.part.min_residue),
        necessary_amount: parseInt(state.part.necessary_amount)
    }
    const url = URLS.ResidueRule.POST
    const error_message = 'Запрос на создание правила остатков товара не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {residue_rules: data.residue_rules}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
            dispatch({
                type: Action.Residue.RESET
            })
        }
    }
}

export function saveResidueRule() {

    const state = store.getState()

    const body = {
        id: state.part.edit_residue_rules,
        part_id: state.part.edit,
        warehouse_id: state.part.warehouse.id,
        min_residue: parseInt(state.part.min_residue),
        necessary_amount: parseInt(state.part.necessary_amount)
    }
    const url = URLS.ResidueRule.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение правила остатков товара не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {residue_rules: data.residue_rules}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
            dispatch({
                type: Action.Residue.RESET
            })
        }
    }
}

export function deleteResidueRule() {

    const state = store.getState()

    const body = {
        id: state.part.edit_residue_rules,
        part_id: state.part.edit
    }
    const url = URLS.ResidueRule.POST
    const method = RequestMethod.DELETE
    const error_message = 'Запрос на удаление правила остатков товара не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Part.CHANGE_STATE,
                data: {residue_rules: data.residue_rules}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''}
            })
            dispatch({
                type: Action.Residue.RESET
            })
        }
    }
}
