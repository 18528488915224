import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {addBatches} from '../../../Redux/actions/remainAction'
import {changeBackState} from '../../../Redux/actions/warehouseBackActions'
import Balance from "../../general/cell/Balance";
import InputCell from "../../general/cell/InputCell";
import Data from "../../general/cell/Data";

const BackPartTable = (props) => {

    useEffect(() => {
        if (!props.back.edit) props.addBatches()
    }, [props.remain.filter_registration_id, props.remain.filter_warehouse])


    useEffect(() => {
        let price = 0
        props.parts.forEach(part => {
            price += part.buy_cost * part.target_count
        })
        props.changeBackState({price})
    }, [props.back.flag])


    const handleChange = (value, idx) => {

        value = parseInt(value.replace(/[^0-9]/g, ''))

        const parts = props.back.parts

        if (0 <= value && value <= parts[idx].count) {
            parts[idx].target_count = value
        } else if (value > parts[idx].count) {
            parts[idx].target_count = parts[idx].count
        } else {
            parts[idx].target_count = 1
        }

        props.changeBackState({parts, flag: !props.back.flag})
    }

    const handleChangePrice = (value, idx) => {
        value = parseInt(value.replace(/[^0-9]/g, ''))
        const parts = props.back.parts
        parts[idx].buy_cost = value
        props.changeBackState({parts, flag: !props.back.flag})
    }


    return (
        <div className='table ovf-a'>
            <table>
                <thead>
                <tr>
                    <th className='th th_w180'>Наименование</th>
                    <th className='th th_w100'>Количество</th>
                    <th className='th th_w90'>Стоимость</th>
                    <th className='th th_w70'>Итого</th>
                </tr>
                </thead>
                <tbody>
                {props.back.parts.map((part, idx) => (
                    <tr
                        key={part.id}
                        className='tr tr_tools'
                    >
                        <td className='td'>{part.part.title} {part.part.marking ? `(${part.part.marking})` : ''}</td>
                        { props.back.edit ?
                            <>
                                <Data data={part.target_count}/>
                                <Data data={part.buy_cost}/>
                            </>
                            :
                            <>
                                <InputCell
                                    onChange={value => handleChange(value, idx)}
                                    value={part.target_count}
                                    after={`/ ${part.count}`}
                                />
                                <InputCell
                                    onChange={value => handleChangePrice(value, idx)}
                                    value={part.buy_cost}
                                    widthInput='50px'
                                />
                            </>
                        }
                        <Balance balance={part.target_count * part.buy_cost}/>
                    </tr>
                ))}
                <tr className='tr_no-underline'>
                    <td className='td' colSpan='3'>Итого сумма:</td>
                    <Balance balance={props.back.price}/>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    remain: state.remain,
    back: state.back,
    parts: state.back.parts
})

const mapDispatchToProps = {
    addBatches,
    changeBackState
}

export default connect(mapStateToProps, mapDispatchToProps)(BackPartTable)