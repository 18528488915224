import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {addWarehouseCategories, changeWarehouseState} from '../../../Redux/actions/warehouseAction';
import {addParts, changePartState, choosePartSelected} from '../../../Redux/actions/partAction'
import {changeVisibleState, setVisibleFlag} from '../../../Redux/actions';
import {Permissions} from "../../../data/permissions";
import {EditorID, Modal} from "../../../data/data";

import Button from '../../general/Button';
import TableFields from '../../general/TableFields'
import Paginate from '../../general/Paginate'
import WarehouseCategoryEditor from './WarehouseCategoryEditor';
import CategoryTable from './CategoryTable';
import Checkbox from '../../general/Checkbox';
import PartTable from './PartTable'

const WarehouseParts = props => {

    const canCreateCategories = props.permissions.includes(Permissions.CAN_CREATE_WAREHOUSE_CATEGORIES)
    const canSeeDeletedCategories = props.permissions.includes(Permissions.CAN_SEE_DELETED_WAREHOUSE_CATEGORIES)
    const canCreateNewParts = props.permissions.includes(Permissions.CAN_CREATE_NEW_PART)
    const canSeeDeletedParts = props.permissions.includes(Permissions.CAN_SEE_DELETED_PART)

    useEffect(() => {
        props.addWarehouseCategories()
    }, [props.warehouse.showDeleted])

    useEffect(() => {
        props.addParts()
    }, [props.part.showDeleted, props.part.page, props.warehouse.current_category, props.part.filter_name])

    const handleAddCategory = () => {
        props.changeWarehouseState({current_parent_category: props.warehouse.current_category})
        props.changeVisibleState({isCentralModalOpen: true, modalCentralType: Modal.Type.WAREHOUSE_CATEGORIES})
    }

    const handleAddPart = () => {
        props.changeWarehouseState({current_parent_category: props.warehouse.current_category})
        props.changePartState({warehouse_category: props.warehouse.warehouse_categories})
        props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.PART})
    }



    return (
        <div className='box box__row'>
            <div className='box__part-left'>
                <div className='two-buttons'>
                    <Button
                        id={EditorID.NEW_CATEGORY}
                        size='med'
                        type='create'
                        title='Категория'
                        onClick={handleAddCategory}
                        invisible={!canCreateCategories}
                    />
                    <Checkbox
                        type='slide-three'
                        label='Показать удаленные'
                        onChange={event => props.changeWarehouseState({showDeleted: event.target.checked})}
                        checked={props.warehouse.showDeleted}
                        invisible={!canSeeDeletedCategories}
                    />
                </div>
                {props.statusWarehouseCategoryEditor ? <WarehouseCategoryEditor/> : null}
                <CategoryTable/>
            </div>
            <div className='box__part-right'>
                <div className='page-buttons'>
                    <div className='two-buttons'>
                        <Button
                            id='newPart'
                            size='med'
                            type='create'
                            title='Товар'
                            onClick={handleAddPart}
                            invisible={!canCreateNewParts}
                        />
                        <Checkbox
                            type='slide-three'
                            label='Показать удаленные'
                            onChange={event => props.changePartState({showDeleted: event.target.checked})}
                            checked={props.part.showDeleted}
                            invisible={!canSeeDeletedParts}
                        />
                    </div>
                    <TableFields
                        list={props.part.choosed_headers}
                        func={table_headers => props.changePartState({table_headers})}
                    />
                </div>
                <PartTable/>
                <Paginate
                    allItems={props.part.count_parts}
                    onPage={50}
                    count={2}
                    count_start_end={0}
                    navigation={true}
                    func={page => props.changePartState({page})}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    statusWarehouseCategoryEditor: state.view.statusWarehouseCategoryEditor,
    warehouse: state.warehouse,
    part: state.part,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    setVisibleFlag,
    addWarehouseCategories,
    changeWarehouseState,
    addParts,
    choosePartSelected,
    changePartState,
    changeVisibleState
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseParts)