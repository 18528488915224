export const WarehousePermissions = Object.freeze({
    SHOW_WAREHOUSE_REMAINS: 'show_warehouse_remains',
    WAREHOUSE_REGISTRATION: 'warehouse_registration',
    MOVE_IN: 'move_in',
    RETURN_FROM_CLIENT: 'return_from_client',
    WRITE_OFF: 'write_off',
    MOVE_OUT: 'move_out',
    ADD_TO_ORDER: 'add_to_order',
    ADD_TO_SALE: 'add_to_sale',
    RETURN_TO_SUPPLIER: 'return_to_supplier'
})

export const DefaultWarehousePermission = {
    is_available: true,
    like_warehouse: true,
    permissions:
        [
            WarehousePermissions.SHOW_WAREHOUSE_REMAINS,
            WarehousePermissions.WAREHOUSE_REGISTRATION,
            WarehousePermissions.MOVE_IN,
            WarehousePermissions.RETURN_FROM_CLIENT,
            WarehousePermissions.WRITE_OFF,
            WarehousePermissions.MOVE_OUT,
            WarehousePermissions.ADD_TO_ORDER,
            WarehousePermissions.ADD_TO_SALE,
            WarehousePermissions.RETURN_TO_SUPPLIER,
        ]
}