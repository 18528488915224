import store from '../store'
import {serverRequest} from './actionUtils'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeBackState( data ) {
    return {
        type: Action.Back.CHANGE_STATE,
        data
    }
}

export function resetBack() {
    return {
        type: Action.Back.RESET
    }
}

function getFilter() {
    const state = store.getState()
    return {
        filter_created_at: state.back.filter_created_at,
        page: state.back.page
    }
}

export function getBack(back_id) {

    const body = {id: back_id}
    const url = URLS.Back.GET
    const error_message = 'Запрос Возврата не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Back.EDIT,
                back: data.back,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: { isCentralModalOpen: true, modalCentralType: Modal.Type.BACK}
            })
        }
    }
}

export function addBacks() {

    const body = getFilter()
    const url = URLS.Back.ADD
    const error_message = 'Запрос возвратов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Back.CHANGE_STATE,
                data: {warehouse_backs: data.backs, count: data.count},
            })
        }
    }
}

export function createBack() {

    const state = store.getState()

    const body = {
        created_at: parseInt(new Date() / 1000),
        parts: state.back.parts,
        description: state.back.description,
        price: state.back.price,
        warehouse_id: state.remain.filter_warehouse.id,
        client_id: state.back.client.id,
        registration_id: state.back.registration.id,
        filter: getFilter()
    }
    const url = URLS.Back.POST
    const error_message = 'Запрос на создание Возврата не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Back.CHANGE_STATE,
                data: {warehouse_backs: data.backs, count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''},
            })
            dispatch({
                type: Action.Back.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Возврат успешно создан')
        }
    }
}

export function saveBack() {

    const state = store.getState()

    const body = {
        id: state.back.edit,
        description: state.back.description,
        filter: getFilter()
    }
    const url = URLS.Back.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение Возврата не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Back.CHANGE_STATE,
                data: {warehouse_backs: data.backs, count: data.count}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: ''},
            })
            dispatch({
                type: Action.Back.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Возврат успешно изменен')
        }
    }
}
