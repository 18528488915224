import store from '../store'
import {serverRequest} from './actionUtils'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";

export function changeWarehouseState(data) {
    return {
        type: Action.Warehouse.CHANGE_STATE,
        data
    }
}

export function editWarehouse(warehouse) {
    return {
        type: Action.Warehouse.EDIT,
        warehouse
    }
}

export function editWarehouseCategory(warehouse_category) {
    return {
        type: Action.Warehouse.EDIT_CATEGORY,
        warehouse_category
    }
}

export function resetWarehouse() {
    return {
        type: Action.Warehouse.RESET,
    }
}

export function chooseWarehouseSelected(value, field) {
    return {
        type: Action.Warehouse.SELECTED,
        field,
        value
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.warehouse.showDeleted
    }
}

export function getWarehouse(warehouse_id){

    const body = {id: warehouse_id}
    const url = URLS.Warehouse.GET
    const error_message = 'Запрос склада не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.EDIT,
                warehouse: data.warehouse,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.WAREHOUSE}
            })
        }

    }

}

export function addWarehouse() {

    const body = getFilter()
    const url = URLS.Warehouse.ADD
    const error_message = 'Запрос складов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouses: data.warehouses}
            })
        }
    }
}


export function createWarehouse() {

    const state = store.getState()

    const body = {
        title: state.warehouse.title,
        description: state.warehouse.description,
        deleted: state.warehouse.deleted,
        isGlobal: state.warehouse.isGlobal,
        permissions: state.warehouse.permissions,
        branch_id: state.warehouse.branch?.id,
        employee_permissions: state.warehouse.employee_permissions,
        filter: getFilter()
    }
    const url = URLS.Warehouse.POST
    const error_message = 'Запрос на создание склада не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouses: data.warehouses}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Склад успешно создан')
        }
    }
}


export function saveWarehouse() {

    const state = store.getState()

    let body = {
        id: state.warehouse.edit,
        title: state.warehouse.title,
        description: state.warehouse.description,
        deleted: state.warehouse.deleted,
        isGlobal: state.warehouse.isGlobal,
        permissions: state.warehouse.permissions,
        branch_id: state.warehouse.branch?.id,
        employee_permissions: state.warehouse.employee_permissions,
        filter: getFilter()
    }
    const url = URLS.Warehouse.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение склада не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouses: data.warehouses}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Склад успешно изменен')
        }
    }
}


export function deleteWarehouse(flag) {

    const state = store.getState()

    const body = {
        id: state.warehouse.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Warehouse.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление склада не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouses: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE}
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Склад успешно удален/восстановлен')
        }
    }
}


function getCategoryFilter() {
    const state = store.getState()
    return {
        deleted: state.warehouse.showDeleted
    }
}

export function addWarehouseCategories() {

    const body = getCategoryFilter()
    const url = URLS.WarehouseCategory.ADD
    const error_message = 'Запрос категорий запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouse_categories: data.warehouse_categories, current_parent_category: data.warehouse_categories}
            })
            dispatch({
                type: Action.Remain.CHANGE_STATE,
                data: {filter_category: data.warehouse_categories}
            })
        }
    }
}

export function createWarehouseCategory() {

    const state = store.getState()

    const body = {
        title: state.warehouse.title_category,
        parent_category_id: state.warehouse.current_parent_category.id,
        deleted: state.warehouse.category_deleted,
        filter: getCategoryFilter()
    }
    const url = URLS.WarehouseCategory.POST
    const error_message = 'Запрос на создание категории запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouse_categories: data.warehouse_categories }
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория запчастей успешно создана')
        }
    }
}


export function saveWarehouseCategory() {

    const state = store.getState()

    const body = {
        id: state.warehouse.edit,
        title: state.warehouse.title_category,
        parent_category_id: state.warehouse.current_parent_category.id,
        filter: getCategoryFilter()
    }
    const url = URLS.WarehouseCategory.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение категории запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouse_categories: data.warehouse_categories }
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория запчастей успешно изменена')
        }
    }
}

export function deleteWarehouseCategory( flag ) {

    const state = store.getState()

    const body = {
        id: state.warehouse.edit,
        deleted: flag,
        filter: getCategoryFilter()
    }
    const url = URLS.WarehouseCategory.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление категории запчастей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouse_categories: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isCentralModalOpen: false, modalCentralType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Warehouse.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Категория запчастей успешно удалена/восстановлена')
        }
    }
}