import store from "./store"
import {bad_request, getRequestConfig, serverRequest} from './actions/actionUtils'
import {Action} from "../data/ActionTypes";
import {URLS} from "../data/urls";




export function setVisibleFlag(field, value) {
    return {
        type: 'SET_VISIBLE_FLAG',
        field,
        value
    }
}

export function changeVisibleState( data ) {
    return {
        type: Action.Visible.CHANGE_STATE,
        data
    }
}

export function setVisibleListFlag(field, idx, value) {
    return {
        type: 'SET_VISIBLE_LIST_FLAG',
        field,
        idx,
        value
    }
}

export function addVisibleFlag(field) {
    return {
        type: 'ADD_FLAG',
        field
    }
}

export function deleteVisibleFlag(field, idx) {
    return {
        type: 'DELETE_FLAG',
        field,
        idx
    }
}

export function editEquipment(equipment) {
    return {
        type: 'EDIT_EQUIPMENT',
        equipment
    }
}



export function setPayment(payment) {
    return {
        type: 'SET_PAYMENT',
        payment
    }
}

export function changeDictServiceForm(value, field) {
    return {
        type: 'CHANGE_DICT_SERVICE_FORM',
        field,
        value
    }
}

export function resetGruopDictService() {
    return {
        type: 'RESET_GROPE_DICT_SERVICE'
    }
}

export function editGroupDictService(group) {
    return {
        type: 'EDIT_GRUOP_DICT_SERVICE',
        group
    }
}

export function resetService() {
    return {
        type: 'RESET_SERVICE'
    }
}


export function selectedService(value, field, saveToApp=false) {
    return {
        type: 'SELECTED_SERVICE',
        field,
        value,
        saveToApp
    }
}


export function editDictService(service) {
    return {
        type: 'EDIT_SERVICE',
        service
    }
}

export function showAlert(dispatch, type, text) {
    const alert = {
        id: Math.round(Date.now() / 1000),
        type,
        text
    }
    dispatch({
        type: Action.Alert.SHOW,
        alert
    })
    setTimeout(() => {
        dispatch({
            type: Action.Alert.CLOSE,
            id: alert.id
        })
    }, 3000)
}

export function log_in(login, password) {

    const state = store.getState()

    return dispatch => {

        // fetch(state.data.url_server + "/post_test", {
        //     method: "POST",
        //     headers: {
        //         // 'Accept': 'application/json, text/javascript, */*; q=0.01',
        //         "Content-Type": "application/json",
        //         "X-CSRFToken": state.data.csrfToken
        //     },
        //     credentials: state.data.credentials,
        // })
        //     .then(res => res.json())
        //     .then(data => {console.log(data)})

        fetch(state.data.url_server + "/flogin", {
            method: "POST",
            headers: {
                // 'Accept': 'application/json, text/javascript, */*; q=0.01',
                "Content-Type": "application/json",
                "X-CSRFToken": state.data.csrfToken
            },
            credentials: state.data.credentials,
            body: JSON.stringify({email: login, password: password}),
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {login_status: true, jwt_token: data.access_token}
                    })
                } else {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {error_message: data.message, login_status: false},
                    })
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error,'Запрос авторизации не выполнен'))
    }
}



export const csrf = () => {

    const state = store.getState()

    if (true) {
        return dispatch => {

            fetch(state.data.url_server + "/getcsrf", {credentials: state.data.credentials})
                .then((res) => {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {csrfToken: res.headers.get(["X-CSRFToken"])}
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    } else {
        return dispatch => {
            dispatch({
                type: Action.Data.CHANGE_STATE,
                data: {csrfToken: document.getElementsByName("csrf-token")[0].content}
            })
        }
    }
}

//===========================================================================================================================


export function addAdCampaign() {

    const state = store.getState()

    return dispatch => {

        fetch(state.data.url_server + '/get_ad_campaign', getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {ad_campaign: data.data}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error,'Запрос рекламных компаний не выполнен'))
    }
}



export function addStatus() {

    const state = store.getState()

    return dispatch => {

        fetch(state.data.url_server + '/get_status', getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {status: data.data},
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос статусов не выполнен'))
    }
}

export function addStatusGroupAction() {

    const state = store.getState()

    return dispatch => {

        fetch(state.data.url_server + '/get_status_group', getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {status_group: data.data}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос групп статусов не выполнен'))
    }
}

export function addEquipment() {

    const state = store.getState()

    return dispatch => {

        fetch(state.data.url_server + '/get_equipment_type', getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {equipment: data.data}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос типов изделий не выполнен'))
    }
}



export function addMainData() {

    const body = {}
    const url = URLS.MainData.GET
    const error_message = 'Запрос основных данных не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Data.CHANGE_STATE,
                data: {
                    user: data.user,
                    order_type: data.order_type,
                    counters: data.counts,
                    ad_campaign: data.ad_campaign,
                    item_payments: data.item_payments,
                    status_group: data.status_group,
                    payRuleTypes: data.pay_rule_types
                }
            })
            dispatch({
                type: Action.Price.CHANGE_STATE,
                data: {
                    service_prices: data.service_prices,
                    discount_margin: data.discount_margin
                }
            })
            dispatch({
                type: Action.Branch.CHANGE_STATE,
                data: {branches: data.branch, current_branch: data.user.default_branch},
            })
            dispatch({
                type: Action.CashRegister.CHANGE_STATE,
                data: {cashboxes: data.cashboxes}
            })
            dispatch({
                type: Action.Employee.CHANGE_STATE,
                data: {employees: data.employees}
            })
            dispatch({
                type: Action.Warehouse.CHANGE_STATE,
                data: {warehouses: data.warehouses}
            })
            dispatch({
                type: Action.MainData.EDIT,
                data: data.generally_info
            })
            dispatch({
                type: Action.NotEvent.CHANGE_STATE,
                data: {
                    eventTypes: data.event_types,
                    notificationTypes: data.notification_types,
                    targetAudiences: data.target_audiences
                }
            })
        }
    }
}


export function addCounters() {

    const state = store.getState()

    return dispatch => {

        fetch(state.data.url_server + '/get_counts', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {counters: data.data}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос счетчиков не выполнен'))
    }
}




export function addDictMalfunction() {

    const state = store.getState()

    const request_config = getRequestConfig({
        page: state.book.page_malfunction
    })

    return dispatch => {

        fetch(state.data.url_server + '/get_malfunction', request_config)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dictMalfunction: data.data, count_malfunction: data.count}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос словарая неисправностей не выполнен'))
    }
}

export function createBookElement() {

    const state = store.getState()

    const url_list = ['malfunction', 'packagelist', 'item_payments']
    const field_list = ['dictMalfunction', 'dictPackagelist', 'item_payments']

    const request_config = getRequestConfig({
        title: state.book.title,
        direction: state.book.direction
    })

    return async dispatch => {

        await fetch(state.data.url_server + `/${url_list[state.book.type]}`, request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на создание записи в словарь не выполнен'))

        await fetch(state.data.url_server + `/get_${url_list[state.book.type]}`, getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {
                            [field_list[state.book.type]]: data.data,
                            [`count_${url_list[state.book.type]}`]: data.count
                        }
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос данных словаря не выполнен'))
    }
}

export function deleteBookElements() {

    const state = store.getState()

    const url_list = ['malfunction', 'packagelist', 'item_payments']
    const field_list = ['dictMalfunction', 'dictPackagelist', 'item_payments']

    let request_config = getRequestConfig({
        del_ids: state.book[`selected_${url_list[state.book.type]}`]
    })
    request_config.method = 'DELETE'

    return async dispatch => {

        await fetch(state.data.url_server + `/${url_list[state.book.type]}`, request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на удаление записи соваря не выполнен'))

        await fetch(state.data.url_server + `/get_${url_list[state.book.type]}`, getRequestConfig())
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {
                            [field_list[state.book.type]]: data.data,
                            [`count_${url_list[state.book.type]}`]: data.count
                        }
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос данных словаря не выполнен'))
    }
}

export function addDictPackagelist() {

    const state = store.getState()

    const request_config = getRequestConfig({
        page: state.book.page_packagelist
    })

    return dispatch => {

        fetch(state.data.url_server + '/get_packagelist', request_config)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dictPackagelist: data.data, count_packagelist: data.count}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос списка комплектаций не выполнен'))
    }
}

export function addItemPayments() {

    const state = store.getState()

    const request_config = getRequestConfig({
        page: state.book.page_item_payments
    })

    return dispatch => {

        fetch(state.data.url_server + '/get_item_payments', request_config)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {item_payments: data.data, count_item_payments: data.count}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос статей платежей не выполнен'))
    }
}



export function addGroupeService() {

    const state = store.getState()

    return async dispatch => {

        await fetch(state.data.url_server + '/get_group_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {group_dict_service: data.data},
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос категорий не выполнен'))
    }
}

export function createGroupDictService() {

    const state = store.getState()

    const request_config = getRequestConfig({
        title: state.dictService.group_title,
        deleted: false
    })

    return async dispatch => {

        await fetch(state.data.url_server + '/group_dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на создание категории не выполнен'))

        await fetch(state.data.url_server + '/get_group_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {group_dict_service: data.data}
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusGroupServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_GROPE_DICT_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос категорий не выполнен'))
    }
}

export function saveGroupDictService() {

    const state = store.getState()

    let request_config = getRequestConfig({
        id: state.dictService.group_edit,
        title: state.dictService.group_title
    })
    request_config.method = 'PUT'

    return async dispatch => {

        await fetch(state.data.url_server + '/group_dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на создание категории не выполнен'))

        await fetch(state.data.url_server + '/get_group_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {group_dict_service: data.data}
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusGroupServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_GROPE_DICT_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос категорий не выполнен'))
    }
}

export function deleteGroupDictService(flag) {

    const state = store.getState()

    let request_config = getRequestConfig({
        id: state.dictService.group_edit,
        deleted: flag
    })
    request_config.method = 'PUT'

    return async dispatch => {

        await fetch(state.data.url_server + '/group_dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на создание категории не выполнен'))

        await fetch(state.data.url_server + '/get_group_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {group_dict_service: data.data},
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusGroupServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_GROPE_DICT_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос категорий не выполнен'))
    }
}

export function addDictService() {

    const state = store.getState()

    const request_config = getRequestConfig({
        category_id: state.dictService.seted_categiry
    })

    return async dispatch => {

        await fetch(state.data.url_server + '/get_dict_service', request_config)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dict_service: data.data}
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос услуг не выполнен'))
    }
}

export function createDictService() {

    const state = store.getState()

    const request_config = getRequestConfig({
        title: state.dictService.title,
        price: state.dictService.price,
        cost: state.dictService.cost,
        warranty: state.dictService.warranty,
        code: state.dictService.code,
        earnings_percent: state.dictService.earnings_percent,
        earnings_summ: state.dictService.earnings_summ,
        deleted: false,
        category_id: state.dictService.category_id
    })

    return async dispatch => {

        await fetch(state.data.url_server + '/dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на создание услуги не выполнен'))

        await fetch(state.data.url_server + '/get_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dict_service: data.data}
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос услуг не выполнен'))
    }
}

export function saveDictService() {

    const state = store.getState()

    let request_config = getRequestConfig({
        id: state.dictService.edit,
        title: state.dictService.title,
        price: state.dictService.price,
        cost: state.dictService.cost,
        warranty: state.dictService.warranty,
        code: state.dictService.code,
        earnings_percent: state.dictService.earnings_percent,
        earnings_summ: state.dictService.earnings_summ,
        deleted: state.dictService.deleted,
        category_id: state.dictService.category_id
    })
    request_config.method = 'PUT'

    return async dispatch => {

        await fetch(state.data.url_server + '/dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на изменение услуги не выполнен'))

        await fetch(state.data.url_server + '/get_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dict_service: data.data}
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос услуг не выполнен'))
    }
}

export function deleteDictService(flag) {

    const state = store.getState()

    let request_config = getRequestConfig({
        id: state.dictService.edit,
        deleted: flag
    })
    request_config.method = 'PUT'

    return async dispatch => {

        await fetch(state.data.url_server + '/dict_service', request_config)
            .catch(error => bad_request(dispatch, error, 'Запрос на удалене/восстановление услуги не выполнен'))

        await fetch(state.data.url_server + '/get_dict_service', getRequestConfig({}))
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: Action.Data.CHANGE_STATE,
                        data: {dict_service: data.data}
                    })
                    dispatch({
                        type: 'SET_VISIBLE_FLAG',
                        field: 'statusServiceEditor',
                        value: false
                    })
                    dispatch({
                        type: 'RESET_SERVICE'
                    })
                } else {
                    console.warn(data.message)
                }
            })
            .catch(error => bad_request(dispatch, error, 'Запрос услуг не выполнен'))
    }
}



