export const listVarCompany = [
    '#КОМПАНИЯ-НАЗВАНИЕ',
    '#КОМПАНИЯ-АДРЕС',
    '#КОМПАНИЯ-EMAIL',
    '#КОМПАНИЯ-РЕКВИЗИТЫ'
]

export const listVarClient = [
    '#КЛИЕНТ-ИМЯ',
    '#КЛИЕНТ-ТЕЛЕФОН',
    '#КЛИЕНТ-АДРЕС',
    '#КЛИЕНТ-EMAIL',
    '#КЛИЕНТ-К/С',
    '#КЛИЕНТ-Р/С',
    '#КЛИЕНТ-ДИРЕКТОР',
    '#КЛИЕНТ-БИК',
    '#КЛИЕНТ-НАЗВАНИЕ-БАНКА',
    '#КЛИЕНТ-ЮРИДИЧЕСКИЙ-АДРЕС',
    '#КЛИЕНТ-КПП',
    '#КЛИЕНТ-ИНН',
    '#КЛИЕНТ-ОГРН',
    '#КЛИЕНТ-ОБРАЩЕНИЕ'
]

export const listVarOrder = [
    '#ЗАКАЗ-НОМЕР',
    '#ЦЕНА',
    '#ЗАМЕТКИ-МЕНЕДЖЕРА',
    '#ЗАМЕТКИ-ИСПОЛНИТЕЛЯ',
    '#СРОЧНО',
    '#ЗАКАЗ-НЕИСПРАВНОСТЬ',
    '#ДАТА-ЗАКАЗ-БУДЕТ-ГОТОВ',
    '#ДАТА-ЗАКАЗ-ЗАПЛАНИРОВАН-НА',
    // '#ДАТА-ЗАКАЗ-ДЛИТЕЛЬНОСТЬ',
    '#РЕКЛАМНАЯ-КАМПАНИЯ',
    '#ЗАКАЗ-ТИП-ИЗДЕЛИЯ',
    '#ЗАКАЗ-БРЕНД',
    '#ЗАКАЗ-МОДУЛЬ',
    '#ЗАКАЗ-МОДЕЛЬ',
    '#ЗАКАЗ-КОМПЛЕКТАЦИЯ',
    '#ЗАКАЗ-ВНЕШНИЙ-ВИД',
    '#ЗАКАЗ-СЕРИЙНЫЙ-НОМЕР',
    // '#ЗАКАЗ-АДРЕС-ЗАКАЗА'
]

export const listVarEmployee = [
    '#ЗАКАЗ-СОЗДАЛ',
    // '#СЧЕТ-СОЗДАЛ',
    '#ИСПОЛНИТЕЛЬ-ИМЯ',
    '#ИСПОЛНИТЕЛЬ-ТЕЛЕФОН',
    '#МЕНЕДЖЕР-ИМЯ',
    '#МЕНЕДЖЕР-ТЕЛЕФОН',
    '#ЗАКАЗ-ЗАКРЫЛ'
]

export const listVarFinance = [
    // '#ВСЕГО-СУММА',
    // '#СУММА-ПРОПИСЬЮ',
    // '#ВАЛЮТА',
    '#ОРИЕНТИР-ЦЕНА',
    // '#ОРИЕНТИР-ЦЕНА-ПРОПИСЬЮ',
    '#К-ОПЛАТЕ',
    // '#К-ОПЛАТЕ-ПРОПИСЬЮ',
    '#ОПЛАЧЕНО',
    // '#ОПЛАЧЕНО-ПРОПИСЬЮ',
    '#ЗАКАЗ-СУММА',
    // '#ЗАКАЗ-СУММА-ПРОПИСЬЮ'
]

export const listVarDate = [
    '#ДАТА-СЕГОДНЯ',
    '#ДАТА-ВРЕМЯ-СЕГОДНЯ',
    // '#ДАТА-ПРОДАЖИ',
    // '#ДАТА-ВЫСТАВЛЕНИЯ-СЧЕТА',
    '#ДАТА-ЗАКАЗ-СОЗДАН',
    '#ДАТА-ЗАКАЗ-ГОТОВ',
    '#ДАТА-ЗАКАЗ-БУДЕТ-ГОТОВ',
    '#ДАТА-ЗАКАЗ-ЗАКРЫТ',
    '#ДАТА-ЗАКАЗ-ЗАПЛАНИРОВАН-НА',
    '#ДАТА-ГАРАНТИЯ'
]

export const listVarAnother = [
    '#ЗАКАЗ-ТИП',
    // '#ЗАКАЗ-URL-ДЛЯ-ИСПОЛНИТЕЛЯ',
    // '#ЗАКАЗ-URL-ДЛЯ-КЛИЕНТА',
    // '#ЗАКАЗ-URL-ОТЗЫВ-КЛИЕНТА',
    // '#ЗАКАЗ-URL-ДЛЯ-ИСПОЛНИТЕЛЯ-SMS',
    // '#ЗАКАЗ-URL-ДЛЯ-КЛИЕНТА-SMS',
    // '#ПРОДАЖА-НОМЕР',
    // '#ФОРМА-ОПЛАТЫ',
    // '#СЧЕТ-НОМЕР',
    '#ВЕРДИКТ',
    // '#КОММЕНТАРИЙ',
    // '#ШТРИХ-КОД',
    // '#КОММЕНТАРИЙ-АВТОР',
    '#ЛОКАЦИЯ',
    '#СТАТУС'
]