import {Action} from "../../data/ActionTypes";

const initialState = {

   employeeId: 0,
   cashRegisterId: 0,
   isAvailable: false,
   likeCashRegister: false,
   personalPermission: [],
   cashRegisterPermission: [],

   forEditor: ''
}
 
export const cashRegisterPermissionReducer = (state = initialState, action) => {
   switch (action.type){

      case Action.CashRegisterPermission.CHANGE_STATE: {
         return {...Object.assign(state, action.data)}
      }


      case Action.CashRegisterPermission.EDIT: {
         return {
            ...state,
            employeeId: action.cashRegisterPermission.employeeId,
            cashRegisterId: action.cashRegisterPermission.cashRegisterId,
            isAvailable: action.cashRegisterPermission.isAvailable,
            likeCashRegister: action.cashRegisterPermission.likeCashRegister,
            personalPermission: action.cashRegisterPermission.personalPermission,

            forEditor: action.cashRegisterPermission.forEditor
         }
      }
 
      case Action.CashRegisterPermission.RESET: {
         return {
            ...state,
            employeeId: 0,
            cashRegisterId: 0,
            isAvailable: false,
            likeCashRegister: false,
            personalPermission: [],
            cashRegisterPermission: [],

            forEditor: ''
         }
      }
       
      default: return state
   }
    
}