import store from '../store'
import {serverRequest} from './actionUtils'
import {currentMonth} from '../../components/general/utils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {AlertType} from "../../data/AlertType";


export function changePayrollState(data) {
    return {
        type: Action.Payroll.CHANGE_STATE,
        data
    }
}

export function editPayroll(payroll) {
    return {
        type: Action.Payroll.EDIT,
        payroll
    }
}

export function resetPayroll() {
    return {
        type: Action.Payroll.RESET
    }
}

function getFilter() {
    const state = store.getState()
    return {
        custom_created_at: state.payroll.filter_created_at,
        employee_id: state.payroll.setted_employee.id,
        deleted: state.payroll.showDeleted
    }
}

export function addPayrolls() {

    const body = getFilter()
    const url = URLS.Payroll.ADD
    const error_message = 'Запрос начислений не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Payroll.CHANGE_STATE,
                data: {payrolls: data.data}
            })
        }
    }
}

export function addMonthBalance() {

    const state = store.getState()

    const body = {
        custom_created_at: currentMonth(),
        employee_id: state.payroll.setted_employee.id
    }
    const url = URLS.Payroll.GET_SUM
    const error_message = 'Запрос баланса не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Payroll.CHANGE_STATE,
                data: {month_balance: data.sum},
            })
        }
    }
}

export function createPayroll() {

    const state = store.getState()

    const now = Math.round(Date.now() / 1000)

    const body = {
        description: state.payroll.description,
        income: state.payroll.income,
        outcome: -state.payroll.outcome,
        direction: state.payroll.direction,
        deleted: state.payroll.deleted,
        reimburse: state.payroll.reimburse,
        created_at: now,
        custom_created_at: state.payroll.custom_created_at || now,
        relation_type: state.payroll.relation_type,
        relation_id: state.payroll.relation_id,
        employee_id: state.payroll.employee.id,
        order_id: state.payroll.order_id,
        payment: state.payroll.relation_type === 12 ? {
            cashbox_id: state.payroll.payment_cashbox.id,
            cashflow_category: state.payroll.payment_cashflow_category.id
        } : null,
        filter: getFilter()
    }
    const url = URLS.Payroll.POST
    const error_message = 'Запрос на создание начисления не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

            if (data.success) {
                dispatch({
                    type: Action.Payroll.CHANGE_STATE,
                    data: {payrolls: data.payrolls}
                })
                dispatch({
                    type: Action.Payroll.RESET
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {statusPayrollEditor: false}
                })
                showAlert(dispatch, AlertType.SUCCESSES, 'Начисление успешно создано')
            }
    }
}

export function deletePayroll(flag) {

    const state = store.getState()

    const body = {
        id: state.payroll.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Payroll.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление начисления не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Payroll.CHANGE_STATE,
                data: {payrolls: data.payrolls}
            })
            dispatch({
                type: Action.Payroll.RESET
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusPayrollEditor: false}
            })
            const text = flag ? 'Начисление успешно удалено' : 'Начисление успешно восстановлено'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}