import React from 'react'
import {connect} from 'react-redux'

import {changePartState, getPart} from '../../../Redux/actions/partAction'

import Doc from '../../general/cell/Doc'
import PartImag from '../../general/cell/Imag'
import TableHeader from '../../general/TableHeader'
import Label from "../../general/cell/Label";
import Data from "../../general/cell/Data";


function PartTable(props) {

    const chooseCell = (header, part) => {

        switch (header.id) {
            case 1:
                return (
                    <Label
                        key={header.id}
                        label={part.title}
                        func={() => props.getPart(part.id)}
                    />
                )
            case 7:
                return (
                    <PartImag
                        key={header.id}
                        title={part.title}
                        image_url={part.image_url}
                    />
                )
            case 8:
                return (
                    <Data
                        key={header.id}
                        data={part.warehouse_category.title}
                    />
                )
            case 9:
                return (
                    <Doc
                        key={header.id}
                        doc_url={part.doc_url}
                    />
                )
            default:
                return (
                    <Data
                        key={header.id}
                        data={part[header.field]}
                    />
                )
        }
    }

    return (
        <div className="box__part-table">
            <table>
                <thead>
                <tr>
                    {props.part.choosed_headers.map(header => (
                        <TableHeader
                            key={header.id}
                            header={header}
                            changeState={props.changePartState}
                            headers={props.part.choosed_headers}
                            // sort_field={props.part.sort_field}
                            // sort={props.part.sort}
                        />
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.part.parts.map(part => (
                        <tr
                            key={part.id}
                            className={part.deleted ? 'rowDeleted' : null}
                            onDoubleClick={() => props.getPart(part.id)}

                        >
                            {props.part.choosed_headers.map(header => chooseCell(header, part))}
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    part: state.part
})

const mapDispatchToProps = {
    getPart,
    changePartState
}


export default connect(mapStateToProps, mapDispatchToProps)(PartTable)