import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeBranchState, createBranch, deleteBranch, resetBranch, saveBranch} from '../../../../Redux/actions/branchAction'
import {changeVisibleState} from '../../../../Redux/actions'
import {hasIDinHierarchy} from "../../../general/utils"
import {EditorID, Modal} from "../../../../data/data"


import BottomButtons from '../../../general/BottomButtons'
import Tabs from "../../../general/Tabs"
import BranchGeneral from "./BranchGeneral"
import BranchPermission from "./BranchPermission";

const BranchEditor = (props) => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: Modal.Type.NONE,
            inputBranchNameChecked: true,
            inputBranchPrefixChecked: true,
            inputBranchPrefixDocChecked: true
        })
        props.resetBranch()
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.BRANCH]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkAllConditions = () => {
        if(props.branch.name && props.branch.orders_prefix && props.branch.documents_prefix){
            return true
        } else {
            if (!props.branch.name) {
                props.changeVisibleState({inputBranchNameChecked: false})
            }
            if (!props.branch.orders_prefix) {
                props.changeVisibleState({inputBranchPrefixChecked: false})
            }
            if (!props.branch.documents_prefix) {
                props.changeVisibleState({inputBranchPrefixDocChecked: false})
            }
            return false
        }
    }

    const handleCreateBranch = () => {
        if (checkAllConditions()) {
            props.createBranch()
        }
    }

    const handleSaveBranch = () => {
        if (checkAllConditions()) {
            props.saveBranch()
        }
    }

    const handleRecover = () => {
        if (props.permissions.includes('setting_recover_branch'))
            props.deleteBranch(false)
    }

    const tabContents = [
        (<BranchGeneral/>),
        (<BranchPermission/>)
    ]

    return (
            <div
                className="modal__box-right"
                id={EditorID.BRANCH}
            >
                <h4>{props.branch.edit ? props.branch.name : 'Новая локация'}</h4>

                <Tabs
                    list={['Общие', 'Доступ']}
                    func={idx => props.changeBranchState({tabs: idx})}
                    tab={props.branch.tabs}
                />

                {tabContents[props.branch.tabs]}

                <BottomButtons
                    edit={props.branch.edit}
                    create={handleCreateBranch}
                    save={handleSaveBranch}
                    recover={handleRecover}
                    delete={() => props.deleteBranch(true)}
                    close={handleClose}
                    deleted={props.branch.deleted}
                />
            </div>
    )
}

const mapStateToProps = (state) => ({
    branch: state.branch,
    permissions: state.data.user.role.permissions,
})

const mapDispatchToProps = {
    changeVisibleState,
    changeBranchState,
    createBranch,
    saveBranch,
    deleteBranch,
    resetBranch
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchEditor)
