import React from 'react'
import { connect } from 'react-redux'

import CashRegisterEmployeePermission from "./CashRegisterEmployeePermission";
import BranchEmployeePermissions from "./BranchEmployeePermissions";
import WarehouseEmployeePermissions from "./WarehouseEmployeePermissions";

const EmployeePermissions = (props) => {

    return (
        <div className='modal__body-right'>
            <CashRegisterEmployeePermission/>
            <BranchEmployeePermissions/>
            <WarehouseEmployeePermissions/>
        </div>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePermissions)