import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../Redux/actions'
import {changeCashRegisterState} from '../../../Redux/actions/cashRegisterAction'
import {changeCashRegisterPermissionState} from "../../../Redux/actions/cashRegisterPermissionAction"
import {Modal} from "../../../data/data"

import Checkbox from '../../general/Checkbox'
import {DefaultEmployeeCashRegisterPermission} from "../../../data/cashRegisterPermissions"
import Data from "../../general/cell/Data"
import {EditFor} from "../../../data/cashRegisterEmployeeFor"
import {CheckboxDate} from "../../../data/checkboxData";


const CashRegisterAccess = (props) => {

    useEffect(() => {
        const new_permissions = []
        props.employees.map(employee => {
            const permission = props.cashRegister.employee_permissions.find(permission => permission?.employee_id === employee.id);
            if(!permission){
                new_permissions.push({
                    ...DefaultEmployeeCashRegisterPermission,
                    cash_register_id: props.cashRegister.edit,
                    employee_id: employee.id
                })
            }
        })
        if (new_permissions){
            props.changeCashRegisterState({
                employee_permissions: props.cashRegister.employee_permissions.concat(new_permissions),
                is_access_prepared: true
            })
        }
    }, [])

    const handleEdit = (permission) => {
        props.changeCashRegisterPermissionState({
            forEditor: EditFor.FOR_CASH_REGISTER,
            employeeId: permission.employee_id,
            cashRegisterId: permission.cash_register_id,
            isAvailable: permission.is_available,
            likeCashRegister: permission.like_cash_register,
            personalPermission: permission.permissions,
            cashRegisterPermission: props.cashRegister.permissions
        })

        props.changeVisibleState({
            modalCentralType: Modal.Type.CASH_REGISTER_EMPLOYEE,
            isCentralModalOpen: true
        })
    }

    const handleCheck = (employee_id, value) => {
        const employee_permissions = props.cashRegister.employee_permissions
        employee_permissions.map(permission => {
            if(permission.employee_id === employee_id){
                permission.is_available = value
            }
            return permission
        })
        props.changeCashRegisterState({employee_permissions})
    }

    const getPermission = (employeeId) => {
        const permission = props.cashRegister.employee_permissions.find(permission => permission?.employee_id === employeeId);
        return permission || DefaultEmployeeCashRegisterPermission
    }

    if(!props.cashRegister.is_access_prepared) return null

    return (
        <div className='table-container'>
            <table>
                <thead>
                <tr>
                    <th className='th th_w60'/>
                    <th className='th th_w180'>Сотрудник</th>
                    <th className='th th_w180'>Права</th>
                </tr>
                </thead>
                <tbody>
                {props.employees.map(employee => {

                    const permission = getPermission(employee.id)
                    const permission_type = permission.is_available ?
                        (permission.like_cash_register ? 'Доступные для кассы' : 'Персональные')
                        : 'Нет доступа'

                    return (
                        <tr
                            key={employee.id}
                            className='tr'
                            onDoubleClick={() => handleEdit(permission)}
                        >
                            <td className='td'>
                                <Checkbox
                                    type={CheckboxDate.Type.SLIDE_THREE}
                                    onChange={event => handleCheck(employee.id, event.target.checked)}
                                    checked={permission.is_available}
                                    disabled={props.cashRegister.deleted}
                                />
                            </td>
                            <Data data={employee.name}/>
                            <Data data={permission_type}/>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    employees: state.employee.employees.filter(employee => !employee.deleted && employee.id !== 0),
    cashRegister: state.cashbox
})

const mapDispatchToProps = {
    changeVisibleState,
    changeCashRegisterState,
    changeCashRegisterPermissionState
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterAccess)