import {Action} from "../../data/ActionTypes";

export function changeCashRegisterPermissionState( data ) {
    return {
        type: Action.CashRegisterPermission.CHANGE_STATE,
        data
    }
}

export function editCashRegisterPermission(cashRegisterPermission) {
    return {
        type: Action.CashRegisterPermission.EDIT,
        cashRegisterPermission
    }
}

export function resetCashRegisterPermission() {
    return {
        type: Action.CashRegisterPermission.RESET
    }
}

