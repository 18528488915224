import React from 'react'
import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';


import './index.scss'
import App from './App'
import store from './Redux/store';


const app = (
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))


