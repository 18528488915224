import React, {useEffect} from 'react'
import { connect } from 'react-redux'


import {changeEmployeeState} from "../../../../Redux/actions/employeeAction"
import {CheckboxDate} from "../../../../data/checkboxData"

import Checkbox from "../../../general/Checkbox"
import SelectFromList from "../../../general/SelectFromList";


const BranchEmployeePermissions = (props) => {

    useEffect(() => {

        const new_permissions = []

        props.branches.map(branch => {

            const permission = props.employee.branchPermissions.find(permission => permission?.branch_id === branch.id)

            if(!permission) {
                new_permissions.push({
                    is_available: true,
                    employee_id: props.employee.edit,
                    branch_id: branch.id
                })
            }
        })

        if(new_permissions){
            props.changeEmployeeState({branchPermissions: props.employee.branchPermissions.concat(new_permissions)})
        }

    },[])

    const changePermission = (branch_id, value) => {

        if(props.employee.default_branch?.id === branch_id) return

        const branchPermissions = props.employee.branchPermissions.map(permission => {
            if(permission.branch_id === branch_id){
                permission.is_available = value
            }
            return permission
        })
        props.changeEmployeeState({branchPermissions})
    }

    const selectDefaultBranch = (default_branch) => {
        changePermission(default_branch.id, true)
        props.changeEmployeeState({default_branch})
    }

    return (
        <>
            <SelectFromList
                className='w250'
                title='Филиал по умолчанию'
                list={props.branches}
                setElement={default_branch => selectDefaultBranch(default_branch)}
                current_object={props.employee.default_branch}
                checkedFlag='inputEmployeeDefaultBranch'
                noChoosed='Выберете филиал'
                disabled={props.employee.deleted}
                invisible={false}
            />
            <table>
                <thead>
                <tr>
                    <th className='th'>Филиал</th>
                </tr>
                </thead>
                <tbody>
                {props.branches.map(branch => {

                    const permission = props.employee.branchPermissions.find(permission => permission.branch_id === branch.id)

                    return (
                        <tr key={branch.id}>
                            <td className='td'>
                                <Checkbox
                                    label={branch.name}
                                    type={CheckboxDate.Type.SLIDE_THREE}
                                    onChange={event => changePermission(branch.id, event.target.checked)}
                                    checked={permission?.is_available}
                                    disabled={props.employee.deleted}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    )
}

const mapStateToProps = state => ({
    branches: state.branch.branches,
    employee: state.employee
})

const mapDispatchToProps = {
    changeEmployeeState
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchEmployeePermissions)