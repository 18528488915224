import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../Redux/actions'
import {resetCashRegister, saveEditCashRegister} from '../../../Redux/actions/cashRegisterAction'
import {changeCashRegisterState, createCashRegister, deleteCashRegister} from '../../../Redux/actions/cashRegisterAction'
import {hasIDinHierarchy} from "../../general/utils";
import {EditorID} from "../../../data/data";

import BottomButtons from '../../general/BottomButtons'
import Tabs from '../../general/Tabs'
import CashRegisterAccess from './CashRegisterAccess'
import DataCashRegister from './DataCashbox'

const CashRegisterEditor = (props) => {

    const handleCLose = () => {
        props.changeVisibleState({
            isRightModalOpen: false,
            modalType: '',
            inputCashRegisterTitleChecked: true
        })
        props.resetCashRegister()
    }

    const clickHandel = (event) => {
        const listIDs = [EditorID.CASH_REGISTER, EditorID.CASH_REGISTER_EMPLOYEE]
        if (!hasIDinHierarchy(event, listIDs)) {
            handleCLose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkAllConditions = () => {
        if (props.cashRegister.title) {
            return true
        } else {
            props.changeVisibleState({inputCashRegisterTitleChecked: false})
            return false
        }
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createCashRegister()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveEditCashRegister()
        }
    }

    return (
        <div
            className="modal__box-right"
            id={EditorID.CASH_REGISTER}
        >

            <h4>{props.cashRegister.edit ? props.cashRegister.title : 'Новая касса'}</h4>

            <div className='modal__body-right'>
                <Tabs
                    list={['Общие', 'Доcтуп']}
                    func={idx => props.changeCashRegisterState({tabs_editor: idx})}
                    tab={props.cashRegister.tabs_editor}
                    tab_field="tabs_editor"
                />

                {props.cashRegister.tabs_editor === 0 ? <DataCashRegister/> : null}
                {props.cashRegister.tabs_editor === 1 ? <CashRegisterAccess/> : null}
            </div>
            <BottomButtons
                edit={props.cashRegister.edit}
                create={handleCreate}
                save={handleSave}
                delete={props.permissions.includes('edit_cash') ? () => props.deleteCashRegister(true) : null}
                recover={props.permissions.includes('edit_cash') ? () => props.deleteCashRegister(false) : null}
                close={handleCLose}
                deleted={props.cashRegister.deleted}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    cashRegister: state.cashbox,
    employees: state.employee.employees,
    view: state.view,
    permissions: state.data.user.role.permissions,
})

const mapDispatchToProps = {
    changeCashRegisterState,
    changeVisibleState,
    createCashRegister,
    saveEditCashRegister,
    deleteCashRegister,
    resetCashRegister
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterEditor)
