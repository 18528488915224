import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'


import {changeVisibleState} from '../../../Redux/actions'
import {addPayments} from '../../../Redux/actions/paymentAction'
import {addCashRegisters, changeCashRegisterState} from '../../../Redux/actions/cashRegisterAction'
import {Modal} from "../../../data/data";

import Button from '../../general/Button'
import Checkbox from '../../general/Checkbox'
import Cashbox from './CashRegister'
import ManeyMovement from '../ManeyMovement'


const Cashboxes = (props) => {

    useEffect(() => {
        if (Object.values(props.current_branch).length) props.addCashboxes()
    }, [props.cashbox.showDeleted, props.current_branch])

    const handelNewCashRegister = () => {
        props.changeVisibleState({
            modalType: Modal.Type.CASH_REGISTER,
            isRightModalOpen: true
        })
    }


    return (
        <div className='row'>
            <div className='modal__block-forms'>
                <Checkbox
                    type='slide-three'
                    label='Показать удаленные'
                    onChange={event => props.changeCashboxState({showDeleted: event.target.checked})}
                    checked={props.cashbox.showDeleted}
                />
                {props.cashbox.cashboxes.map(cashbox => {
                    return (
                        <Cashbox
                            key={cashbox.id}
                            cashbox={cashbox}
                            active={cashbox.active}
                        />
                    )
                })}


                <Button
                    size='med'
                    type='tertiary'
                    title='Добавить кассу'
                    onClick={handelNewCashRegister}
                    invisible={!props.user.role.permissions.includes('edit_cash')}
                />
            </div>
            <ManeyMovement/>

        </div>
    )
}

const mapStateToProps = state => ({
    view: state.view,
    cashbox: state.cashbox,
    user: state.data.user,
    current_branch: state.branch.current_branch,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    addCashboxes: addCashRegisters,
    changeCashboxState: changeCashRegisterState,
    addPayments
}

export default connect(mapStateToProps, mapDispatchToProps)(Cashboxes)