import React from 'react'

/**
 *
 * @example
 * <Data
 *   onChange={value => console.log('value')}
 *   value={'value'}
 *   after={'after'}
 *   widthInput='50px'
 *   invisible={false}
 *   disabled={false}
 * />
 *
 * func - функция onChange
 * value - значение
 * after - после ввода
 * widthInput - ширина блока ввода
 * invisible - не отображать
 * disabled - заблокировать
 */
const InputSell = props => {

    if (props.invisible) return null

    return (
        <td>
            <div className='cell'>
                <div
                    className='input td_input'
                    style={props.widthInput ? {width: props.widthInput} : null}
                >
                    <input
                        className='w30 bcsb'
                        onChange={event => props.onChange(event.target.value)}
                        value={props.value}
                        disabled={props.disabled}
                    />
                    {props.after ? <div>{props.after}</div> : null}
                </div>
            </div>
        </td>
    )
}

export default InputSell