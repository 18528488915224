import store from '../store'
import {getRequestConfig, bad_request, serverRequest} from './actionUtils'
import {getOrderFilter} from './orderActions'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";


export function changeOperationState(data) {
    return {
        type: Action.Operation.CHANGE_STATE,
        data
    }
}

export function editOperation(operation) {
    return {
        type: Action.Operation.EDIT,
        operation
    }
}

export function resetOperation() {
    return {
        type: Action.Operation.RESET
    }
}

export function createOperation(service) {

    const state = store.getState()

    const getPrice = (service, state) => {
        if (state.order.client.discount_service_type) {
            const price = state.price.service_prices.find(price =>
                price.service_id === service.id &&
                price.discount_margin_id === state.order.client.discount_service_margin.id
            )
            if (price) return price.cost
            return 0
        } else {
            return service.price
        }
    }

    const price = getPrice(service, state)

    const discount_value = state.order.client.discount_service_type ?
        service.price - price :
        (service.price * state.order.client.discount_services / 100) || 0

    const body = {
        amount: 1,
        cost: service.cost || 0,
        discount_value: discount_value,
        engineer_id: state.operation.engineer.id || null,
        price: service.price || 0,
        total: state.order.client.discount_service_type ? price : price - discount_value,
        title: service.title,
        comment: '',
        percent: !state.order.client.discount_service_type,
        discount: state.order.client.discount_service_type ? (discount_value || 0) : state.order.client.discount_services,
        warranty_period: service.warranty_period,
        order_id: state.order.edit,
        dict_id: service.id,

        earnings_percent: service.earnings_percent,
        earnings_summ: service.earnings_summ,
        order_type_id: state.order.order_type_id,
        filter_order: getOrderFilter()
    }

    const url = URLS.Operation.POST
    const error_message = 'Запрос добавления операции не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Операция успешно добавлена')
        }
    }
}

export function createCustomOperation() {

    const state = store.getState()

    const body = {
        title: state.operation.title,
        price: state.operation.price || 0,
        amount: state.operation.amount,
        cost: state.operation.cost || 0,
        discount_value: state.operation.discount_value || 0,
        engineer_id: state.operation.engineer.id || null,
        total: state.operation.total || 0,
        comment: state.operation.comment,
        percent: state.operation.percent,
        discount: state.operation.discount,
        warranty_period: state.operation.warranty_period,
        order_id: state.order.edit,
        dict_id: null,

        order_type_id: state.order.order_type_id,
        filter_order: getOrderFilter()
    }
    const url = URLS.Operation.POST
    const error_message = 'Запрос добавления операции не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Операция успешно добавлена')
        }
    }
}


export function saveOperation() {

    const state = store.getState()

    const body = {
        id: state.operation.edit,
        title: state.operation.title,
        price: state.operation.price || 0,
        amount: state.operation.amount,
        cost: state.operation.cost || 0,
        discount_value: state.operation.discount_value || 0,
        engineer_id: state.operation.engineer.id || null,
        total: state.operation.total || 0,
        comment: state.operation.comment,
        percent: state.operation.percent,
        discount: state.operation.discount,
        warranty_period: state.operation.warranty_period,
        order_id: state.order.edit,
        filter_order: getOrderFilter()
    }
    const url = URLS.Operation.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение операции не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Операция успешно изменена')
        }
    }
}

export function deleteOperation(flag) {

    const state = store.getState()

    const body = {
        id: state.operation.edit,
        order_id: state.order.edit,
        deleted: flag
    }
    const url = URLS.Operation.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление операции не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {events: data.events || []}
            })
            const text = flag ? 'Операция успешно удалена' : 'Операция успешно восстановлена'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }

    }
}