import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeEmployeeState} from "../../../../Redux/actions/employeeAction"
import {changeVisibleState} from "../../../../Redux/actions";
import {changeCashRegisterPermissionState} from "../../../../Redux/actions/cashRegisterPermissionAction"
import {DefaultEmployeeCashRegisterPermission} from "../../../../data/cashRegisterPermissions"
import {Modal} from "../../../../data/data"
import {EditFor} from "../../../../data/cashRegisterEmployeeFor";

import Data from "../../../general/cell/Data"
import Checkbox from "../../../general/Checkbox";

function CashRegisterEmployeePermission(props) {

    useEffect(() => {

        const new_permissions = []

        props.cashRegisters.map(cashRegister => {

            const permission = props.employee.cashRegisterPermissions.find(permission => permission?.cash_register_id === cashRegister.id);

            if (!permission) {
                new_permissions.push({
                    ...DefaultEmployeeCashRegisterPermission,
                    cash_register_id: cashRegister.id,
                    employee_id: props.employee.edit
                })
            }
        })

        if (new_permissions) {
            props.changeEmployeeState({cashRegisterPermissions: props.employee.cashRegisterPermissions.concat(new_permissions)})
        }
    }, [])

    useEffect(() => {
    }, [props.employee.cashRegisterPermissions])

    const handleEdit = (permission, cashRegister) => {
        props.changeCashRegisterPermissionState({
            forEditor: EditFor.FOR_EMPLOYEE,
            employeeId: permission.employee_id,
            cashRegisterId: permission.cash_register_id,
            isAvailable: permission.is_available,
            likeCashRegister: permission.like_cash_register,
            personalPermission: permission.permissions,
            cashRegisterPermission: cashRegister.permissions
        })

        props.changeVisibleState({
            modalCentralType: Modal.Type.CASH_REGISTER_EMPLOYEE,
            isCentralModalOpen: true
        })
    }

    const handleCheck = (cashRegisterId, value) => {
        const cashRegisterPermissions = props.employee.cashRegisterPermissions
        cashRegisterPermissions.map(permission => {
            if (permission.cash_register_id === cashRegisterId) {
                permission.is_available = value
            }
            return permission
        })
        props.changeEmployeeState({cashRegisterPermissions})
    }

    const getPermission = (cashRegisterId) => {
        const permission = props.employee.cashRegisterPermissions.find(permission => permission?.cash_register_id === cashRegisterId);
        return permission || DefaultEmployeeCashRegisterPermission
    }

    return (
        <table>
            <thead>
            <tr>
                <th className='th th_w60'/>
                <th className='th th_w180'>Касса</th>
                <th className='th th_w180'>Права</th>
            </tr>
            </thead>
            <tbody>
            {props.cashRegisters.map(cashRegister => {

                const permission = getPermission(cashRegister.id)
                const permission_type = permission.is_available ?
                    (permission.like_cash_register ? 'Доступные для кассы' : 'Персональные')
                    : 'Нет доступа'

                return (
                    <tr
                        key={cashRegister.id}
                        className='tr'
                        onDoubleClick={() => handleEdit(permission, cashRegister)}
                    >
                        <td className='td'>
                            <Checkbox
                                type='slide-three'
                                onChange={event => handleCheck(cashRegister.id, event.target.checked)}
                                checked={permission.is_available}
                                disabled={props.employee.deleted}
                            />
                        </td>
                        <Data data={cashRegister.title}/>
                        <Data data={permission_type}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    cashRegisters: state.cashbox.cashboxes,
    employee: state.employee
})

const mapDispatchToProps = {
    changeVisibleState,
    changeEmployeeState,
    changeCashRegisterPermissionState
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterEmployeePermission)