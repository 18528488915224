import React from 'react'
import {connect} from 'react-redux'

import {showPhone} from '../../../general/utils'
import {getBranch} from '../../../../Redux/actions/branchAction'
import {changeVisibleState} from '../../../../Redux/actions'

import IconTitle from "../../../general/cell/IconTitle";
import Data from "../../../general/cell/Data";


const TableBranches = (props) => {

    return (
        <table>
            <thead>
            <tr>
                <th className='th'>Название локации</th>
                <th className='th'>Адрес</th>
                <th className='th'>Телефон</th>
            </tr>
            </thead>
            <tbody>
            {props.branches.map(branch => {
                return (
                    <tr
                        key={branch.id}
                        className={branch.deleted ? 'tr_deleted' : 'tr'}
                        onDoubleClick={() => props.getBranch(branch.id)}
                    >
                        <IconTitle
                            title={branch.name}
                            icon={branch.icon}
                            color={branch.color}
                        />
                        <Data data={branch.address}/>
                        <Data data={showPhone(branch.phone)}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    branches: state.branch.branches,
    showDeleted: state.branch.showDeleted
})

const mapDispatchToProps = {
    getBranch,
    changeVisibleState
}

export default connect(mapStateToProps, mapDispatchToProps)(TableBranches)