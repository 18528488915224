import React from 'react'
import MainData from './MainData'
import SettingHeader from "../SettingHeader";

const Generally = () => {

    return (
        <div className='setting-content'>
            <SettingHeader header='Общие'/>
            <MainData/>
        </div>
    )
}


export default Generally