import store from '../store'
import {serverRequest} from './actionUtils'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {RequestMethod} from "../../data/requestMethod";
import {URLS} from "../../data/urls";


export function changeInventoryState( data ) {
    return {
        type: Action.Inventory.CHANGE_STATE,
        data
    }
}


export function editInventory(inventory) {
    return {
        type: Action.Inventory.EDIT,
        inventory
    }
}


export function resetInventory() {
    return {
        type: Action.Inventory.RESET
    }
}


function getFilter() {
    const state = store.getState()
    return {
        filter_created_at: state.inventory.filter_created_at,
        page: state.inventory.page
    }
}


export function getInventory(inventory_id) {

    const body = {id: inventory_id}
    const url = URLS.Inventory.GET
    const error_message = 'Запрос инвентаризации не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Inventory.EDIT,
                inventory: data.inventory,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.INVENTORY}
            })
        }
    }
}


export function addInventory() {

    const body = getFilter()
    const url = URLS.Inventory.ADD
    const error_message = 'Запрос инвентаризаций не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Inventory.CHANGE_STATE,
                data: {warehouse_inventories: data.inventories, count: data.count},
            })
        }

    }
}


export function createInventory() {

    const state = store.getState()

    const parts = state.remain.warehouse_remains
        .filter(remain => !state.inventory.parts.map(part => part.title).includes(remain.title))
        .map(remain => {
            if (state.inventory.isZero) {
                remain.actual_count = 0
                return remain
            } else {
                remain.actual_count = remain.count
                return remain
            }
        })

    const body = {
        created_at: parseInt(new Date() / 1000),
        parts: state.inventory.parts.concat(parts),
        description: state.inventory.description,
        warehouse_id: state.remain.filter_warehouse.id,
        warehouse_category_id: state.remain.filter_category.id,
        filter: getFilter()
    }
    const url = URLS.Inventory.POST
    const error_message = 'Запрос на создание Инвентаризации не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Inventory.CHANGE_STATE,
                data: {warehouse_inventories: data.inventories, count: data.count},
            })
            dispatch({
                type: Action.Inventory.EDIT,
                inventory: data.inventory,
            })
        }
    }
}


export function saveInventory() {

    const state = store.getState()

    const body = {
        parts: state.inventory.parts,
        description: state.inventory.description,
        filter: getFilter()
    }
    const method = RequestMethod.PUT
    const url = URLS.Inventory.POST
    const error_message = 'Запрос на изменение Инвентаризации не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Inventory.CHANGE_STATE,
                data: {warehouse_inventories: data.inventories, count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: ''},
            })
            dispatch({
                type: Action.Inventory.RESET
            })
        }
    }
}

