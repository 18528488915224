import React from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";

import {changeWarehouseState, chooseWarehouseSelected} from "../../../../Redux/actions/warehouseAction";

import ChooseButton from "../../../general/ChooseButton"
import LabelInput from "../../../general/LabelInput"
import LabelArea from "../../../general/LableArea"
import Checkbox from "../../../general/Checkbox"
import SelectFromList from "../../../general/SelectFromList";
import {CheckboxDate} from "../../../../data/checkboxData";
import {WarehousePermissions} from "../../../../data/warehousePermissions";


const WarehouseInfo = props => {
    return (
        <div className='modal__body-right'>
            <div className='modal__block-forms w260'>
                <LabelInput
                    title="Наименование"
                    onChange={event => props.changeWarehouseState({title: event.target.value})}
                    value={props.warehouse.title}
                    checkedFlag="inputWarehouseTitleChecked"
                    redStar={ true }
                    disabled={props.warehouse.deleted}
                />
                <LabelArea
                    title="Описание"
                    onChange={event => props.changeWarehouseState({description: event.target.value})}
                    value={props.warehouse.description}
                    disabled={props.warehouse.deleted}
                />
                <ChooseButton
                    title='Выберите тип'
                    name={['Склад компании', 'Склад локации']}
                    func1 = {() => props.changeWarehouseState({isGlobal: true})}
                    func2 = {() => props.changeWarehouseState({isGlobal: false})}
                    // checked = { true }
                    disabled={props.warehouse.deleted}
                    invisible={false}
                />
                <SelectFromList
                    title='Филиал'
                    list={props.branches}
                    setElement={branch => props.changeWarehouseState({branch})}
                    current_object={props.warehouse.branch}
                    noChoosed='Выберете филиал'
                    disabled={props.warehouse.deleted}
                    invisible={props.warehouse.isGlobal}
                />

            <h5>Допустимые действия со складом</h5>

            <Checkbox
                type={CheckboxDate.Type.SLIDE_THREE}
                label='Видеть остатки'
                onChange={() => props.chooseWarehouseSelected([WarehousePermissions.SHOW_WAREHOUSE_REMAINS], 'permissions')}
                checked={props.warehouse.permissions.includes(WarehousePermissions.SHOW_WAREHOUSE_REMAINS)}
                disabled={props.warehouse.deleted}
            />
            <div className='modal__block-forms_row'>
                <div className='modal__block-forms'>
                    <h5>Входящие операции:</h5>
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Оприходование'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.WAREHOUSE_REGISTRATION], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.WAREHOUSE_REGISTRATION)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Перемещение'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.MOVE_IN], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.MOVE_IN)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Возврат от клиента'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.RETURN_FROM_CLIENT], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.RETURN_FROM_CLIENT)}
                        disabled={props.warehouse.deleted}
                    />
                </div>
                <div className='modal__block-forms'>
                    <h5>Исходящие операции:</h5>
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Списание'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.WRITE_OFF], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.WRITE_OFF)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Перемещение'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.MOVE_OUT], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.MOVE_OUT)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Добавление в заказ'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.ADD_TO_ORDER], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.ADD_TO_ORDER)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Добавление в продажу'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.ADD_TO_SALE], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.ADD_TO_SALE)}
                        disabled={props.warehouse.deleted}
                    />
                    <Checkbox
                        type={CheckboxDate.Type.SLIDE_THREE}
                        label='Возврат поставщику'
                        onChange={() => props.chooseWarehouseSelected([WarehousePermissions.RETURN_TO_SUPPLIER], 'permissions')}
                        checked={props.warehouse.permissions.includes(WarehousePermissions.RETURN_TO_SUPPLIER)}
                        disabled={props.warehouse.deleted}
                    />
                </div>
            </div>
            </div>
        </div>
    )
}

WarehouseInfo.propTypes = {

    warehouse: PropTypes.shape({
        warehouses: PropTypes.arrayOf(PropTypes.object),

        edit: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        deleted: PropTypes.bool,
        isGlobal: PropTypes.bool,
        permissions: PropTypes.arrayOf(PropTypes.string),
        branch_id: PropTypes.number,
        branch: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            icon: PropTypes.string,
            color: PropTypes.string
        }),
        categories: PropTypes.arrayOf(PropTypes.object)
    }),
    branches: PropTypes.arrayOf(PropTypes.object),
    inputBookTitleChecked: PropTypes.bool
}

const mapStateToProps = state => ({
    warehouse: state.warehouse,
    branches: state.branch.branches.filter(branch => !branch.deleted),
    inputBookTitleChecked: state.view.inputBookTitleChecked
})

const mapDispatchToProps = {
    changeWarehouseState,
    chooseWarehouseSelected
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseInfo)