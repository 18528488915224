import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'

import {changeRoleState} from "../../../../../Redux/actions/roleActions"
import {selectValues} from "../../../../general/utils"
import {CheckboxDate} from "../../../../../data/checkboxData"
import {COLORS} from "../../../../../data/colors"

import Checkbox from "../../../../general/Checkbox"


function ChooseAvailableStatuses(props) {

    const statusIDs = props.status.map(status => status.id)
    const disabled = props.role.deleted

    const handleSelectVisible = (values) => {
        const visibleStatuses = selectValues(values, props.role.visibleStatuses)
        props.changeRoleState({visibleStatuses})
    }

    const handleSelectSettable = (values) => {
        const settableStatuses = selectValues(values, props.role.settableStatuses)
        props.changeRoleState({settableStatuses})
    }

    const visibleCheckbox = useRef()

    useEffect(() => {
        const values = props.status.filter(status => props.role.visibleStatuses.includes(status.id))
        if (values.length === props.status.length) {
            visibleCheckbox.current.indeterminate = false
            visibleCheckbox.current.checked = true
        } else if (!values.length) {
            visibleCheckbox.current.indeterminate = false
            visibleCheckbox.current.checked = false
        } else {
            visibleCheckbox.current.indeterminate = true
        }
    }, [props.role.visibleStatuses])

    const settableCheckbox = useRef()

    useEffect(() => {
        const values = props.status.filter(status => props.role.settableStatuses.includes(status.id))
        if (values.length === props.status.length) {
            settableCheckbox.current.indeterminate = false
            settableCheckbox.current.checked = true
        } else if (!values.length) {
            settableCheckbox.current.indeterminate = false
            settableCheckbox.current.checked = false
        } else {
            settableCheckbox.current.indeterminate = true
        }
    }, [props.role.settableStatuses])


    return (
        <table>
            <caption className='ta-l'>{props.header}</caption>
            <thead>
            <tr>
                <th className='th th_w180'>Статус заказа</th>
                <th className='th'>
                    <Checkbox
                        type={CheckboxDate.Type.SQUARED_FIVE}
                        label='Видеть заказы в статусе'
                        onChange={() => handleSelectVisible(statusIDs)}
                        checked={statusIDs.every(statusID => props.role.visibleStatuses.includes(statusID))}
                        ref={visibleCheckbox}
                        disabled={disabled}
                    />
                </th>
                <th className='th'>
                    <Checkbox
                        type={CheckboxDate.Type.SQUARED_FIVE}
                        label='Устанавливать статус'
                        onChange={() => handleSelectSettable(statusIDs)}
                        checked={statusIDs.every(statusID => props.role.settableStatuses.includes(statusID))}
                        ref={settableCheckbox}
                        disabled={disabled}
                    />
                </th>
            </tr>
            </thead>
            <tbody>
            {props.status.map(status => {
                return (
                    <tr key={status.id}>
                        <td className='td'>
                            <div
                                className='select__status'
                                style={{
                                    backgroundColor: `var(--${COLORS.STATUS[status.group]})`,
                                    color: COLORS.STATUS[status.group] === 'disabled' ? 'var(--secondary)' : 'var(--background)'
                                }}
                            >
                                {status.name}
                            </div>
                        </td>
                        <td className='td'>
                            <div className='td_checkbox'>
                                <Checkbox
                                    type={CheckboxDate.Type.SQUARED_FIVE}
                                    onChange={() => handleSelectVisible([status.id])}
                                    checked={props.role.visibleStatuses.includes(status.id)}
                                    disabled={disabled}
                                />
                            </div>
                        </td>
                        <td className='td'>
                            <div className='td_checkbox'>
                                <Checkbox
                                    type={CheckboxDate.Type.SQUARED_FIVE}
                                    onChange={() => handleSelectSettable([status.id])}
                                    checked={props.role.settableStatuses.includes(status.id)}
                                    disabled={disabled}
                                />
                            </div>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    role: state.role
})

const mapDispatchToProps = {
    changeRoleState
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAvailableStatuses)