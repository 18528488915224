import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import {
    createSalaryRule,
    saveSalaryRule,
    deleteSalaryRule,
    changeSalaryRuleState
} from '../../../../Redux/actions/payrulleAction'
import {resetPayRule} from '../../../../Redux/actions/payrulleAction'
import {hasIDinHierarchy, onlyNumbers, onlyNumbersAndPoint} from "../../../general/utils"
import {ICON} from '../../../../data/icons'
import {EditorID, Modal} from "../../../../data/data"
import {ButtonData} from "../../../../data/ButtonData"
import {COLORS} from "../../../../data/colors"

import Button from "../../../general/Button"
import WarningOrange from '../../../general/WarningOrange'
import ChooseButton from '../../../general/ChooseButton'
import BottomButtons from '../../../general/BottomButtons'
import Icon from '../../../general/Icon'
import LabelInput from '../../../general/LabelInput'
import SelectFromList from "../../../general/SelectFromList";


const SalaryRuleReducer = (props) => {

    const isPremiumWork = [1, 2, 3, 4, 5].includes(props.salaryRule.type.type)
    const isFixedSalary = [7, 8].includes(props.salaryRule.type.type)
    const withCoefficient = [4, 5, 6].includes(props.salaryRule.type.type)
    const availableStatuses = props.status_group.filter(status_group => [4, 6].includes(status_group.id))

    const handleClose = () => {
        props.changeVisibleState({isCentralModalOpen: false, modalCentralType: Modal.Type.SALARY_RULE})
        props.resetPayrule()
    }

    const clickHandel = (event) => {
        const listIds = [EditorID.SALARY_RULE, EditorID.NEW_SALARY_RULE]
        if (!hasIDinHierarchy(event, listIds)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const handleChangeCoefficient = (idx, value) => {
        let count_coeff = props.salaryRule.count_coeff
        count_coeff.map((item, i) => {
            if (i === idx) {
                item.coef = parseInt(onlyNumbers(value))
            }
        })
        props.changeSalaryRuleState({count_coeff})
    }

    const handleChangeCost = (idx, value) => {
        let count_coeff = props.salaryRule.count_coeff
        count_coeff.map((item, i) => {
            if (i === idx) {
                item.cost = parseInt(onlyNumbers(value))
            }
        })
        props.changeSalaryRuleState({count_coeff})
    }

    const handleDeleteCoefficient = (idx) => {
        const count_coeff = props.salaryRule.count_coeff.filter((item, i) => idx !== i)
        props.changeSalaryRuleState({count_coeff})
    }

    const handleAddCoefficient = () => {
        const count_coeff = props.salaryRule.count_coeff.concat([{cost: 0, coef: 0}])
        props.changeSalaryRuleState({count_coeff})
    }

    return (
        <div
            className='modal__box modal__box_editor w600'
            id={EditorID.SALARY_RULE}
        >
            <h4>{props.salaryRule.edit ? 'Редактировать правило' : 'Создать правило'}</h4>

            <div className='modal__body modal__body-editor modal__body-editor_small'>
                <div className='two-buttons'>
                    <SelectFromList
                        className='w220'
                        title='Правило'
                        list={props.payRuleTypes}
                        setElement={type => props.changeSalaryRuleState({type})}
                        current_object={props.salaryRule.type}
                        noChoosed='Выберете правило'
                    />
                    <SelectFromList
                        className='w220'
                        title='Тип заказа'
                        list={props.order_type}
                        setElement={orderType => props.changeSalaryRuleState({orderType})}
                        current_object={props.salaryRule.orderType}
                        invisible={!isPremiumWork}
                    />
                </div>
                <div className='two-buttons'>
                    <SelectFromList
                        className='w220'
                        title='Считать по статусу'
                        list={availableStatuses}
                        setElement={statusGroup => props.changeSalaryRuleState({statusGroup})}
                        current_object={props.salaryRule.statusGroup}
                        noChoosed='Выберете правило'
                        invisible={!isPremiumWork}
                    />
                    <ChooseButton
                        title='Метод расчета'
                        name={['%', 'руб']}
                        func1={() => props.changeSalaryRuleState({method: 0})}
                        func2={() => props.changeSalaryRuleState({method: 1})}
                        checked={true}
                        invisible={isFixedSalary}
                    />
                </div>


                {withCoefficient ?
                    <div className='box__forms'>
                        <WarningOrange
                            width='500px'
                            text='Расчет по выбранному правилу может происходить двумя способами'
                        />

                        <div className='row ai-c'>
                            <div className='number-in-round'>1</div>
                            <div>В случае, если в прейскуранте услуг в карточке услуги указано значение вознаграждения, то в расчете ЗП будет использоваться это значение умноженное на коэффициент</div>
                        </div>

                        <div className='row jc-c ai-c g6'>
                            <div className=''>Коэффициент</div>
                            <LabelInput
                                className='w70'
                                onChange={event => props.changeSalaryRuleState({coefficient: onlyNumbersAndPoint(event.target.value)})}
                                value={props.salaryRule.coefficient}
                            />
                        </div>

                        <div className='row ai-c'>
                            <div className='number-in-round'>2</div>
                            <div>Для всех остальных услуг будет использоваться правило расчета:</div>
                        </div>
                    </div>
                    : null
                }

                {!isFixedSalary ?
                    <>
                        {props.salaryRule.count_coeff.map((count_coef, idx) => (
                            <div
                                key={idx}
                                className='row g6 ai-c'
                            >
                                <div>Если сумма работы</div>
                                <div>&ge;</div>
                                <LabelInput
                                    className='w90'
                                    onChange={event => handleChangeCost(idx, event.target.value)}
                                    unit='руб'
                                    value={count_coef.cost}
                                />
                                <div>Вознаграждение составит</div>
                                <LabelInput
                                    className='w90'
                                    onChange={event => handleChangeCoefficient(idx, event.target.value)}
                                    unit={props.salaryRule.method ? 'руб.' : '%'}
                                    value={count_coef.coef}
                                />
                                {idx ?
                                    <div onClick={() => handleDeleteCoefficient(idx)}>
                                        <Icon
                                            className='icon cur-p'
                                            icon={ICON.TRASH}
                                            color={COLORS.NAME.ERROR}
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        ))
                        }
                        <div className='row jc-c'>
                            <Button
                                size={ButtonData.Size.MEDIUM}
                                type={ButtonData.Type.TERTIARY}
                                title='Добавить'
                                onClick={handleAddCoefficient}
                                invisible={isFixedSalary}
                            />
                        </div>
                    </>
                    : null
                }

                {isFixedSalary ?
                    <div className='box__forms'>
                        <WarningOrange
                            width='500px'
                            text='Расчет по выбранному правилу будет расчитвываться исходя из графика рабочих смен'
                        />
                        <LabelInput
                            className='w220'
                            title='Ставка'
                            onChange={event => props.changeSalaryRuleState({fixSalary: parseInt(onlyNumbers(event.target.value))})}
                            unit='руб.'
                            value={props.salaryRule.fixSalary}
                        />
                    </div>
                    : null
                }
            </div>
            <BottomButtons
                edit={props.salaryRule.edit}
                create={() => props.createSalaryRule()}
                save={() => props.saveSalaryRule()}
                delete={() => props.deleteSalaryRule(true)}
                close={handleClose}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    salaryRule: state.salaryRule,
    status_group: state.data.status_group,
    order_type: state.data.order_type,
    payRuleTypes: state.data.payRuleTypes
})

const mapDispatchToProps = {
    changeVisibleState,
    changeSalaryRuleState,
    createSalaryRule,
    resetPayrule: resetPayRule,
    saveSalaryRule,
    deleteSalaryRule
}

export default connect(mapStateToProps, mapDispatchToProps)(SalaryRuleReducer)