import store from '../store'
import {serverRequest} from './actionUtils'
import {getOrderFilter} from './orderActions'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeOrderPartState( data ) {
    return {
        type: Action.OrderPart.CHANGE_STATE,
        data
    }
}

export function editOrderPart(order_part) {
    return {
        type: Action.OrderPart.EDIT,
        order_part
    }
}

export function resetOrderPart() {
    return {
        type: Action.OrderPart.RESET
    }
}

export function createCustomOrderPart() {

    const state = store.getState()

    const body = {
        amount: state.orderPart.amount,
        cost: state.orderPart.cost || 0,
        discount_value: state.orderPart.discount_value || 0,
        engineer_id: state.orderPart.engineer.id || 0,
        price: state.orderPart.price || 0,
        total: state.orderPart.total || 0,
        title: state.orderPart.title,
        comment: state.orderPart.comment,
        deleted: false,
        warranty_period: state.orderPart.warranty_period,
        order_id: state.order.edit,

        order_type_id: state.order.order_type_id,

        filter_order: getOrderFilter()
    }
    const url = URLS.OrderPart.POST
    const error_message = 'Запрос на добавление зачасти не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message,)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Запчасть успешно добавлена')
        }
    }
}

export function saveOrderPart() {

    const state = store.getState()

    const body = {
        id: state.orderPart.edit,
        amount: state.orderPart.amount,
        cost: state.orderPart.cost || 0,
        discount_value: state.orderPart.discount_value || 0,
        engineer_id: state.orderPart.engineer.id || 0,
        price: state.orderPart.price || 0,
        total: state.orderPart.total || 0,
        title: state.orderPart.title,
        comment: state.orderPart.comment,
        warranty_period: state.orderPart.warranty_period,
        order_id: state.order.edit,
        warehouse_parts_id: state.orderPart.warehouse_parts_id || null,

        filter_order: getOrderFilter()
    }
    const url = URLS.OrderPart.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение запчасти не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Запчасть успешно изменена')
        }
    }
}

export function deleteOrderPart(flag) {

    const state = store.getState()

    const body = {
        id: state.orderPart.edit,
        order_id: state.order.edit,
        to_warehouse_id: state.orderPart.to_warehouse_id || null,
        deleted: flag,

        filter_order: getOrderFilter()
    }
    const url = URLS.OrderPart.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос заказов не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, events: data.events || []}
            })
            const text = flag ? 'Запчасть успешно удалена' : 'Запчасть успешно восстановлена'
            showAlert(dispatch, AlertType.SUCCESSES, text)
        }
    }
}