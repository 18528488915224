import store from '../store'
import {serverRequest} from './actionUtils'
import {Action} from "../../data/ActionTypes";
import {Modal} from "../../data/data";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";

export function changeRoleState( data ) {
    return {
        type: Action.Role.CHANGE_STATE,
        data
    }
}

export function resetRole() {
    return {
        type: Action.Role.RESET
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.role.showDeleted
    }
}

export function getRole(role_id){

    const body = {id: role_id}
    const url = URLS.Role.GET
    const error_message = 'Запрос роли не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Role.EDIT,
                role: data.role,
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: true, modalType: Modal.Type.ROLE},
            })
        }

    }

}

export function addRoles() {

    const body =  getFilter()
    const url = URLS.Role.ADD
    const error_message = 'Запрос ролей не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Role.CHANGE_STATE,
                data: {roles: data.roles},
            })
        }

    }
}

export function createRole() {

    const state = store.getState()

    const body = {
        title: state.role.title,
        earnings_visibility: state.role.earningsVisibility,
        leads_visibility: state.role.leadsVisibility,
        orders_visibility: state.role.ordersVisibility,
        permissions: state.role.permissions,
        settable_statuses: state.role.settableStatuses,
        visible_statuses: state.role.visibleStatuses,
        settable_discount_margin: state.role.settableDiscountMargin,

        filter: getFilter()
    }
    const url = URLS.Role.POST
    const error_message = 'Запрос на создание роли не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Role.CHANGE_STATE,
                data: {roles: data.roles},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Role.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Роль успешно создана')
        }
    }
}

export function saveRole() {

    const state = store.getState()

    const body = {
        id: state.role.edit,
        title: state.role.title,
        deleted: state.role.deleted,
        earnings_visibility: state.role.earningsVisibility,
        leads_visibility: state.role.leadsVisibility,
        orders_visibility: state.role.ordersVisibility,
        permissions: state.role.permissions,
        settable_statuses: state.role.settableStatuses,
        visible_statuses: state.role.visibleStatuses,
        settable_discount_margin: state.role.settableDiscountMargin,

        filter: getFilter()
    }
    const url = URLS.Role.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение роли не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Role.CHANGE_STATE,
                data: {roles: data.roles},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Role.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Роль успешно изменена')
        }
    }
}

export function deleteRole(flag) {

    const state = store.getState()

    const body = {
        id: state.role.edit,
        deleted: flag,
        filter: getFilter()
    }
    const url = URLS.Role.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на удаление/восстановление роли не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Role.CHANGE_STATE,
                data: {roles: data.roles},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: Modal.Type.NONE},
            })
            dispatch({
                type: Action.Role.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Роль успешно удалена/восстановлена')
        }
    }
}