export const NotificationData = Object.freeze({
    TargetAudience: {
        CLIENT: 1,
        MANAGER: 2,
        ENGINEER: 3
    },
    Type: {
        SMS: 1,
        EMAIL: 2
    }
})