import {permission_warehouse} from "../../data/permissions";
import {Action} from "../../data/ActionTypes";

const initialState = {

    warehouses: [],
    warehouse_categories: [],

    tabs: JSON.parse(localStorage.getItem('warehouse_tabs')) || 0,

    edit: 0,
    title: '',
    description: '',
    deleted: false,
    isGlobal: false,
    img_url: '',
    permissions: permission_warehouse,
    employee_permissions: [],
    branch: {},
    categories: [],
    img: '',

    permissions_employee: 0,

    title_category: '',
    parent_category_id: 0,
    category_deleted: false,

    current_category: {},
    current_parent_category: {},

    showDeleted: false
}


export const warehouseReducer = (state = initialState, action) => {
    switch (action.type){

        case Action.Warehouse.CHANGE_STATE: {
            const local_save = ['tabs']
            Object.keys(action.data).forEach(field => {
                if (local_save.includes(field)) localStorage.setItem(`warehouse_${field}`, JSON.stringify(action.data[field]))
            })
            return {...Object.assign(state, action.data)}
        }

        case Action.Warehouse.EDIT: {
            return {
                ...state,
                edit: action.warehouse.id,
                title: action.warehouse.title,
                description: action.warehouse.description,
                deleted: action.warehouse.deleted,
                isGlobal: action.warehouse.isGlobal,
                permissions: action.warehouse.permissions,
                employee_permissions: action.warehouse.employee_permissions,
                branch: action.warehouse.branch,
                categories: action.warehouse.categories,
                branch_id: action.warehouse.branch.id
            }
        }

        case Action.Warehouse.EDIT_CATEGORY: {
            return {
                ...state,
                edit: action.warehouse_category.id,
                title_category: action.warehouse_category.title,
                parent_category_id: action.warehouse_category.parent_category_id,
                category_deleted: action.warehouse_category.deleted,
            }
        }

        case Action.Warehouse.RESET: {
            return {
                ...state,
                edit: 0,
                title: '',
                description: '',
                deleted: false,
                isGlobal: false,
                permissions: permission_warehouse,
                employee_permissions: [],
                branch: {},
                categories: [],
                permissions_employee: 0,

                title_category: '',
                parent_category_id: 0,
                warehouse_id: 0,
                category_deleted: false

            }
        }

        case Action.Warehouse.SELECTED: {
            if (action.value.every(val => state[action.field].includes(val))) {
                return {
                    ...state,
                    [action.field]: state[action.field].filter(val => !action.value.includes(val)),
                }
            } else {
                return {
                    ...state,
                    [action.field]: state[action.field].concat(action.value.filter(val => !state[action.field].includes(val)))
                }
            }
        }

        default: return state
    }

}