export const order_event_types = [
    {id: 1, visible: true, title: 'Заказ создан', value: 'CREATE_ORDER'},
    {id: 2, visible: true, title: 'Инженер назначен', value: 'ASSIGN_ENGINEER'},
    {id: 3, visible: true, title: 'Инженер изменен', value: 'CHANGE_ENGINEER'},
    {id: 4, visible: true, title: 'Менеджер назначен', value: 'ASSIGN_MANAGER'},
    {id: 5, visible: true, title: 'Менеджер изменен', value: 'CHANGE_MANAGER'},
    {id: 6, visible: true, title: 'Добавлен клиент', value: 'ADD_CLIENT'},
    {id: 7, visible: true, title: 'Клиент изменен', value: 'CHANGE_CLIENT'},
    {id: 8, visible: true, title: 'Изменение данных', value: 'CHANGE_DATA'},
    {id: 9, visible: true, title: 'Срок выполнения изменен', value: 'CHANGE_ESTIMATED_DONE_AT'},
    {id: 10, visible: true, title: 'Добавлена операция', value: 'ADD_OPERATION'},
    {id: 11, visible: true, title: 'Операция удалена', value: 'DELETE_OPERATION'},
    {id: 12, visible: true, title: 'Операция изменена', value: 'CHANGE_OPERATION'},
    {id: 13, visible: true, title: 'Добавлена запчасть', value: 'ADD_ORDER_PART'},
    {id: 14, visible: true, title: 'Запчасть удалена', value: 'DELETE_ORDER_PART'},
    {id: 15, visible: true, title: 'Запчасть изменена', value: 'CHANGE_ORDER_PART'},
    {id: 16, visible: true, title: 'Платеж добавлен', value: 'ADD_PAYMENT'},
    {id: 17, visible: true, title: 'Платеж удален', value: 'DELETE_PAYMENT'},
    {id: 18, visible: true, title: 'Статус изменен', value: 'CHANGE_STATUS'},
    {id: 19, visible: true, title: 'Комментарии', value: 'ADD_COMMENT'},
    {id: 20, visible: true, title: 'SMS оповещение', value: 'SEND_SMS'},
    {id: 21, visible: true, title: 'Email оповещение', value: 'SEND_EMAIL'},
    {id: 22, visible: true, title: 'Перемещение заказа', value: 'MOVE_TO'}
]

export const request_event_types = [
    {id: 1, visible: true, title: 'Запрос создан', value: 'CREATE_REQUEST_SPARE_PART'},
    {id: 2, visible: true, title: 'Добавлена запчасть', value: 'ADD_PART'},
    {id: 3, visible: true, title: 'Назначен исполнитель', value: 'ASSIGN_EXECUTOR'},
    {id: 4, visible: true, title: 'Добавлен поставщик', value: 'ADD_SUPPLER'},
    {id: 5, visible: true, title: 'Добавлен клиент', value: 'ADD_CLIENT'},
    {id: 6, visible: true, title: 'Добавлен заказ', value: 'ADD_ORDER'},
    {id: 7, visible: true, title: 'Запчасть изменена', value: 'CHANGE_PART'},
    {id: 8, visible: true, title: 'Исполнитель изменен', value: 'CHANGE_EXECUTOR'},
    {id: 9, visible: true, title: 'Изменен поставщик', value: 'CHANGE_SUPPLER'},
    {id: 10, visible: true, title: 'Изменен клиент', value: 'CHANGE_CLIENT'},
    {id: 11, visible: true, title: 'Изменен заказ', value: 'CHANGE_ORDER'},
    {id: 12, visible: true, title: 'Изменены данные', value: 'CHANGE_DATA'},
    {id: 13, visible: true, title: 'Статус изменен', value: 'CHANGE_STATUS'},
    {id: 14, visible: true, title: 'Добавлен комментарий', value: 'ADD_COMMENT'},
    {id: 15, visible: true, title: 'Изменен срок поставки', value: 'CHANGE_ESTIMATED_COME_AT'},
    {id: 16, visible: true, title: 'Установлен срок поставки', value: 'ADD_ESTIMATED_COME_AT'}
]

export const Payment = {
    Direction: {
        MOVEMENT: 0,
        OUTCOME: 1,
        INCOME: 2
    },
    SumConfiguration: [
        'ru',
        {
            style: 'currency',
            currency: 'RUB'
        }
    ]
}

export const Modal =  Object.freeze({
    Type: {
        NONE: 0,
        ORDER: 1,
        PAYMENT: 2,
        PART: 3,
        RESIDUE_RULE: 4,
        REQUEST_SPARE_PART: 5,
        REGISTRATION: 6,
        REGISTRATION_PART: 7,
        WRITE_OFF: 8,
        PAYMENT_CARD: 9,
        WAREHOUSE_MOVEMENT: 10,
        INVENTORY: 11,
        INVENTORY_PREVIEW: 12,
        BACK: 13,
        CASH_REGISTER: 14,
        CASH_REGISTER_EMPLOYEE: 15,
        WAREHOUSE_CATEGORIES: 16,
        ROLE: 17,
        EMPLOYEE: 18,
        SALARY_RULE: 19,
        BRANCH: 20,
        WAREHOUSE: 21,
        WAREHOUSE_EMPLOYEE: 22,
        NOT_EVENT: 23,
        NOT_TEMPLATE: 24
    }
})

export const EditorID =  Object.freeze({
    ORDER: 'ORDER',
    PAYMENT: 'PAYMENT',
    PART: 'PART',
    RESIDUE_RULE: 'RESIDUE_RULE',
    REQUEST_SPARE_PART: 'REQUEST_SPARE_PART',
    REGISTRATION: 'REGISTRATION',
    REGISTRATION_PART: 'REGISTRATION_PART',
    ADD_REGISTRATION_PART: 'ADD_REGISTRATION_PART',
    WRITE_OFF: 'WRITE_OFF',
    PAYMENT_CARD: 'PAYMENT_CARD',
    WAREHOUSE_MOVEMENT: 'WAREHOUSE_MOVEMENT',
    INVENTORY: 'INVENTORY',
    INVENTORY_PREVIEW: 'INVENTORY_PREVIEW',
    BACK: 'BACK',
    CASH_REGISTER: 'CASH_REGISTER',
    CASH_REGISTER_EMPLOYEE: 'CASH_REGISTER_EMPLOYEE',
    WAREHOUSE_CATEGORIES: 'WAREHOUSE_CATEGORIES',
    ROLE: 'ROLE',
    EMPLOYEE: 'EMPLOYEE',
    SALARY_RULE: 'SALARY_RULE',
    BRANCH: 'BRANCH',
    WAREHOUSE: 'WAREHOUSE',
    WAREHOUSE_EMPLOYEE: 'WAREHOUSE_EMPLOYEE',
    NOT_EVENT: 'NOT_EVENT',
    NOT_TEMPLATE: 'NOT_TEMPLATE',

    NEW_CATEGORY: 'NEW_CATEGORY',
    NEW_SALARY_RULE: 'NEW_SALARY_RULE',
    NEW_NOTIFICATION: 'NEW_NOTIFICATION'
})

