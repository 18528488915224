import {Action} from "../../data/ActionTypes";

const initialState = {

   edit: 0,

   amount: 1,
   cost: 0,
   discount_value: 0,
   engineer: {},
   price: 0,
   total: 0,
   title: '',
   comment: '',
   percent: true,
   discount: 0,
   warranty: false,
   warranty_period: 0,
   created_at: 0,
   order_id: 0,
   dict_id: 0,

   warranty_value: 30*24*60*60

}
 
export const operationReducer = (state = initialState, action) => {
   switch (action.type){

      case Action.Operation.CHANGE_STATE: {
         return {...Object.assign(state, action.data)}
      }
 
      case Action.Operation.EDIT: {
         return {
            ...state, 
            edit: action.operation.id,
            amount: action.operation.amount,
            cost: action.operation.cost,
            discount_value: action.operation.discount_value,
            engineer: action.operation.engineer,
            price: action.operation.price,
            total: action.operation.total,
            title: action.operation.title,
            comment: action.operation.comment,
            percent: action.operation.percent,
            discount: action.operation.discount || 0,
            warranty: action.operation.warranty,
            warranty_period: action.operation.warranty_period,
            created_at: action.operation.created_at,
            order_id: action.operation.order_id,
            dict_id: action.operation.dict_id
         }
      }
 
      case Action.Operation.RESET: {
         return {
            ...state, 
            edit: 0,
            amount: 1,
            cost: 0,
            discount_value: 0,
            // engineer_id: 0,
            price: 0,
            total: 0,
            title: '',
            comment: '',
            percent: true,
            discount: 0,
            warranty: false,
            warranty_period: 0,
            created_at: 0,
            order_id: 0,
            dict_id: 0 
         }
      }
 
       
      default: return state
   }
    
}