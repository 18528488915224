import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeOrderState(data) {
    return {
        type: Action.Order.CHANGE_STATE,
        data
    }
}

export function resetOrder() {
    return {
        type: Action.Order.RESET
    }
}

export function changeOrderField(id, field, value) {
    return {
        type: Action.Order.CHANGE_FIELD,
        id,
        field,
        value
    }
}

export function reorderOrderField(id, order) {
    return {
        type: Action.Order.REORDER_FIELD,
        id,
        order
    }
}

export function getOrderFilter() {
    const state = store.getState()
    return {
        sort: state.order.sort,
        field_sort: state.order.field_sort,
        page: state.order.page,

        engineer_id: !state.data.user.role.orders_visibility ? state.filter.engineer_id.concat([state.data.user.id]) : state.filter.engineer_id,
        overdue: state.filter.overdue,
        status_id: state.filter.status_id,
        status_overdue: state.filter.status_overdue,
        urgent: state.filter.urgent,
        order_type_id: state.filter.order_type_id,
        manager_id: state.filter.manager_id,
        created_at: state.filter.created_at,
        kindof_good_id: state.filter.kindof_good,
        brand_id: state.filter.brand,
        subtype_id: state.filter.subtype,
        client_id: state.filter.client_id,
        branch_id: state.branch.current_branch.id,

        search: state.filter.search,

        update_badges: true
    }
}

export function addOrders() {

    const body = getOrderFilter()
    const url = URLS.Order.ADD
    const error_message = 'Запрос заказов не выполнен'

    return async dispatch => {

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {spinner: true}
        })

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {
                    ordersShow: data.orders,
                    count: data.count
                }
            })
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {badges: data.badges}
            })
        }
        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {spinner: false}
        })
    }
}

export function createOrder() {

    const state = store.getState()

    const body = {
        estimated_done_at: state.order.estimated_done_at,

        order_type_id: state.order.order_type?.id,
        client_id: state.order.client.id  || null,
        ad_campaign_id: state.order.ad_campaign.id  || null,
        manager_id: state.order.manager.id  || null,
        engineer_id: state.order.engineer?.id,
        created_by_id: state.data.user.id,
        branch_id: state.branch.current_branch.id,
        status_id: 1,
        kindof_good_id: state.order.kindof_good.id  || null,
        brand_id: state.order.brand.id  || null,
        subtype_id: state.order.subtype.id  || null,
        model_id: state.order.model.id  || null,
        malfunction: state.order.malfunction,
        packagelist: state.order.packagelist,
        appearance: state.order.appearance,
        urgent: state.order.urgent,

        manager_notes: state.order.manager_notes,
        estimated_cost: state.order.estimated_cost,

        filter: getOrderFilter()
    }
    const url = URLS.Order.POST
    const error_message = 'Запрос на создание заказа не выполнен'


    return async dispatch => {

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: true}
        })

        dispatch({
            type: Action.Visible.CHANGE_STATE,
            data: {statusOrderEditor: false}
        })

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, count: data.count, events: data.events || []}
            })
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {badges: data.badges}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Заказ успешно создан')
        }

        if (state.view.checkOrderSticker) {
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusOrderSticker: true, needToResetOrder: true}
            })
        } else {
            dispatch({
                type: Action.Order.RESET
            })
        }

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: false}
        })
    }
}

export function changeStatus(status_id, order_id) {

    const state = store.getState()

    let filter = getOrderFilter()
    filter.update_order = state.order.edit

    const body = {
        order_id: order_id,
        status_id: status_id,
        filter
    }
    const url = URLS.Order.CHANGE_STATUS
    const error_message = 'Запрос на изменение статуса не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            if (state.order.edit) {
                dispatch({
                    type: Action.Order.EDIT,
                    order: data.order
                })
            }
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, count: data.count, events: data.events || []}
            })
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {badges: data.badges}
            })
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {badges: data.badges}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Статус успешно изменен')
        }

    }
}

export function saveOrder() {

    const state = store.getState()

    const body = {
        id: state.order.edit,
        assigned_at: state.order.assigned_at,
        duration: state.order.duration,
        estimated_done_at: state.order.estimated_done_at,
        scheduled_for: state.order.scheduled_for,
        warranty_date: state.order.warranty_date,
        status_deadline: state.order.status_deadline,

        ad_campaign_id: state.order.ad_campaign.id || null,
        client_id: state.order.client.id || null,
        order_type_id: state.order.order_type.id || null,
        manager_id: state.order.manager.id || null,
        engineer_id: state.order.engineer.id || null,
        kindof_good_id: state.order.kindof_good.id || null,
        brand_id: state.order.brand.id || null,
        subtype_id: state.order.subtype.id || null,
        model_id: state.order.model.id || null,

        serial: state.order.serial,
        malfunction: state.order.malfunction,
        packagelist: state.order.packagelist,
        appearance: state.order.appearance,
        engineer_notes: state.order.engineer_notes,
        manager_notes: state.order.manager_notes,
        resume: state.order.resume,
        cell: state.order.cell,

        estimated_cost: state.order.estimated_cost,
        urgent: state.order.urgent,
        filter: getOrderFilter()
    }
    const url = URLS.Order.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение заказа не выполнен'


    return async dispatch => {

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: true}
        })

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {ordersShow: data.orders, count: data.count, events: data.events || []}
            })
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {badges: data.badges}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Заказ успешно изменен')
        }

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: false}
        })
    }
}

export function getOrder(order_id) {

    const body = {id: order_id}
    const url = URLS.Order.GET
    const error_message = 'Запрос заказа не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.EDIT,
                order: data.order
            })
            dispatch({
                type: Action.Book.CHANGE_STATE,
                data: {
                    equipment_type: data.order.kindof_good,
                    equipment_brand: data.order.brand,
                    equipment_subtype: data.order.subtype,
                    equipment_model: data.order.model
                }
            })
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {events: data.events || []}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusOrderEditor: true}
            })
        }
    }
}

export function addEventComment() {

    const state = store.getState()

    const body = {
        order_id: state.order.edit,
        current_status_id: state.order.status.id,
        branch_id: state.branch.current_branch.id,
        comment: state.order.event_comment
    }
    const url = URLS.Order.COMMENT
    const error_message = 'Запрос на создание комментария не выполнен'

    return async dispatch => {

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: true}
        })

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Order.CHANGE_STATE,
                data: {events: data.events || [], event_comment: ''}
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Комментарий успешно добавлен')
        }

        dispatch({
            type: Action.Order.CHANGE_STATE,
            data: {showLoader: false}
        })
    }
}