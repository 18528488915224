import {Action} from "../../data/ActionTypes";

const initialState = {

    alerts: [],

    // menuRows: data_menu_rows,
    // current_menu_row: '',

    user: JSON.parse(sessionStorage.getItem('user')) || {},
    status: [],
    status_group: [],
    order_type: [],
    equipment: [],
    ad_campaign: [],
    payrules: [],
    group_dict_service: [],
    dict_service: [],
    payRuleTypes: [],

    counters: [],
    dictMalfunction: [],
    count_malfunction: 0,
    dictPackagelist: [],
    count_packagelist: 0,
    item_payments: [],
    count_item_payments: 0,

    // для изменения размера столбцов колонок
    position_cursor: 0,
    position_over: null,

    csrfToken: 'not exist',
    jwt_token: JSON.parse(sessionStorage.getItem('jwt_token')) || 'not exist',
    credentials: process.env.REACT_APP_CREDENTIALS,
    login_status: JSON.parse(sessionStorage.getItem('login_status')) || false,
    error_message: '',
    url_server: process.env.REACT_APP_URL_SERVER
}

export const dataReducer = (state = initialState, action) => {


    switch (action.type) {

        case Action.Data.CHANGE_STATE: {
            const session_save = ['login_status', 'jwt_token', 'user']
            Object.keys(action.data).forEach(field => {
                if (session_save.includes(field)) sessionStorage.setItem(field, JSON.stringify(action.data[field]))
            })
            return {...Object.assign(state, action.data)}
        }

        case Action.Alert.SHOW: {
            let alerts = state.alerts
            if (alerts.length >= 3) alerts.shift()
            return {
                ...state,
                alerts: alerts.concat([action.alert])
            }
        }
        case Action.Alert.CLOSE: {
            return {
                ...state,
                alerts: state.alerts.filter(alert => alert.id !== action.id)
            }

        }


        default:
            return state
    }
}
