import React from 'react'
import { connect } from 'react-redux'

import {getNotEvent} from '../../../../Redux/actions/notEventAction'
import {EventType} from '../../../../data/events'
import {COLORS} from "../../../../data/colors"
import {Permissions} from "../../../../data/permissions";

import Data from "../../../general/cell/Data"


const EventTable = props => {

    const handleEdit = (event) => {

        console.log(props.permissions.includes(Permissions.CAN_EDIT_NOTIFICATION_EVENT))

        if(props.permissions.includes(Permissions.CAN_EDIT_NOTIFICATION_EVENT)) {
            props.getNotEvent(event.id)
        }
    }

    return (
        <table>
            <thead>
            <tr>
                <th className='th'>При событии</th>
                <th className='th'>Отправить</th>
                <th className='th'>Шаблон</th>
            </tr>
            </thead>
            <tbody>
            {props.notEvent.events.filter(event => event.target_audience.id === props.target_audience).map(event =>{

                return (
                    <tr
                        key={event.id}
                        className={event.deleted ? 'tr_deleted' : 'tr'}
                        onDoubleClick={() =>handleEdit(event)}
                    >
                        <td className='td pd5'>
                            <div className='pd5'>{event.event_type.title}</div>
                            {event.event_type.id === EventType.ORDER_STATUS_CHANGED_TO ?
                                <div className='row'>
                                    {event.statuses.map(status => (
                                        <div
                                            key={status.id}
                                            className='status fw-bold nowrap'
                                            style={{
                                                backgroundColor: `var(--${COLORS.STATUS[status.group]})`,
                                                color: COLORS.STATUS[status.group] === 'disabled' ? 'var(--secondary)' : 'var(--background)'
                                            }}
                                        >
                                            {status.name}
                                        </div>
                                    ))}
                                </div>
                                : null
                            }

                        </td>
                        <Data data={event.notification_type.title}/>
                        <Data data={event.template.title}/>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    notEvent: state.notEvent,
    permissions: state.data.user.role.permissions,
    statuses: state.data.status
})

const mapDispatchToProps = {
    getNotEvent
}


export default connect (mapStateToProps, mapDispatchToProps) (EventTable)