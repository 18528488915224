import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {changeVisibleState} from '../../../../Redux/actions'
import {changeNotEventState, createNotEvent, deleteNotEvent} from '../../../../Redux/actions/notEventAction'
import {resetNotEvent, saveNotEvent, selectedNotEvent} from '../../../../Redux/actions/notEventAction'
import {checkObject, hasIDinHierarchy} from "../../../general/utils";
import {EventType} from '../../../../data/events'
import {EditorID, Modal} from "../../../../data/data";
import {Permissions} from "../../../../data/permissions";
import {NotificationData} from "../../../../data/NotificationData";

import BottomButtons from '../../../general/BottomButtons'
import ChooseButton from '../../../general/ChooseButton'
import SelectStatuses from '../../../general/SelectStatuses'
import SelectFromList from "../../../general/SelectFromList";


const NotEventEditor = props => {

    const handleClose = () => {
        props.changeVisibleState({
            isRightModalOpen: false, modalType: Modal.Type.NONE,
            inputNotEventEventChecked: true,
            inputNotEventTemplateChecked: true
        })
        props.resetNotEvent()
    }

    const clickHandel = (event) => {

        const listIDs = [EditorID.NOT_TEMPLATE, EditorID.NEW_NOTIFICATION]

        if (!hasIDinHierarchy(event, listIDs)) {
            handleClose()
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickHandel)
        return () => {
            window.removeEventListener('click', clickHandel)
        }
    })

    const checkAllConditions = () => {

        if (checkObject(props.notEvent.event_type) && checkObject(props.notEvent.notification_template)) {
            return true
        }
        if (!checkObject(props.notEvent.event_type)) {
            props.changeVisibleState({inputNotEventEventChecked: false})
        }
        if (!checkObject(props.notEvent.notification_template)) {
            props.changeVisibleState({inputNotEventTemplateChecked: false})
        }

        return false
    }

    const handleCreate = () => {
        if (checkAllConditions()) {
            props.createNotEvent()
        }
    }

    const handleSave = () => {
        if (checkAllConditions()) {
            props.saveNotEvent()
        }
    }

    const findNotificationType = (notificationTypeId) => {
        return props.notEvent.notificationTypes.find(notificationType => notificationType.id === notificationTypeId)
    }

    const can_deleted = props.permissions.includes(Permissions.CAN_DELETE_NOTIFICATION_EVENT)
    const can_recover = props.permissions.includes(Permissions.CAN_RECOVER_NOTIFICATION_EVENT)


    return (
        <div className="modal__box-right modal__box-right_w300" id={EditorID.NOT_TEMPLATE}>
            <h4>{props.notEvent.edit ? props.notEvent.title : 'Новое оповещение'}</h4>

            <div className="modal__body-right">
                <div className='modal__block-forms w250'>
                    <SelectFromList
                        title='При событии'
                        list={props.notEvent.eventTypes}
                        setElement={event_type => props.changeNotEventState({event_type})}
                        current_object={props.notEvent.event_type}
                        checkedFlag='inputNotEventEventChecked'
                        noChoosed='Не задано'
                        disabled={props.notEvent.deleted}
                    />
                    <SelectStatuses
                        func={value => props.selectedNotEvent(value, 'statuses')}
                        func_clear={() => props.changeNotEventState({statuses: []})}
                        current_list={props.notEvent.statuses}
                        range={[0, 7]}
                        invisible={props.notEvent.event_type?.id !== EventType.ORDER_STATUS_CHANGED_TO}
                    />

                    <ChooseButton
                        title='Отправить'
                        name={['SMS', 'Email']}
                        func1={() => props.changeNotEventState({notification_type: findNotificationType(NotificationData.Type.SMS)})}
                        func2={() => props.changeNotEventState({notification_type: findNotificationType(NotificationData.Type.EMAIL)})}
                        checked={props.notEvent.notification_type.id !== NotificationData.Type.EMAIL}
                        disabled={props.notEvent.deleted}
                    />
                    <SelectFromList
                        title='Шаблон'
                        list={props.templates}
                        setElement={notification_template => props.changeNotEventState({notification_template})}
                        current_object={props.notEvent.notification_template}
                        checkedFlag='inputNotEventTemplateChecked'
                        noChoosed='Не задано'
                        disabled={props.notEvent.deleted}
                    />
                    {
                        checkObject(props.notEvent.notification_template) ?

                            <div className="modal__block-forms">
                                <p>Шаблон:</p>
                                <p>{props.notEvent.notification_template.template}</p>
                            </div>

                            : null
                    }
                </div>
            </div>


            <BottomButtons
                edit={props.notEvent.edit}
                deleted={props.notEvent.deleted}
                create={handleCreate}
                save={handleSave}
                delete={can_deleted ? () => props.deleteNotEvent(true) : null}
                recover={can_recover ? () => props.deleteNotEvent(false) : null}
                close={handleClose}
            />
        </div>

    )
}

const mapStateToProps = state => ({
    notEvent: state.notEvent,
    inputNotEventEventChecked: state.view.inputNotEventEventChecked,
    inputNotEventTemplateChecked: state.view.inputNotEventTemplateChecked,
    permissions: state.data.user.role.permissions,
    templates: state.notTemplate.templates
})

const mapDispatchToProps = {
    changeVisibleState,
    resetNotEvent,
    createNotEvent,
    saveNotEvent,
    deleteNotEvent,
    changeNotEventState,
    selectedNotEvent
}

export default connect(mapStateToProps, mapDispatchToProps)(NotEventEditor)
