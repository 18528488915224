import store from '../store'
import {serverRequest} from './actionUtils'
import {showAlert} from '../actions'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";


export function changeFilterState( data ) {
    return {
        type: Action.Filter.CHANGE_STATE,
        data
    }
}


export function resetFilter() {
    return {
        type: Action.Filter.RESET
    }
}

export function resetTempFilter() {
    return {
        type: Action.Filter.RESET_TEMP
    }
}


export function selectedFilter( value, field, saveToApp=false ) {
    return {
        type: Action.Filter.SELECTED,
        field,
        value,
        saveToApp
    }
}

export function addCustomFilters() {

    const state = store.getState()

    const body = {employee_id: state.data.user.id}
    const url = URLS.Filter.ADD
    const error_message = 'Запрос пользовательских фильтров не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {customFilters: data.data}
            })
        }
    }
}

export function createCustomFilter() {

    const state = store.getState()

    const body = {
        title: state.filter.title,
        general: state.filter.general,
        employee_id: state.data.user.id,
        filters: {
            page: 0,
            engineer_id: state.filter.temp_engineers.length ? state.filter.temp_engineers.map(status => status.id) : null,
            overdue: null,
            status_id: state.filter.temp_statuses.length ? state.filter.temp_statuses.map(status => status.id) : null,
            status_overdue: null,
            urgent: null,
            order_type_id: state.filter.temp_order_types.length ? state.filter.temp_order_types.map(status => status.id) : null,
            manager_id: state.filter.temp_managers.length ? state.filter.temp_managers.map(status => status.id) : null,
            created_at: state.filter.temp_created_at.some(date => date) ? state.filter.temp_created_at : null,
            kindof_good: state.filter.temp_kindof_good_id,
            brand: state.filter.temp_brand,
            subtype: state.filter.temp_subtype,
            client_id: Object.values(state.filter.temp_client).length ? [state.filter.temp_client.id] : null
        }
    }
    const url = URLS.Filter.POST
    const error_message = 'Запрос на создание  фильтра не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {customFilters: data.data}
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusCreateNewFilter: false}
            })
            dispatch({
                type: Action.Filter.RESET_DATA
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Фильтр успешно создан')
        }

    }
}


export function deleteFilter() {

    const state = store.getState()

    const body = {
        id: state.filter.active_filter,
        employee_id: state.data.user.id
    }
    const url = URLS.Filter.POST
    const method = RequestMethod.DELETE
    const error_message = 'Запрос на удаление фильтра не выполнен'


    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Filter.CHANGE_STATE,
                data: {
                    customFilters: data.data,
                    active_filter: 0
                }
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {statusCreateNewFilter: false}
            })
            dispatch({
                type: Action.Filter.RESET_DATA
            })
            showAlert(dispatch, 'success', 'Фильтр успешно удален')
        }
    }
}