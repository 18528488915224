import store from '../store'
import {serverRequest} from './actionUtils'
import {Modal} from "../../data/data";
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {showAlert} from "../actions";
import {AlertType} from "../../data/AlertType";
import {RequestMethod} from "../../data/requestMethod";

export function changeRegistrationState( data ) {
    return {
        type: Action.Registration.CHANGE_STATE,
        data
    }
}

export function resetRegistration() {
    return {
        type: Action.Registration.RESET
    }
}

export function resetRegistrationPart() {
    return {
        type: Action.Registration.RESET_PART
    }
}

export function addRegistrationPart ( ) {
    return {
        type: Action.Registration.ADD_PART
    }
}

export function saveRegistrationPart ( idx ) {
    return {
        type: Action.Registration.SAVE_PART,
        idx
    }
}

export function editRegistrationPart ( idx, part ) {
    return {
        type: Action.Registration.EDIT_PART,
        idx,
        part
    }
}

export function deleteRegistrationPart ( idx ) {
    return {
        type: Action.Registration.DELETE_PART,
        idx
    }
}

function getFilter() {

    const state = store.getState()

    return {
        deleted: state.registration.showDeleted,
        custom_created_at: state.registration.filter_created_at,
        page: state.registration.page
    }
}

export function getRegistration(registration_id) {

    const state = store.getState()

    const body = {id: registration_id}
    const url = URLS.Registration.GET
    const error_message = 'Запрос оприходования не выполнен'

    return async dispatch => {

        if (state.data.user.role.permissions.includes('edit_registrations')) {

            const data = await serverRequest(body, url, dispatch, error_message)

            if (data.success) {
                dispatch({
                    type: Action.Registration.EDIT,
                    registration:  data.registration
                })
                dispatch({
                    type: Action.Visible.CHANGE_STATE,
                    data: {isRightModalOpen: true, modalType: Modal.Type.REGISTRATION}
                })
            }
        }
    }
}

export function addRegistration() {

    const body = getFilter()
    const url = URLS.Registration.ADD
    const error_message = 'Запрос оприходований не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Registration.CHANGE_STATE,
                data: {registrations: data.warehouse_registrations, registrations_count: data.count}
            })
        }
    }
}

export function createRegistration() {

    const state = store.getState()
    const now = Math.round(Date.now() / 1000)
    const parts = state.registration.parts.filter(part => !state.registration.inventory_id || part.checked)

    const body = {
        number: state.registration.number,
        created_at: state.registration.created_at || now,
        custom_created_at: parseInt(state.registration.custom_created_at) || now,
        deleted: state.registration.deleted,
        description: state.registration.description,
        parts,
        client_id: state.registration.client.id,
        warehouse_id: state.registration.warehouse.id,
        employee_id: state.data.user.id,
        price: state.registration.price,
        inventory_id: state.registration.inventory_id || null,
        filter: getFilter()
    }
    const url = URLS.Registration.POST
    const error_message = 'Запрос на создание оприходования не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message)

        if (data.success) {
            dispatch({
                type: Action.Registration.CHANGE_STATE,
                data: {registrations: data.warehouse_registrations, registrations_count: data.count},
            })
            if (state.inventory.edit) {
                dispatch({
                    type: Action.Inventory.EDIT,
                    inventory: data.inventory
                })
            }
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: ''},
            })
            dispatch({
                type: Action.Registration.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Оприходование успешно создано')
        }
    }
}

export function saveRegistration() {

    const state = store.getState()

    const body = {
        id: state.registration.edit,
        number: state.registration.number,
        description: state.registration.description,
        parts: state.registration.parts,
        price: state.registration.price,
        filter: getFilter()
    }
    const url = URLS.Registration.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос на изменение оприходования не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.Registration.CHANGE_STATE,
                data: {registrations: data.warehouse_registrations, registrations_count: data.count},
            })
            dispatch({
                type: Action.Visible.CHANGE_STATE,
                data: {isRightModalOpen: false, modalType: ''},
            })
            dispatch({
                type: Action.Registration.RESET
            })
            showAlert(dispatch, AlertType.SUCCESSES, 'Оприходование успешно изменено')
        }
    }
}