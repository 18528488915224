import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'

import {changeRoleState} from "../../../../../Redux/actions/roleActions"
import {selectValues} from "../../../../general/utils"
import {CheckboxDate} from "../../../../../data/checkboxData"

import Checkbox from "../../../../general/Checkbox"


function SelectAvailablePrices(props) {

    const discountMarginIDs = props.discount_margin.map(margin => margin.id)
    const disabled = props.role.deleted

    const handleSelect = (values) => {
        const settableDiscountMargin = selectValues(values, props.role.settableDiscountMargin)
        props.changeRoleState({settableDiscountMargin})
    }

    const priceCheckbox = useRef()

    useEffect(() => {
        const values = props.discount_margin.filter(margin => props.role.settableDiscountMargin.includes(margin.id))
        if (values.length === props.discount_margin.length) {
            priceCheckbox.current.indeterminate = false
            priceCheckbox.current.checked = true
        } else if (!values.length) {
            priceCheckbox.current.indeterminate = false
            priceCheckbox.current.checked = false
        } else {
            priceCheckbox.current.indeterminate = true
        }
    }, [props.role.settableDiscountMargin])


    return (
        <table>
            <caption className='ta-l'>Доступ к ценам</caption>
            <thead>
            <tr>
                <th className='th'>
                    <Checkbox
                        type={CheckboxDate.Type.SQUARED_FIVE}
                        label='Название'
                        onChange={() => handleSelect(discountMarginIDs)}
                        checked={discountMarginIDs.every(marginID => props.role.settableDiscountMargin.includes(marginID))}
                        ref={priceCheckbox}
                        disabled={disabled}
                    />
                </th>
            </tr>
            </thead>
            <tbody>
            {props.discount_margin.map(margin => {
                return (
                    <tr key={margin.id}>
                        <td className='td'>
                            <Checkbox
                                type={CheckboxDate.Type.SQUARED_FIVE}
                                label={margin.title}
                                onChange={() => handleSelect([margin.id])}
                                checked={props.role.settableDiscountMargin.includes(margin.id)}
                                disabled={disabled}
                            />
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = state => ({
    discount_margin: state.price.discount_margin,
    role: state.role
})

const mapDispatchToProps = {
    changeRoleState
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAvailablePrices)