import React from 'react'
import {connect} from 'react-redux'

import {changeMainDataState, saveGenerallyInfo} from "../../../../Redux/actions/mainDataActions";
import {ICON} from '../../../../data/icons'

import Icon from "../../../general/Icon"
import Button from "../../../general/Button"
import LabelInput from '../../../general/LabelInput'
import LabelArea from '../../../general/LableArea'
import {ButtonData} from "../../../../data/ButtonData";

const MainData = (props) => {
    return (
        <div className="box">
            <div className='box__forms w400'>
                <div className="row g6 ai-c">
                    <Icon
                        className='icon fm'
                        icon={ICON.HOUSE}
                    />
                    <h4>Реквизиты компании</h4>
                </div>
                <LabelInput
                    title="Название компании"
                    onChange={(event) => props.changeMainDataState({name: event.target.value})}
                    value={props.maindata.name}
                    checkedFlag="inputMaindataNameChecked"
                    redStar={true}
                />
                <LabelArea
                    title="Адрес"
                    onChange={(event) => props.changeMainDataState({address: event.target.value})}
                    value={props.maindata.address}
                />
                <LabelInput
                    title="Email"
                    onChange={(event) => props.changeMainDataState({email: event.target.value})}
                    value={props.maindata.email}
                />
                <LabelInput
                    title="ОГРН"
                    onChange={(event) => props.changeMainDataState({ogrn: event.target.value})}
                    value={props.maindata.ogrn}
                />
                <LabelInput
                    title="КПП"
                    onChange={(event) => props.changeMainDataState({kpp: event.target.value})}
                    value={props.maindata.kpp}
                />
                <LabelArea
                    title="Юредический адрес"
                    onChange={(event) => props.changeMainDataState({juridical_address: event.target.value})}
                    value={props.maindata.juridical_address}
                />
                <LabelInput
                    title="Директор"
                    onChange={(event) => props.changeMainDataState({director: event.target.value})}
                    value={props.maindata.director}
                />
                <LabelInput
                    title="Наименование банка"
                    onChange={(event) => props.changeMainDataState({bank_name: event.target.value})}
                    value={props.maindata.bank_name}
                />
                <LabelInput
                    title="Р/С"
                    onChange={(event) => props.changeMainDataState({settlement_account: event.target.value})}
                    value={props.maindata.settlement_account}
                />
                <LabelInput
                    title="К/с"
                    onChange={(event) => props.changeMainDataState({corr_account: event.target.value})}
                    value={props.maindata.corr_account}
                />
                <LabelInput
                    title="БИК"
                    onChange={(event) => props.changeMainDataState({bic: event.target.value})}
                    value={props.maindata.bic}
                />
                <Button
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.PRIMARY}
                    title='Сохранить'
                    onClick={() => props.saveGenerallyInfo()}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    maindata: state.maindata,
    view: state.view,
})

const mapDispatchToProps = {
    changeMainDataState,
    saveGenerallyInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainData)
