import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {addWarehouse, changeWarehouseState} from "../../../../Redux/actions/warehouseAction"
import {changeVisibleState} from "../../../../Redux/actions"
import {ButtonData} from "../../../../data/ButtonData"
import {Modal} from "../../../../data/data"

import Button from '../../../general/Button'
import SettingHeader from "../SettingHeader"
import Checkbox from "../../../general/Checkbox"
import TableWarehouse from './TableWarehouse'
import {CheckboxDate} from "../../../../data/checkboxData";

const SettingWarehouse = (props) => {

    useEffect(() => {
        props.addWarehouse()
    }, [props.showDeleted])

    return (
        <div className='setting-content'>
            <SettingHeader header='Склады'/>

            <div className='box'>
                <div className='box__forms'>
                    <h4>Склады товаров или запчастей</h4>
                    <p>Перечень складов компании для хранения товаров. Склады могут принадлежать конкретной локации или
                        всей
                        компании.</p>
                    <div className='two-buttons ai-c'>
                        <Button
                            size={ButtonData.Size.MEDIUM}
                            type={ButtonData.Type.CREATE}
                            title='Склад'
                            onClick={() => props.changeVisibleState({ isRightModalOpen: true, modalType: Modal.Type.WAREHOUSE})}
                            invisible={!props.permissions.includes('setting_create_warehouse')}
                        />
                        <Checkbox
                            type={CheckboxDate.Type.SLIDE_THREE}
                            label='Показать удаленных'
                            onChange={event => props.changeWarehouseState({showDeleted: event.target.checked})}
                            checked={props.showDeleted}
                            invisible={!props.permissions.includes('setting_see_deleted_warehouse')}
                        />
                    </div>
                    <TableWarehouse/>
                </div>
            </div>
        </div>
    )
}
// yj
const mapStateToProps = state => ({
    showDeleted: state.warehouse.showDeleted,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    addWarehouse,
    changeVisibleState,
    changeWarehouseState
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingWarehouse)