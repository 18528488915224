import React from 'react';
import { connect } from 'react-redux'

import {generateUUID} from "../general/utils";
import {data_setting_menu} from '../../data/dataSettingRows'

import SettingGroup from './SettingGroup';
import Loader  from '../Loader/Loader';

function SettingMenu(props) {

    if (data_setting_menu) {
        return (
            <div className='sidebar__body'>
                <SettingGroup 
                    group = {data_setting_menu.filter(row =>
                        row.group_name === 'generally_setting' && props.permission.includes(row.permission_key))}
                    key = {generateUUID()}
                    />
                <hr className='sidebar__hr'/>
                <SettingGroup 
                    group = {data_setting_menu.filter(row =>
                        row.group_name === 'app_setting' && props.permission.includes(row.permission_key))}  
                    key = {generateUUID()}
                />
            </div>
        )
    }
    else {
     return (<Loader/>)
    }
}

const mapStateToProps = state => ({
    permission: state.data.user.role.permissions
})
   
export default connect(mapStateToProps)(SettingMenu)