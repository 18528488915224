import {Action} from "../../data/ActionTypes";

const initialState = {
   statusMenuVisible : {},
   statusSetCustomFilter: false,
   stausListFilter: false,
   typeListFilter: false,
   managerListFilter: false,
   engineerListFilter: false,
   groupListFilter: false,
   brandListFilter: false,
   subtypeListFilter: false,
   clientListFilter: false,
   statusCreateNewFilter: false,
   statusCreateNewRole: false,
   statusOrderEditor: false,
   statusClientEditor: false,
   statusAdCampaignClient: false,
   statusEquipmentEditor: false,
   statusElementEditor: false,
   statusPaymentsEditor: false,
   statusStatusList: false,
   statusPriceEditor: false,
   statusGroupServiceEditor: false,
   statusServiceEditor: false,
   statusOperationEditor: false,
   statusPayrollEditor: false,
   statusCellEditor: false,
   statusWarehouseEditor: false,
   statusWarehouseEmployeeEditor: false,
   statusOrderPartEditor: false,
   statusOrderSticker: false,
   statusOrderLoader: false,
   checkOrderSticker: localStorage.getItem('checkOrderSticker') === 'true',
   statusNotTemplateEditor: false,
   needToResetOrder: false,
   statusRegistrationPartEditor: false,
   statusRemainEditor: false,
   statusOrderNotFound: false,
   statusReturnPart: false,

   checkedOrderClient: true,
   inputClientNameChecked: true,
   inputClientPhoneChecked: [true],
   inputClientDiscServChecked: true,
   inputClientDiscMatChecked: true,
   inputClientDiscGoodChecked: true,
   inputRoleTitleChecked: true,
   inputEmployeeNameChecked: true,
   inputEmployeeEmailChecked: true,
   inputEmployeeLoginChecked: true,
   inputEmployeePasswordChecked: true,
   inputEmployeeRoleChecked: true,
   inputMalfunctionChecked: true,
   inputClientOrderEditor: true,
   checkedOrderKindofGood: true,
   checkedOrderBrand: true,
   checkedOrderSubtype: true,
   inputMaindataNameChecked: true,
   inputBranchNameChecked: true,
   inputBranchPrefixChecked: true,
   inputBranchPrefixDocChecked: true,
   inputBookTitleChecked: true,
   inputCashRegisterTitleChecked: true,
   inputPaymentDescChecked: true,
   inputPaymentCashflowChecked: true,
   inputPaymentCashboxChecked: true,
   inputPaymentEmployeeChecked: true,
   inputPaymentSumChecked: true,
   inputPriceTitleChecked: true,
   inputDictServiceChecked: true,
   inputServiceTitleChecked: true,
   inputServiceCategoryIdChacked:true,
   inputServicePriceChacked:true,
   inputOperationEngineerChecked: true,
   inputOperationTitleChecked: true,
   inputPayrollDescChecked: true,
   inputPayrollEmployeeChecked: true,
   inputPayrollSumChecked: true,
   inputSingleMalfunctionChecked: true,
   inputWarehouseTitleChecked: true,
   inputWCategoryTitleChecked: true,
   inputWPartTitleChecked: true,
   inputOrderPartTitleChecked: true,
   inputOrderPartEngineerChecked: true,
   inputNotTempTitleChecked: true,
   inputNotTempTempleChecked: true,
   inputNotEventEventChecked: true,
   inputNotEventTemplateChecked: true,
   inputFilterTitleChecked: true,
   inputRegistrationLabelChecked: true,
   inputRegistrationWarehouseChecked: true,
   inputRegistrationCountChecked: true,
   inputRegistrationClientChecked: true,
   inputRegistrationPartChecked: true,
   inputResRulWarehouse: true,
   inputEngineerWriteOf: true,
   inputTargetWarehouseMovement: true,
   inputWarehouseBack: true,
   inputWarehouseReturnPart: true,
   inputWarehouseInventory: true,
   inputRequestSparePart: true,
   inputEmployeeDefaultBranch: true,
   inputOldPassword: true,
   inputNewPassword: true,

   errorSameMail: false,
   errorSameLogin: false,
   statusRefreshPage: false,

   modalType: '',
   modalCentralType: '',
   isRightModalOpen: false,
   isCentralModalOpen: false,
}

// todo: переписать ненужные функции
export const visualReducer = (state = initialState, action) => {
   switch (action.type){

      case Action.Visible.CHANGE_STATE: {
         return {...Object.assign(state, action.data)}
      }

      case 'INIT_STATUS_MENU_VISIBLE': {
         return {
            ...state, 
            statusMenuVisible: action.data,
         }
      }

      case 'EDIT_ROLE': {
         return {
            ...state, 
            statusCreateNewRole: !state.statusCreateNewRole,
         }
      }

      case 'SET_VISIBLE_FLAG': {

         if (action.value === 'change') {
            return {
               ...state, 
               [action.field]: !state[action.field]
            }
         } else {
            return {
               ...state,
            [action.field]: action.value
            }
         }
      }

      case 'SET_VISIBLE_LIST_FLAG': {

         const list = state[action.field]

         if (action.value === 'change') {
            list[action.idx] = !list[action.idx] 
            return {
               ...state, 
               [action.field]: list
            }
         } else {
            list[action.idx] = action.value
            return {
               ...state,
            [action.field]: list
            }
         }
      }

      case 'DELETE_FLAG': {
         const list = state[action.field]
         list.splice(action.idx, 1)
         return {
            ...state,
            [action.field]: list
         }
      }

      case 'ADD_FLAG': {
         return {
            ...state,
            [action.field]: state[action.field].concat([true])
         }
      }

      case 'CHANGE_EMPLOYEE_EDITOR_FORM': {
         if (action.field === 'email')
         return {
            ...state, 
            errorSameMail: false
         }
         if (action.field === 'login')
         return {
            ...state, 
            errorSameLogin: false
         }
         return state
      }


      default: return state
   }
   
}
