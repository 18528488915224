import React from 'react';
import { connect } from 'react-redux'

import {ICON} from '../../data/icons'

import Icon from '../general/Icon'

const Header = (props) => {

    return (
        <div className='header'>
            <div className='row g12 ai-c'>
                <h3>{props.title}</h3>
                <div className='row g3 ai-c'>
                    <Icon
                        className='icon icon_20'
                        icon={props.current_branch?.icon}
                        color={props.current_branch?.color}
                    />
                    <h3 className='ml5'>{props.current_branch?.name}</h3>
                    <Icon
                        className='icon icon_24'
                        icon={ICON.DOWN}
                        color='var(--main)'
                    />
                </div>
            </div>
            {props.search}
        </div>
    )
}

const mapStateToProps = state => ({
    current_branch: state.branch.current_branch
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Header)