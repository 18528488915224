export const CheckboxDate = Object.freeze({
    Type: {
        SLIDE_ONE: 'slide-one',
        SLIDE_TWO: 'slide-two',
        SLIDE_THREE: 'slide-three',
        ROUNDED_ONE: 'rounded-one',
        ROUNDED_TWO: 'rounded-two',
        SQUARED_ONE: 'squared-one',
        SQUARED_TWO: 'squared-two',
        SQUARED_THREE: 'squared-three',
        SQUARED_FOUR: 'squared-four',
        SQUARED_FIVE: 'squared-five',
    }
})