import React from 'react'
import {connect} from 'react-redux'

import LabelInput from '../../../general/LabelInput'
import LabelArea from '../../../general/LabelInput'
import SalaryRule from './SalaryRule'

import {changeEmployeeState} from '../../../../Redux/actions/employeeAction'
import SelectFromList from "../../../general/SelectFromList";


function EditEmployeeGenerally(props) {
    return (
        <div className="modal__body-right">
            <div className="modal__block-forms w250">
                <LabelInput
                    title="Имя"
                    onChange={event => props.changeEmployeeState({first_name: event.target.value})}
                    value={props.employee.first_name}
                    checkedFlag="inputEmployeeNameChecked"
                    redStar={true}
                    disabled={props.employee.deleted}
                />
                <LabelInput
                    title="Фамилия"
                    onChange={event => props.changeEmployeeState({last_name: event.target.value})}
                    value={props.employee.last_name}
                    disabled={props.employee.deleted}
                />
                <LabelInput
                    title="email"
                    onChange={event => props.changeEmployeeState({email: event.target.value})}
                    value={props.employee.email}
                    checkedFlag="inputEmployeeEmailChecked"
                    redStar={true}
                    disabled={props.employee.deleted}
                />
                {props.view.errorSameMail ? (
                    <div className="errorMassageInput">Такой email уже существует</div>
                ) : null}
                <LabelInput
                    title="login"
                    onChange={event => props.changeEmployeeState({login: event.target.value})}
                    value={props.employee.login}
                    checkedFlag="inputEmployeeLoginChecked"
                    redStar={true}
                    disabled={props.employee.deleted}
                />
                {props.view.errorSameLogin ? <div className="errorMassageInput">Такой Login уже существует</div> : null}
                <LabelInput
                    title="Пароль"
                    onChange={event => props.changeEmployeeState({password: event.target.value})}
                    value={props.employee.password}
                    checkedFlag={props.employee.edit ? null : 'inputEmployeePasswordChecked'}
                    redStar={!props.employee.edit}
                    disabled={props.employee.deleted}
                />
                <LabelInput
                    title="Телефон"
                    onChange={value => props.changeEmployeeState({phone: value})}
                    value={props.employee.phone}
                    disabled={props.employee.deleted}
                    isPhone={true}
                />
                <LabelInput
                    title="ИНН Сотрудника"
                    onChange={event => props.changeEmployeeState({inn: event.target.value})}
                    value={props.employee.inn}
                    disabled={props.employee.deleted}
                />
                <LabelInput
                    title="Имя в печатных документах"
                    onChange={event => props.changeEmployeeState({doc_name: event.target.value})}
                    value={props.employee.doc_name}
                    disabled={props.employee.deleted}
                />
                <LabelArea
                    title="Примечание"
                    onChange={event => props.changeEmployeeState({notes: event.target.value})}
                    value={props.employee.notes}
                    disabled={props.employee.deleted}
                />
                <LabelInput
                    title="Должность"
                    onChange={event => props.changeEmployeeState({post: event.target.value})}
                    value={props.employee.post}
                    disabled={props.employee.deleted}
                />
                <SelectFromList
                    title='Роль'
                    list={props.role.roles}
                    setElement={role => props.changeEmployeeState({role})}
                    current_object={props.employee.role}
                    checkedFlag='inputEmployeeRoleChecked'
                    noChoosed='Выберете роль'
                    disabled={props.employee.deleted}
                />
                <SalaryRule/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    employee: state.employee,
    role: state.role,
    view: state.view,
})

const mapDispatchToProps = {
    changeEmployeeState
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployeeGenerally)
