import React from 'react'
import {connect} from 'react-redux'


import Data from "../../../../general/cell/Data";
import {getRole} from "../../../../../Redux/actions/roleActions";

function TableRoles(props) {
    return (
        <>
            <table>
                <thead>
                <tr>
                    <th className='th'>Название</th>
                </tr>
                </thead>
                <tbody>
                {props.roles.map(role => {
                    return (
                        <tr
                            key={role.id}
                            className={role.deleted ? 'tr_deleted' : 'tr'}
                            onDoubleClick={() => props.getRole(role.id)}
                        >
                           <Data data={role.title}/>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div>Всего - {props.roles.length}</div>
        </>
    )
}

const mapStateToProps = state => ({
    roles: state.role.roles
})

const mapDispatchToProps = {
    getRole
}

export default connect(mapStateToProps, mapDispatchToProps)(TableRoles)