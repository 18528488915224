import store from '../store'
import {getRequestConfig, bad_request, serverRequest} from './actionUtils'
import {Action} from "../../data/ActionTypes";
import {URLS} from "../../data/urls";
import {RequestMethod} from "../../data/requestMethod";

export function changeMainDataState( data ){
    return {
        type: Action.MainData.CHANGE_STATE,
        data
    }
}

export function saveGenerallyInfo() {

    const state = store.getState()

    let body = {
        id: 1,
        name: state.maindata.name,
        address: state.maindata.address,
        email: state.maindata.email,

        ogrn: state.maindata.ogrn,
        inn: state.maindata.inn,
        kpp: state.maindata.kpp,
        juridical_address: state.maindata.juridical_address,
        director: state.maindata.director,
        bank_name: state.maindata.bank_name,
        settlement_account: state.maindata.settlement_account,
        corr_account: state.maindata.corr_account,
        bic: state.maindata.bic,

        description: state.maindata.description,
        phone: state.maindata.phone,
        logo: state.maindata.logo
    }
    const url = URLS.General_info.POST
    const method = RequestMethod.PUT
    const error_message = 'Запрос изменение основных данных компании не выполнен'

    return async dispatch => {

        const data = await serverRequest(body, url, dispatch, error_message, method)

        if (data.success) {
            dispatch({
                type: Action.MainData.EDIT,
                data: data.general_info,
            })
        }
    }
}