import React, {useEffect} from 'react'
import { connect } from 'react-redux'


import {changeWarehouseState} from '../../../../Redux/actions/warehouseAction'
import {changeVisibleState} from "../../../../Redux/actions"
import {editEmployee} from '../../../../Redux/actions/employeeAction'
import {changeWarehousePermissionState} from "../../../../Redux/actions/WarehousePermissionActions"
import {CheckboxDate} from "../../../../data/checkboxData"
import {DefaultWarehousePermission} from "../../../../data/warehousePermissions"
import {EditWarehousePermission} from "../../../../data/WarehouseEmployeeFor"
import {Modal} from "../../../../data/data"

import Data from "../../../general/cell/Data"
import Checkbox from "../../../general/Checkbox"
import WarehouseEmployeeEditor from "./WarehouseEmployeeEditor"

const WarehouseAccess = (props) => {

    useEffect(() => {
        const new_permissions = []
        props.employees.map(employee => {
            const permission = props.warehouse.employee_permissions.find(permission => permission?.employee_id === employee.id);
            if(!permission){
                new_permissions.push({
                    ...DefaultWarehousePermission,
                    warehouse_id_id: props.warehouse.edit,
                    employee_id: employee.id
                })
            }
        })
        if (new_permissions){
            props.changeWarehouseState({
                employee_permissions: props.warehouse.employee_permissions.concat(new_permissions),
                is_access_prepared: true
            })
        }
    }, [])

    const handleEdit = permission => {
        props.changeWarehousePermissionState({
            forEditor: EditWarehousePermission.FOR_WAREHOUSE,
            employeeId: permission.employee_id,
            warehouseId: permission.warehouse_id,
            isAvailable: permission.is_available,
            likeWarehouse: permission.like_warehouse,
            personalPermission: permission.permissions,
            warehousePermission: props.warehouse.permissions
        })

        props.changeVisibleState({
            modalCentralType: Modal.Type.WAREHOUSE_EMPLOYEE,
            isCentralModalOpen: true
        })
    }

    const handleCheck = (employee_id, value) => {
        const employee_permissions = props.warehouse.employee_permissions
        employee_permissions.map(permission => {
            if(permission.employee_id === employee_id){
                permission.is_available = value
            }
            return permission
        })
        props.changeWarehouseState({employee_permissions})
    }

    const getPermission = (employeeId) => {
        const permission = props.warehouse.employee_permissions.find(permission => permission?.employee_id === employeeId);
        return permission || DefaultWarehousePermission
    }

    return (
        <div className='table-container'>
            <table>
                <thead>
                <tr>
                    <th className='th th_w60'/>
                    <th className='th th_w180'>Сотрудник</th>
                    <th className='th th_w180'>Права</th>
                </tr>
                </thead>
                <tbody>
                {props.employees.map(employee => {

                    const permission = getPermission(employee.id)
                    const permission_type = permission.is_available ?
                        (permission.like_warehouse ? 'Доступные для склада' : 'Персональные')
                        : 'Нет доступа'

                    return (
                    <tr
                        key={employee.id}
                        className='tr'
                        onDoubleClick={() => handleEdit(permission)}
                    >
                        <td className='td'>
                            <Checkbox
                                type={CheckboxDate.Type.SLIDE_THREE}
                                onChange={event => handleCheck(employee.id, event.target.checked)}
                                checked={permission.is_available}
                                disabled={props.warehouse.deleted}
                            />
                        </td>
                        <Data data={employee.name}/>
                        <Data data={permission_type}/>
                    </tr>
                )})}
                </tbody>
            </table>
            {props.statusWarehouseEmployeeEditor ? <WarehouseEmployeeEditor/> : null}
        </div>
    )
}

const mapStateToProps = state => ({
    employees: state.employee.employees.filter(employee => !employee.deleted && employee.id !== 0),
    warehouse: state.warehouse,
    statusWarehouseEmployeeEditor: state.view.statusWarehouseEmployeeEditor
})

const mapDispatchToProps = {
    changeWarehouseState,
    editEmployee,
    changeVisibleState,
    changeWarehousePermissionState
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseAccess)