import React, {useEffect} from 'react'
import { connect } from 'react-redux'

import {addBacks, changeBackState} from '../../../Redux/actions/warehouseBackActions'

import ChooseDate from '../../general/calandar/ChooseDate'
import TableFields from '../../general/TableFields'
import Paginate from '../../general/Paginate'
import BackTable from './BackTable'

const WarehouseBacks = props => {

    useEffect(() => {
        props.addBacks()
    }, [props.back.page, props.back.filter_created_at])

    return (
        <div className='box'>
            <div className='page-buttons'>
                    <ChooseDate
                        title='Диапазон'
                        range={true}
                        func={date => props.changeBackState({filter_created_at: date.map(date => Math.round(date / 1000))})}
                        current_date={props.back.filter_created_at}
                    />
                <TableFields
                    id='backFields'
                    list={props.back.table_headers}
                    func={table_headers => props.changeBackState({table_headers})}
                />
            </div>
            <BackTable/>
            <Paginate
                allItems={props.back.count}
                onPage={50}
                count={2}
                count_start_end={2}
                navigation={true}
                func={page => props.changeBackState({page})}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    back: state.back
})

const mapDispatchToProps = {
    changeBackState,
    addBacks
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseBacks)