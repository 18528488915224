import {Action} from "../../data/ActionTypes";

const initialState = {

    events: [],
    eventTypes: [],
    notificationTypes: [],
    targetAudiences: [],

    tabs: 0,

    edit: 0,
    event_type: {},
    target_audience_id: 0,
    notification_type: {id: 1, title: "SMS"},
    statuses: [],
    notification_template: {},
    deleted: false,

    showDeleted: false,
    filter_target_audience: 0
}

export const notEventReducer = (state = initialState, action) => {
    switch (action.type){

        case Action.NotEvent.CHANGE_STATE: {
            return {...Object.assign(state, action.data)}
        }

        case Action.NotEvent.EDIT: {
            return {
                ...state,
                edit: action.notificationEvent.id,
                event_type: action.notificationEvent.event_type,
                target_audience_id: action.notificationEvent.target_audience.id,
                notification_type: action.notificationEvent.notification_type,
                notification_template: action.notificationEvent.template,
                statuses: action.notificationEvent.statuses,
                deleted: action.notificationEvent.deleted
            }
        }

        case Action.NotEvent.RESET: {
            return {
                ...state,
                edit: 0,
                event: {},
                target_audience: {},
                notification_type: {},
                notification_template: {},
                statuses: [],
                deleted: false,
            }
        }

        case Action.NotEvent.SELECTED: {
            // Обявим переменную для изменных данных
            let new_data
            // Проверим если значения value в списке уже существующих
            if (action.value.every(val => state[action.field].includes(val))) {
                // Если есть удалим эти значения
                new_data = state[action.field].filter(val => !action.value.includes(val))
            } else {
                // Если нет добавим эти значения
                new_data = state[action.field].concat(action.value.filter(val => !state[action.field].includes(val)))
            }
            // Если флаг saveToApp установлен сохраним данные на локальном хранилище
            if (action.saveToApp) localStorage.setItem(action.field, JSON.stringify(new_data))
            // Вернем изменненый стейт
            return {
                ...state,
                [action.field]: new_data,
            }
        }


        default: return state
    }

}