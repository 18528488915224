import React from 'react'
import { connect } from 'react-redux'

import {changeNotEventState} from '../../../../Redux/actions/notEventAction'
import {changeVisibleState} from '../../../../Redux/actions'
import {EditorID, Modal} from "../../../../data/data"
import {ButtonData} from "../../../../data/ButtonData"
import {CheckboxDate} from "../../../../data/checkboxData"

import Button from '../../../general/Button'
import EventTable from './EventTable'
import Checkbox from '../../../general/Checkbox'
import {NotificationData} from "../../../../data/NotificationData";


const OrderNotification = (props) => {

    const handleCreateClients = () => {
        props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.NOT_EVENT})
        props.changeNotEventState({target_audience_id: NotificationData.TargetAudience.CLIENT})
    }

    const handleCreateManagers = () => {
        props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.NOT_EVENT})
        props.changeNotEventState({target_audience_id: NotificationData.TargetAudience.MANAGER})
    }

    const handleCreateEngineers = () => {
        props.changeVisibleState({isRightModalOpen: true, modalType: Modal.Type.NOT_EVENT})
        props.changeNotEventState({target_audience_id: NotificationData.TargetAudience.ENGINEER})
    }

    return (
        <div className = 'box__forms'>
            <h4>Для клиентов</h4>
            <p>Настройте уведомления для клиентов о ходе выполнения работ или других событий.</p>
            <div className='two-buttons ai-c'>
                <Button
                    id={EditorID.NEW_NOTIFICATION}
                    size={ButtonData.Size.MEDIUM}
                    type={ButtonData.Type.CREATE}
                    title='Оповещение'
                    onClick={handleCreateClients}
                    invisible={!props.permissions.includes('setting_create_not_event')}
                />
                <Checkbox
                    type={CheckboxDate.Type.SLIDE_THREE}
                    label='Показать удаленные'
                    onChange={event => props.changeNotEventState({showDeleted: event.target.checked})}
                    checked={props.showDeleted}
                    invisible={!props.permissions.includes('setting_see_deleted_not_event')}
                />
            </div>

            <EventTable target_audience={NotificationData.TargetAudience.CLIENT}/>

            <h4>Для менеджеров</h4>
            <p>Настройте уведомления для менеджеров, чтобы они не пропустили ничего важного.</p>
            <Button
                id={EditorID.NEW_NOTIFICATION}
                size={ButtonData.Size.MEDIUM}
                type={ButtonData.Type.CREATE}
                title='Оповещение'
                onClick={handleCreateManagers}
            />

            <h4>Для исполнителей</h4>
            <p>Настройте уведомления для исполнителей, чтобы они не пропустили ничего важного.</p>
            <Button
                id={EditorID.NEW_NOTIFICATION}
                size={ButtonData.Size.MEDIUM}
                type={ButtonData.Type.CREATE}
                title='Оповещение'
                onClick={handleCreateEngineers}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    showDeleted: state.notEvent.showDeleted,
    permissions: state.data.user.role.permissions
})

const mapDispatchToProps = {
    changeVisibleState,
    changeNotEventState
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderNotification)